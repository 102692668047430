/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Typography from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";
import { ListAccepted, ListPending, ListRefused } from "./lists/Lists";

export const Richieste = ({ className, pin }) => {
  const { user } = useSelector((state) => state.auth);
  const richieste = useSelector(
    (state) => state.richiesteNotification.richieste,
    shallowEqual
  );

  const [count, setCount] = useState(0);
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`nav-tabpanel-${index}`}
        aria-labelledby={`nav-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box p={3}>
            <Typography>{children}</Typography>
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired,
  };

  function a11yProps(index) {
    return {
      id: `nav-tab-${index}`,
      "aria-controls": `nav-tabpanel-${index}`,
    };
  }

  function LinkTab(props) {
    return (
      <Tab
        component="a"
        onClick={(event) => {
          event.preventDefault();
        }}
        {...props}
      />
    );
  }

  const useStyles = makeStyles((theme) => ({
    root: {
      flexGrow: 1,
      backgroundColor: theme.palette.background.paper,
    },
  }));

  const classes = useStyles();

  useEffect(() => {}, [count]);

  return (
    <>
      <div className={`card card-custom ${className}`}>
        {/* Header */}
        <div className="card-dashboard border-0"></div>
        <div className={classes.root}>
          <AppBar position="static">
            <Tabs
              variant="fullWidth"
              value={value}
              onChange={handleChange}
              aria-label="nav tabs example"
            >
              <LinkTab label="In attesa" href="/pending" {...a11yProps(0)} />
              <LinkTab label="Accettate" href="/accepted" {...a11yProps(1)} />
              <LinkTab label="Rifiutate" href="/refused" {...a11yProps(2)} />
            </Tabs>
          </AppBar>
          <TabPanel value={value} index={0}>
            <ListPending pin={pin} pending={richieste.pending} user={user} />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <ListAccepted accepted={richieste.accepted} user={user} />
          </TabPanel>
          <TabPanel value={value} index={2}>
            <ListRefused refused={richieste.refused} user={user} />
          </TabPanel>
        </div>

        {/* Body */}
      </div>
    </>
  );
};
