/**
 * TODO: 
 * Al termine del meet il medico dovrà visualizzare un form con
 * dati che devono essere criptati e un input file per allegare il referto
 * o un qualsiasi altro tipo di documento che verrà mintato in blockchain
 * 
 * Rimuovere watermark Jitsi e disabilitare tasti del componente
 */
import React, { useState, useEffect } from "react";
import { ModalDetailMeet } from "./meetsComponents.js/ModalDetailMeet";
import config from "../../../../config/config";
import { useSelector } from "react-redux";
import moment from "moment-timezone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";

export const Meets = ({ idOrganization }) => {
  const user = useSelector((state) => state.auth.user);

  const [meets, setMeets] = useState([]);
  const [show, setShow] = useState(false);
  const [selectedMeet, setSelectedMeet] = useState(0);
  const [dateAppointment, setDateAppointment] = useState(new Date());
  const [password, setPassword] = useState("");

  const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  const GetMeets = () => {
    const options = {
      headers: {
        Authorization: config.keyApi,
      },
      method: "POST",
      body: JSON.stringify({
        idUser: user.id,
        idOrganization: idOrganization,
      }),
    };
    fetch(config.apiUrl + "calendar/GetMeets.php", options)
      .then((response) => response.json())
      .then((data) => setMeets(data));
  };

  useEffect(() => {
    GetMeets();
    /*eslint-disable-next-line*/
  }, []);

  return (
    <>
      <ModalDetailMeet
        idMeet={selectedMeet}
        idRole={user.idRole}
        firstname={user.firstname}
        lastname={user.lastname}
        password={password}
        dateAppointment={dateAppointment}
        setMeet={setSelectedMeet}
        show={show}
        close={handleClose}
      />
      <div className="card card-custom gutter-b">
        <div className="card-header border-0">
          <h3 className="card-title font-weight-bolder text-dark">
            Teleconsulti
          </h3>
        </div>

        <div
          style={{ overflow: "auto", minHeight: "400px", maxHeight: "420px" }}
          className="card-body pt-0"
        >
          {meets.length > 0 && meets !== undefined ? (
            meets.map((e, key) => (
              <div
                key={key}
                className="d-flex align-items-center mb-9 bg-light-warning rounded p-5"
              >
                <div className="d-flex flex-column flex-grow-1 mr-2">
                  {/*eslint-disable-next-line*/}
                  <span className="font-weight-bold text-dark-75 font-size-lg mb-1">
                    {e.service}:
                  </span>
                  <span className="text-muted font-weight-bold">
                    {moment(e.dateAppointment).format("DD MMMM YYYY - HH:mm")}
                  </span>
                </div>

                <span className="font-weight-bolder text-warning text-hover-warning py-1 font-size-lg">
                  <FontAwesomeIcon
                    title="Dettagli"
                    onClick={() => {
                      setSelectedMeet(e.idMeet);
                      setPassword(e.password);
                      setDateAppointment(
                        moment(e.dateAppointment).format("DD MMMM YYYY - HH:mm")
                      );
                      handleOpen();
                    }}
                    style={{ cursor: "pointer" }}
                    icon={faCircleInfo}
                  />
                </span>
              </div>
            ))
          ) : (
            <div className="font-weight-bolder text-bold py-1 font-size-lg">
              <div className="text-center mt-5">
                <span className="font-weight-bold">Nessun meet imminente</span>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
};
