import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import * as PropTypes from "prop-types";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Card, Tooltip, OverlayTrigger, Modal, Button } from "react-bootstrap";
import Skeleton from "@material-ui/lab/Skeleton";
import config from "../../../config/config";

export const RichiesteMedici = () => {
  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );

  const TableComponent = ({ ...restProps }) => (
    <Table.Table
      {...restProps}
      className="table table-hover table-head-custom table-vertical-center overflow-hidden"
    />
  );

  const [acceptModal, setAcceptModal] = useState(false);
  const [declineModal, setDeclineModal] = useState(false);

  const AcceptModal = (value) => (
    <Modal show={acceptModal}>
      <Modal.Header>
        <Modal.Title>Accetta richiesta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Sei sicuro di voler accettare la richiesta di {value.value.firstname}{" "}
        {value.value.lastname}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => AcceptRequest(value.value.idUser, value.value.id)}
          variant="success"
        >
          Accetta
        </Button>
        <Button onClick={() => setAcceptModal(false)} variant="danger">
          Annulla
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const DeclineModal = (value) => (
    <Modal show={declineModal}>
      <Modal.Header>
        <Modal.Title>Rifiuta richiesta</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Sei sicuro di voler rifiutare la richiesta di {value.value.firstname}{" "}
        {value.value.lastname}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={() => DeclineRequest(value.value.idUser, value.value.id)}
          variant="danger"
        >
          Rifiuta
        </Button>
        <Button onClick={() => setDeclineModal(false)} variant="success">
          Annulla
        </Button>
      </Modal.Footer>
    </Modal>
  );

  const editColum = ({ value }) => (
    <>
      <AcceptModal value={value} />
      <DeclineModal value={value} />
      <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">Accetta</Tooltip>}
      >
        {/*eslint-disable-next-line*/}
        <a
          onClick={() => setAcceptModal(true)}
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Check.svg")} />
          </span>
        </a>
      </OverlayTrigger>

      <> </>
      <OverlayTrigger
        overlay={<Tooltip id="products-delete-tooltip">Rifiuta</Tooltip>}
      >
        {/*eslint-disable-next-line*/}
        <a
          onClick={() => setDeclineModal(true)}
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/Navigation/Close.svg")} />
          </span>
        </a>
      </OverlayTrigger>
    </>
  );

  const ButtonColumn = (props) => (
    <DataTypeProvider formatterComponent={editColum} {...props} />
  );

  const [columns] = useState([
    { name: "id", title: "ID richiesta" },
    { name: "firstname", title: "Nome" },
    { name: "lastname", title: "Cognome" },
    { name: "birthdate", title: "Data di nascita" },
    { name: "dateRequest", title: "Data richiesta" },
    { name: "actions", title: "Azioni" },
  ]);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [actionsColumn] = useState(["actions"]);

  const [sorting, setSorting] = useState([
    { columnName: "id", direction: "desc" },
  ]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 20, 30, 0]);

  const ActionsFilterCell = ({ filter, onFilter }) => (
    <th style={{ fontWeight: "normal" }}>
      <input disabled style={{ visibility: "hidden" }} />
    </th>
  );
  ActionsFilterCell.propTypes = {
    filter: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
    onFilter: PropTypes.func.isRequired,
  };
  ActionsFilterCell.defaultProps = {
    filter: null,
  };

  const FilterCell = (props) => {
    const { column } = props;
    if (column.name === "actions") {
      return <ActionsFilterCell {...props} />;
    }
    return <TableFilterRow.Cell {...props} />;
  };
  FilterCell.propTypes = {
    column: PropTypes.shape({ name: PropTypes.string }).isRequired,
  };

  const tableMessages = {
    noData: "Nessuna richiesta in attesa",
  };
  const filterRowMessages = {
    filterPlaceholder: "Filtra...",
  };

  const pagingPanelMessages = {
    showAll: "Tutte",
    rowsPerPage: "Righe per pagina",
    info: "Da {from} a {to} ({count} richieste)",
  };

  const [count, setCount] = useState(0);

  const GetRequests = () => {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: organization.id,
      }),
    };
    fetch(config.apiUrl + "organization/GetRequests.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setRows(data))
      .then(() => setLoading(false));
  };

  const AcceptRequest = (idDoc, idRequest) => {
    const requestOptions = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: idDoc,
        idRequest: idRequest,
      }),
    };
    fetch(config.apiUrl + "organization/AcceptRequest.php", requestOptions)
      .then(() => setCount(count + 1))
      .then(() => setAcceptModal(false));
  };

  const DeclineRequest = (idDoc, idRequest) => {
    const requestOptions = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: idDoc,
        idRequest: idRequest,
      }),
    };
    fetch(config.apiUrl + "organization/DeclineRequest.php", requestOptions)
      .then(() => setCount(count + 1))
      .then(() => setAcceptModal(false));
  };

  useEffect(() => {
    GetRequests();
    /*eslint-disable-next-line*/
  }, [count]);

  return (
    <>
      {!loading ? (
        <Card className="tab-content table-responsive px-2 py-2">
          <Grid rows={rows} columns={columns}>
            <ButtonColumn for={actionsColumn} />
            <FilteringState />
            <IntegratedFiltering />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            <SortingState sorting={sorting} onSortingChange={setSorting} />
            <IntegratedSorting />
            <Table messages={tableMessages} tableComponent={TableComponent} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow
              messages={filterRowMessages}
              cellComponent={FilterCell}
            />
            <PagingPanel messages={pagingPanelMessages} pageSizes={pageSizes} />
          </Grid>
        </Card>
      ) : (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="circle" width={80} height={80} />
          <Skeleton variant="rect" height={300} />
        </>
      )}
    </>
  );
};
