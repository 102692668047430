/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import { Skeleton } from "@material-ui/lab";
import { GetDati } from "../../../config/api/cartellaDigitale";
import { decrypt } from "../../../config/utils/crypt";
import { CardValore } from "./widgets/cardValore";

import moment from "moment";
import { Riepilogo } from "./widgets/Riepilogo";

export const Resoconto = ({ user, pin, blur }) => {
  const [dati, setDati] = useState([]);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState({});
  const [page, setPage] = useState("cards");

  useEffect(() => {
    setLoading(true);
    if (!blur) {
      GetDati({ accessToken: user.authToken }, pin)
        .then((data) => {
          let newObj = {};
          let objGrid = [];
          for (var k in data.result) {
            newObj[k] = data.result[k].map((e) => ({
              argument: moment(e.argument).format("DD MMMM YYYY"),
              lineValue: decrypt(e.lineValue, pin),
            }));
            let label = k;

            data.result[k].map((e) =>
              objGrid.push({
                label: label,
                /* "": "", */
                dateCreated: moment(e.argument).format("DD MMMM YYYY"),
                value: decrypt(e.lineValue, pin),
              })
            );
          }
          setDati(newObj);
        })
        .then(() => setLoading(false));
    }
    /*eslint-disable-next-line*/
  }, [blur]);

  return (
    <Card
      style={
        blur
          ? { borderTop: "0px", borderRadius: "0px", filter: "blur(3px)" }
          : { borderTop: "0px", borderRadius: "0px" }
      }
      className="p-4"
    >
      {page === "cards" ? (
        <Card.Body>
          {!loading ? (
            <div className="row">
              {Object.keys(dati).map((e, key) => {
                let last = Array.from(dati[e].values()).pop();
                return (
                  <CardValore
                    setPage={setPage}
                    label={e}
                    value={last.lineValue}
                    summary={dati[e]}
                    setSummary={setSummary}
                  />
                );
              })}
            </div>
          ) : (
            <div className="row">
              <div className="col-4">
                <Skeleton variant="rect" height={300} />
              </div>
              <div className="col-4">
                <Skeleton variant="rect" height={300} />
              </div>
              <div className="col-4">
                <Skeleton variant="rect" height={300} />
              </div>
              <div className="col-4 mt-4">
                <Skeleton variant="rect" height={300} />
              </div>
              <div className="col-4 mt-4">
                <Skeleton variant="rect" height={300} />
              </div>
              <div className="col-4 mt-4">
                <Skeleton variant="rect" height={300} />
              </div>
            </div>
          )}
        </Card.Body>
      ) : (
        <Card.Body>
          <Riepilogo summary={summary} setPage={setPage} />
        </Card.Body>
      )}
    </Card>
  );
};
