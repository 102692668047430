import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector, shallowEqual } from "react-redux";
import { InputPic } from "../utils/InputPic";
import * as Yup from "yup";
import { Form, Button } from "react-bootstrap";
import config from "../../../../config/config";
import Select from "react-select";

export const CreaOrganizzazione = () => {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const [profilePic, setProfilePic] = useState();
  const [copertinaPic, setCopertinaPic] = useState();

  const [regions, setRegions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [controlRegion, setControlRegion] = useState(false);
  const [controlProvince, setControlProvince] = useState(false);
  const [controlCity, setControlCity] = useState(false);

  const [controlName, setControlName] = useState(false);
  const [controlResult, setControlResult] = useState(false);

  const requestOptions = {
    headers: {
      Authorization:
        "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
    },
    method: "POST",
    body: JSON.stringify({
      provincia: selectedProvince,
      regione: selectedRegion,
    }),
  };

  const GetRegions = () => {
    fetch(config.apiUrl + "geolocal/GetRegions.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setRegions(data));
  };

  const GetProvinces = () => {
    fetch(config.apiUrl + "geolocal/GetProvinces.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setProvinces(data));
  };

  const GetCities = () => {
    fetch(config.apiUrl + "geolocal/GetCities.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setCities(data));
  };

  useEffect(() => {
    GetRegions();
    /*eslint-disable-next-line*/
  }, []);

  useEffect(() => {
    if (selectedProvince !== "") {
      GetCities();
    }
    /*eslint-disable-next-line*/
  }, [selectedProvince]);

  useEffect(() => {
    if (selectedRegion !== "") {
      GetProvinces();
    }
    /*eslint-disable-next-line*/
  }, [selectedRegion]);

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const schema = Yup.object({
    name: Yup.string("Inserire un nome valido")
      .required("Inserire un nome valido")
      .min(3, "Minimo 3 caratteri")
      .max(50, "Massimo 50 caratteri"),
    vatNumber: Yup.string("Inserire una partita IVA valida")
      .required("Inserire una partita IVA valida")
      .min(11, "La partita IVA deve essere composta da 11 cifre")
      .max(11, "La partita IVA deve essere composta da 11 cifre"),
    /* region: Yup.string("Selezionare una regione").required(
      "Selezionare una regione"
    ),
    province: Yup.string("Selezionare una provincia").required(
      "Selezionare una provincia"
    ),
    city: Yup.string("Seleziona città").required("Seleziona città"), */
    address: Yup.string("Inserire l'indirizzo").required(
      "Inserire l'indirizzo"
    ),
    phone: Yup.string("Inserire il numero di telefono")
      .required("Inserire il numero di telefono")
      .matches(/^\d+$/, "Caratteri non ammessi"),
    fax: Yup.string("Inserire numero FAX")
      .notRequired("")
      .matches(/^\d+$/, "Caratteri non ammessi"),
    email: Yup.string()
      .email("Inserire una mail valida")
      .required("Inserire una email valida"),
    pec: Yup.string().email("Inserire una PEC valida").notRequired(""),
    bio: Yup.string("Inserire una descrizione valida")
      .notRequired("")
      .max(100, "Massimo 100 caratteri")
      .min(10, "Minimo 10 caratteri"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      vatNumber: "",
      address: "",
      phone: "",
      fax: "",
      email: "",
      pec: "",
      bio: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (selectedRegion === "") {
        setControlRegion(true);
      } else {
        setControlRegion(false);
      }
      if (selectedCity === "") {
        setControlCity(true);
      } else {
        setControlCity(false);
      }
      if (selectedProvince === "") {
        setControlProvince(true);
      } else {
        setControlProvince(false);
      }
      if (
        controlRegion === false &&
        controlCity === false &&
        controlProvince === false
      ) {
        const formResult = new FormData();
        for (let value in values) {
          formResult.append(value, values[value]);
        }
        formResult.append("regione", selectedRegion);
        formResult.append("provincia", selectedProvince);
        formResult.append("citta", selectedCity);
        formResult.append("profilePic", profilePic);
        formResult.append("completePic", copertinaPic);
        formResult.append("idUser", user.id);
        const requestOptions = {
          headers: {
            Authorization:
              "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
          },
          method: "POST",
          body: formResult,
        };

        fetch(
          config.apiUrl + "organization/CreateOrganization.php",
          requestOptions
        )
          .then((response) => response.json())
          .then((data) => {
            setControlName(data["name"]);
            setControlResult(data["result"]);
          });
      }
      if (!controlName && controlResult) {
        formik.resetForm();
      }
    },
  });

  return (
    <Form className="mx-5 my-5">
      <Form.Row>
        <div className="col-12 my-3">
          <h5 className="card-label font-weight-bolder text-dark">
            Anagrafica azienda
          </h5>
        </div>
        <Form.Row className="col-12">
          <Form.Group className="col-6">
            <Form.Label className="col-12">Nome azienda</Form.Label>
            <Form.Control
              name="name"
              placeholder="Nome azienda..."
              value={formik.values.name}
              onChange={formik.handleChange}
              className={getInputClasses("name")}
              {...formik.getFieldProps("name")}
            />
            {controlName ? (
              <p style={{ color: "red" }}>Nome già utilizzato</p>
            ) : null}
            {formik.touched.name && formik.errors.name ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.name}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label className="col-12">Partita IVA</Form.Label>
            <Form.Control
              name="vatNumber"
              placeholder="Partita IVA..."
              value={formik.values.vatNumber}
              onChange={formik.handleChange}
              className={getInputClasses("vatNumber")}
              {...formik.getFieldProps("vatNumber")}
            />
            {formik.touched.vatNumber && formik.errors.vatNumber ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.vatNumber}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label className="col-12">Regione</Form.Label>
            <Select
              defaultValue={selectedRegion}
              onChange={(e) => setSelectedRegion(e.value)}
              options={regions}
              name="region"
              placeholder="Seleziona regione..."
            />
            {controlRegion ? (
              <p style={{ color: "red" }}>Selezionare una regione</p>
            ) : null}
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label className="col-12">Provincia</Form.Label>
            <Select
              defaultValue={selectedProvince}
              onChange={(e) => setSelectedProvince(e.value)}
              options={provinces}
              name="province"
              isDisabled={selectedRegion === "" ? true : false}
              placeholder="Seleziona provincia..."
            />
            {controlProvince ? (
              <p style={{ color: "red" }}>Selezionare una provincia</p>
            ) : null}
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label className="col-12">Città</Form.Label>
            <Select
              defaultValue={selectedCity}
              onChange={(e) => setSelectedCity(e.value)}
              options={cities}
              name="city"
              isDisabled={selectedProvince === "" ? true : false}
              placeholder="Seleziona città..."
            />
            {controlCity ? (
              <p style={{ color: "red" }}>Selezionare una città</p>
            ) : null}
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label className="col-12">Indirizzo</Form.Label>
            <Form.Control
              name="address"
              placeholder="Indirizzo..."
              value={formik.values.address}
              onChange={formik.handleChange}
              className={getInputClasses("address")}
              {...formik.getFieldProps("address")}
            />
            {formik.touched.address && formik.errors.address ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.address}</div>
              </div>
            ) : null}
          </Form.Group>
        </Form.Row>
        <div className="col-12 my-3">
          <h5 className="card-label font-weight-bolder text-dark">Contatti</h5>
        </div>
        <Form.Row className="col-12">
          <Form.Group className="col-6">
            <Form.Label className="col-12">Telefono</Form.Label>
            <Form.Control
              name="phone"
              placeholder="Telefono..."
              value={formik.values.phone}
              onChange={formik.handleChange}
              className={getInputClasses("phone")}
              {...formik.getFieldProps("phone")}
            />
            {formik.touched.phone && formik.errors.phone ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.phone}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label className="col-12">Fax</Form.Label>
            <Form.Control
              name="fax"
              placeholder="FAX..."
              value={formik.values.fax}
              onChange={formik.handleChange}
              className={getInputClasses("fax")}
              {...formik.getFieldProps("fax")}
            />
            {formik.touched.fax && formik.errors.fax ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.fax}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label className="col-12">Email</Form.Label>
            <Form.Control
              name="email"
              placeholder="Email..."
              value={formik.values.email}
              onChange={formik.handleChange}
              className={getInputClasses("email")}
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label className="col-12">PEC</Form.Label>
            <Form.Control
              name="pec"
              placeholder="PEC..."
              value={formik.values.pec}
              onChange={formik.handleChange}
              className={getInputClasses("pec")}
              {...formik.getFieldProps("pec")}
            />
            {formik.touched.pec && formik.errors.pec ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.pec}</div>
              </div>
            ) : null}
          </Form.Group>
        </Form.Row>
        <div className="col-12 my-3">
          <h5 className="card-label font-weight-bolder text-dark">
            Arricchisci profilo
          </h5>
        </div>
        <Form.Row className="col-12">
          <Form.Group className="col-12">
            <Form.Control
              name="bio"
              as="textarea"
              rows={4}
              style={{ resize: "none" }}
              placeholder="Descrizione..."
              value={formik.values.bio}
              onChange={formik.handleChange}
              className={getInputClasses("bio")}
              {...formik.getFieldProps("bio")}
            />
            {formik.touched.bio && formik.errors.bio ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.bio}</div>
              </div>
            ) : null}
          </Form.Group>
        </Form.Row>
      </Form.Row>
      <div className="col-12">
        <Button
          onClick={formik.handleSubmit}
          className="float-right ml-3"
          
          variant="primary"
        >
          Crea
        </Button>
        <Button
          onClick={formik.resetForm}
          className="float-right mr"
          
          variant="secondary"
        >
          Reset
        </Button>
      </div>
      {controlResult ? (
        <div className="col-12 float-right">
          <p style={{ color: "green" }}>Organizzazione creata con successo!</p>
        </div>
      ) : null}
    </Form>
  );
};
