import React, { useState } from "react";
import { useSubheader } from "../../layout";
import { Modal, Button, Card } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import { Meets } from "../components/dashboardComponents/Meets";
import { PrestazioniChart } from "../components/charts/PrestazioniChart";
import { Prestazioni } from "../components/dashboardComponents/Prestazioni";
import {
  MixedWidget1,
  MixedWidget6,
  MixedWidget4,
  BaseTablesWidget1,
  BaseTablesWidget2,
  BaseTablesWidget6,
  StatsWidget11,
  ListsWidget8,
  ListsWidget10,
  ListsWidget14,
  AdvanceTablesWidget9,
  StatsWidget12,
} from "../widgets";
import { MieOrganizzazioni } from "../components/dashboardComponents/MieOrganizzazioni";
import { useSelector, shallowEqual } from "react-redux";
import { roles } from "../../../config/config";

export function Demo3Dashboard() {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const history = useHistory();
  const suhbeader = useSubheader();
  suhbeader.setTitle("Dashboard");

  var active;
  if (parseInt(user.active) === 2) {
    active = true; //Profilo non completo -> true per aprire modal avviso
  } else if (parseInt(user.active) === 1) {
    active = false; //Profilo completo
  }

  const [show, setShow] = useState(active);
  //const handleOpen = () => setShow(true);
  const handleClose = () => setShow(false);

  const ModalProfile = () => {
    return (
      <Modal show={show} onHide={handleClose}>
        <Modal.Header>
          <Modal.Title>ATTENZIONE!</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Attenzione, prima di poter proseguire nella piattaforma devi
          completare il tuo profilo, fino ad allora le funzionalità saranno
          limitate
        </Modal.Body>
        <Modal.Footer>
          <Button
            onClick={() =>
              history.push("/user-profile/personal-information", {
                from: "Dashboard",
              })
            }
          >
            Completa profilo
          </Button>
          <Button variant="danger" onClick={handleClose}>
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  return (
    <>
      <ModalProfile />

      <div className="row">
        <div className="col-sm">
          <MixedWidget1 className="card-stretch gutter-b" />
        </div>
      </div>

      {/* begin::Dashboard */}

      {/* begin::Row */}
      {parseInt(user.idRole) === roles.patient ? (
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <Prestazioni />
          </div>
        </div>
      ) : null}
      {/* end::Row */}

      {/* begin::Row */}
      {parseInt(user.idRole) === roles.doctor ? (
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <Prestazioni />
          </div>
          <div className="col-xl-8 col-lg-8 col-sm-12">
            <PrestazioniChart className="gutter-b card card-custom card-stretch" />
          </div>
        </div>
      ) : null}
      {/* end::Row */}

      <div className="row">
        <div className="col-xl-4 col-lg-4 col-sm-12">
          <Meets idOrganization={false} />
        </div>
        {/* <div className="col-xl-8 col-lg-8 col-sm-12">
          <div className="row">
            <div className="col-12">
              <StatsWidget12 id="sopra" className="gutter-b card-stretch" />
            </div>
            <div className="col-12">
              <StatsWidget12 id="sotto" className="gutter-b card-stretch" />
            </div>
          </div>
        </div> */}

        {/* begin::Row */}
        {parseInt(user.idRole) === roles.doctor ? (
          <div className="col-xl-8 col-lg-8 col-sm-12">
            <MieOrganizzazioni className="card-stretch gutter-b" />
          </div>
        ) : null}
      </div>
      {/*       <div className="row">
        <div className="col-xl-4">
          <div className="row">
            <div className="col-xl-12">
              <StatsWidget11
                className="gutter-b"
                symbolShape="circle"
                baseColor="danger"
              />
            </div>
            <div className="col-xl-12">
              <StatsWidget10
                className="gutter-b"
                symbolShape="circle"
                baseColor="info"
              />
            </div>
          </div>
        </div>
        <div className="col-xl-8">
          <ListsWidget14 className="gutter-b card-stretch" />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/*       <div className="row">
        <div className="col-lg-12 col-xxl-12">
          <AdvanceTablesWidget9 className="card-stretch gutter-b" />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className="row">
        <div className="col-xl-6">
          <ListsWidget10 className="card-stretch gutter-b" />
        </div>
        <div className="col-xl-6">
          <BaseTablesWidget1 className="card-stretch gutter-b" />
        </div>
      </div> */}
      {/* end::Row */}

      {/* begin::Row */}
      {/* <div className="row">
        <div className="col-lg-4">
          <ListsWidget8 className="card-stretch gutter-b" />
        </div>
        <div className="col-lg-8">
          <BaseTablesWidget2 className="card-stretch gutter-b" />
        </div>
      </div> */}
      {/* end::Row */}

      {/* end::Dashboard */}
    </>
  );
}
