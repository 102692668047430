/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link } from "react-router-dom";
import { shallowEqual, useSelector } from "react-redux";
import SVG from "react-inlinesvg";
import { useHistory } from "react-router-dom";
import { toAbsoluteUrl } from "../../../../_helpers";
import { roles } from "../../../../../config/config";

export function QuickUser() {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const richieste = useSelector(
    (state) => state.richiesteNotification.richieste,
    shallowEqual
  );

  /* useEffect(() => {
    GetRichieste(user.authToken).then((data) => setRichieste(data.result.pending));
    
  }, [count]);
  setTimeout(() => {
    setCount(count + 1);
  }, 10000); */

  const logoutClick = () => {
    const toggle = document.getElementById("kt_quick_user_toggle");
    if (toggle) {
      toggle.click();
    }
    history.push("/logout");
  };

  return (
    <div id="kt_quick_user" className="offcanvas offcanvas-left offcanvas p-10">
      <div className="offcanvas-content pr-5 mr-n5">
        <div className="d-flex align-items-center mt-5">
          {/* <div className="symbol symbol-100 mr-5">
            <div
              className="symbol-label"
              style={{
                backgroundImage: `url(${toAbsoluteUrl(
                  "/media/users/300_21.jpg"
                )})`,
              }}
            />
            <i className="symbol-badge bg-success" />
          </div> */}
          <div className="d-flex flex-column">
            <a
              href="#"
              className="font-weight-bold font-size-h5 text-dark-75 text-hover-primary"
            >
              {user.firstname} {user.lastname}
            </a>
            <div className="text-muted mt-1">
              {parseInt(user.idRole) === roles.doctor
                ? "Medico/Direttore"
                : "Paziente"}
            </div>
            <div className="navi mt-2">
              <a href="#" className="navi-item">
                <span className="navi-link p-0 pb-2">
                  <span className="navi-icon mr-1">
                    <span className="svg-icon-lg svg-icon-primary">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Communication/Mail-notification.svg"
                        )}
                      ></SVG>
                    </span>
                  </span>
                  <span className="navi-text text-muted text-hover-primary">
                    {user.email}
                  </span>
                </span>
              </a>
            </div>
            {/* <Link to="/logout" className="btn btn-light-primary btn-bold">
                Sign Out
              </Link> */}
            <button
              className="btn btn-light-primary btn-bold"
              onClick={logoutClick}
            >
              Logout
            </button>
          </div>
        </div>

        <div className="separator separator-dashed mt-8 mb-5" />

        <div className="navi navi-spacer-x-0 p-0">
          <Link
            to="/user-profile/personal-information/old"
            className="navi-item"
          >
            <div className="navi-link">
              <div className="symbol symbol-40 bg-light mr-3">
                <div className="symbol-label">
                  <span className="svg-icon svg-icon-md svg-icon-success">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/General/Notification2.svg"
                      )}
                    ></SVG>
                  </span>
                </div>
              </div>
              <div className="navi-text">
                <div className="font-weight-bold">Profilo</div>
                <div className="text-muted">
                  Impostazioni account{" "}
                  {parseInt(user.active) === 2 ? (
                    <span className="label label-light-danger label-inline font-weight-bold">
                      Completa profilo
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
          </Link>

          {/*<Link to="/user-profile" className="navi-item">*/}
          {/*  <div className="navi-link">*/}
          {/*    <div className="symbol symbol-40 bg-light mr-3">*/}
          {/*      <div className="symbol-label">*/}
          {/*        <span className="svg-icon svg-icon-md svg-icon-warning">*/}
          {/*          <SVG*/}
          {/*            src={toAbsoluteUrl(*/}
          {/*              "/media/svg/icons/Shopping/Chart-bar1.svg"*/}
          {/*            )}*/}
          {/*          ></SVG>*/}
          {/*        </span>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className="navi-text">*/}
          {/*      <div className="font-weight-bold">Miei messaggi</div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</Link>*/}

          {/*<Link to="/user-profile" className="navi-item">*/}
          {/*  <div className="navi-link">*/}
          {/*    <div className="symbol symbol-40 bg-light mr-3">*/}
          {/*      <div className="symbol-label">*/}
          {/*        <span className="svg-icon svg-icon-md svg-icon-danger">*/}
          {/*          <SVG*/}
          {/*            src={toAbsoluteUrl(*/}
          {/*              "/media/svg/icons/Files/Selected-file.svg"*/}
          {/*            )}*/}
          {/*          ></SVG>*/}
          {/*        </span>*/}
          {/*      </div>*/}
          {/*    </div>*/}
          {/*    <div className="navi-text">*/}
          {/*      <div className="font-weight-bold">Mie prenotazioni</div>*/}
          {/*    </div>*/}
          {/*  </div>*/}
          {/*</Link>*/}
        </div>

        <div className="separator separator-dashed my-7"></div>
        <h5 className="mb-5">Notifiche recenti</h5>
        {richieste.pending ? (
          richieste.pending.slice(0, 5).map((e, key) => (
            <div>
              <div className="d-flex align-items-center bg-light-warning rounded p-5 gutter-b">
                <span className="svg-icon svg-icon-warning mr-5">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Home/Library.svg")}
                    className="svg-icon svg-icon-lg"
                  ></SVG>
                </span>

                <div className="d-flex flex-column flex-grow-1 mr-2">
                  <a
                    href="#"
                    className="font-weight-normal text-dark-75 text-hover-primary font-size-lg mb-1"
                  >
                    Medico: {e.medic}
                  </a>
                  <span className="text-muted font-size-sm">
                    Stato: {e.status === 0 ? "In attesa" : null}
                    {e.status === 1 ? "Accettata" : null}
                    {e.status === 2 ? "Rifiutata" : null}
                  </span>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">Nessuna nuova notifica</p>
        )}
      </div>
    </div>
  );
}
