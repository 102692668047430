import arrConfig from "../config";

export const GetOrganizations = (authToken) => {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify({ accessToken: authToken }),
  };

  return fetch(arrConfig.apiUrl + "appointments/GetOrganizations.php", options);
};

export const GetInfoService = async (payload) => {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify({
      accessToken: payload.accessToken,
      idService: payload.idService,
      idOrganization: payload.idOrganization,
    }),
  };
  const result = await fetch(
    arrConfig.apiUrl + "organization/GetInfoService.php",
    options
  );
  return await result.json();
};

export const DeleteSiteFromService = async (payload) => {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify({
      accessToken: payload.accessToken,
      idSite: payload.idSite,
      idService: payload.idService,
    }),
  };
  const result = await fetch(
    arrConfig.apiUrl + "organization/DeleteSiteFromService.php",
    options
  );

  return await result.json();
};

export const UpdateService = async (payload) => {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify(payload),
  };
  const result = await fetch(
    arrConfig.apiUrl + "organization/UpdateService.php",
    options
  );

  return await result.json();
};
