import React from "react";
import { Accepted } from "./Accepted";
import { Pending } from "./Pending";
import { Refused } from "./Refused";

export const ListAccepted = ({ user, accepted }) => (
  <Accepted richieste={accepted} user={user} />
);
export const ListPending = ({ user, pending, pin }) => (
  <Pending richieste={pending} user={user} pin={pin} />
);
export const ListRefused = ({ user, refused }) => (
  <Refused richieste={refused} user={user} />
);
