/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Form, Button, Card } from "react-bootstrap";
import { Skeleton } from "@material-ui/lab";
import {
  GetCountries,
  GetRegions,
  GetProvinces,
  GetCities,
} from "../../../../../config/api/geolocal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowRight,
  faArrowRotateLeft,
} from "@fortawesome/free-solid-svg-icons";

export const StepUno = ({
  formik,
  className,
  paziente,
  setSelectPaziente,
  submit,
}) => {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const [countriesB, setCountriesB] = useState([]);
  const [regionsB, setRegionsB] = useState([]);
  const [provincesB, setProvincesB] = useState([]);
  const [citiesB, setCitiesB] = useState([]);
  const [countriesA, setCountriesA] = useState([]);
  const [regionsA, setRegionsA] = useState([]);
  const [provincesA, setProvincesA] = useState([]);
  const [citiesA, setCitiesA] = useState([]);

  useEffect(() => {
    setCountriesB("load");
    setCountriesA("load");
    GetCountries(user.authToken).then((data) => {
      setCountriesA(data.result);
      setCountriesB(data.result);
    });
  }, []);

  useEffect(() => {
    if (formik.values.countryBirth === "Italia" && paziente) {
      setRegionsB("load");
      GetRegions(user.authToken).then((data) => setRegionsB(data.result));
    }
  }, [formik.values.countryBirth]);

  useEffect(() => {
    if (formik.values.countryAddress === "Italia") {
      setRegionsA("load");
      GetRegions(user.authToken).then((data) => setRegionsA(data.result));
    }
  }, [formik.values.countryAddress]);

  useEffect(() => {
    if (paziente) {
      setProvincesB("load");
      GetProvinces(user.authToken, formik.values.regionBirth).then((data) =>
        setProvincesB(data.result)
      );
    }
  }, [formik.values.regionBirth]);

  useEffect(() => {
    setProvincesA("load");
    GetProvinces(user.authToken, formik.values.regionAddress).then((data) =>
      setProvincesA(data.result)
    );
  }, [formik.values.regionAddress]);

  useEffect(() => {
    if (paziente) {
      setCitiesB("load");
      GetCities(user.authToken, formik.values.provinceBirth).then((data) =>
        setCitiesB(data.result)
      );
    }
  }, [formik.values.provinceBirth]);

  useEffect(() => {
    setCitiesA("load");
    GetCities(user.authToken, formik.values.provinceAddress).then((data) =>
      setCitiesA(data.result)
    );
  }, [formik.values.provinceAddress]);

  return (
    <Form className={className}>
      <Form.Row>
        <h5 className="card-label font-weight-bolder text-dark my-4 col-12">
          Anagrafica
        </h5>
        <Form.Group className="col-lg-6 col-xxl-6 col-sm-12">
          <Form.Label className="col-12">Nome</Form.Label>
          <Form.Control
            type="text"
            name="firstname"
            disabled={!paziente}
            placeholder="Nome..."
            className={getInputClasses("firstname")}
            {...formik.getFieldProps("firstname")}
          />
          {formik.touched.firstname && formik.errors.firstname ? (
            <div className="invalid-feedback display-block">
              {formik.errors.firstname}
            </div>
          ) : null}
        </Form.Group>
        <Form.Group className="col-lg-6 col-xxl-6 col-sm-12">
          <Form.Label className="col-12">Cognome</Form.Label>
          <Form.Control
            type="text"
            name="lastname"
            disabled={!paziente}
            placeholder="Cognome..."
            className={getInputClasses("lastname")}
            {...formik.getFieldProps("lastname")}
          />
          {formik.touched.lastname && formik.errors.lastname ? (
            <div className="invalid-feedback display-block">
              {formik.errors.lastname}
            </div>
          ) : null}
        </Form.Group>
        <Form.Group className="col-lg-6 col-xxl-6 col-sm-12">
          <Form.Label className="col-12">Anno di nascita</Form.Label>
          <Form.Control
            type="date"
            disabled={!paziente}
            name="birthday"
            className={getInputClasses("birthday")}
            {...formik.getFieldProps("birthday")}
            required
          />
          {formik.touched.birthday && formik.errors.birthday ? (
            <div className="invalid-feedback display-block">
              {formik.errors.birthday}
            </div>
          ) : null}
        </Form.Group>
        <Form.Group className="col-lg-6 col-xxl-6 col-sm-12">
          <Form.Label className="col-12">Seleziona genere</Form.Label>
          {paziente ? (
            <select
              className={`form-control ${getInputClasses("gender")}`}
              {...formik.getFieldProps("gender")}
            >
              <option value="">Seleziona genere</option>
              <option value="Donna">Donna</option>
              <option value="Uomo">Uomo</option>
              <option value="Altro">Preferisco non specificarlo</option>
            </select>
          ) : (
            <Form.Control
              name="gender"
              className={getInputClasses("gender")}
              {...formik.getFieldProps("gender")}
              disabled={true}
            />
          )}
          {formik.touched.gender && formik.errors.gender ? (
            <div className="invalid-feedback display-block">
              {formik.errors.gender}
            </div>
          ) : null}
        </Form.Group>
        <Form.Group className="col-lg-6 col-xxl-6 col-sm-12">
          <Form.Label className="col-12">Nazione di nascita</Form.Label>
          {paziente ? (
            <select
              name="countryBirth"
              className={`form-control ${getInputClasses("countryBirth")}`}
              {...formik.getFieldProps("countryBirth")}
            >
              <option value="">Seleziona nazione</option>
              {Array.isArray(countriesB) ? (
                countriesB.map((e, key) => (
                  <option key={key} value={e.value}>
                    {e.label}
                  </option>
                ))
              ) : (
                <Skeleton />
              )}
            </select>
          ) : (
            <Form.Control
              name="countryBirth"
              className={getInputClasses("countryBirth")}
              {...formik.getFieldProps("countryBirth")}
              disabled={true}
            />
          )}
          {formik.touched.countryBirth && formik.errors.countryBirth ? (
            <div className="invalid-feedback display-block">
              {formik.errors.countryBirth}
            </div>
          ) : null}
        </Form.Group>
        <Form.Group className="col-lg-6 col-xxl-6 col-sm-12">
          <Form.Label className="col-12">Regione di nascita</Form.Label>
          {paziente ? (
            <select
              name="regionBirth"
              className={`form-control ${getInputClasses("regionBirth")}`}
              {...formik.getFieldProps("regionBirth")}
            >
              <option value="">Seleziona regione</option>
              {Array.isArray(regionsB)
                ? regionsB.map((e, key) => (
                    <option key={key} value={e.value}>
                      {e.label}
                    </option>
                  ))
                : null}
            </select>
          ) : (
            <Form.Control
              name="regionBirth"
              className={getInputClasses("regionBirth")}
              {...formik.getFieldProps("regionBirth")}
              disabled={true}
            />
          )}
          {formik.touched.regionBirth && formik.errors.regionBirth ? (
            <div className="invalid-feedback display-block">
              {formik.errors.regionBirth}
            </div>
          ) : null}
        </Form.Group>
        <Form.Group className="col-lg-6 col-xxl-6 col-sm-12">
          <Form.Label className="col-12">Provincia di nascita</Form.Label>
          {paziente ? (
            <select
              name="provinceBirth"
              className={`form-control ${getInputClasses("provinceBirth")}`}
              {...formik.getFieldProps("provinceBirth")}
            >
              <option value="">Seleziona provincia</option>
              {Array.isArray(provincesB) ? (
                provincesB.map((e, key) => (
                  <option key={key} value={e.value}>
                    {e.label}
                  </option>
                ))
              ) : (
                <Skeleton />
              )}
            </select>
          ) : (
            <Form.Control
              name="provinceBirth"
              className={getInputClasses("provinceBirth")}
              {...formik.getFieldProps("provinceBirth")}
              disabled={true}
            />
          )}
          {formik.touched.provinceBirth && formik.errors.provinceBirth ? (
            <div className="invalid-feedback display-block">
              {formik.errors.provinceBirth}
            </div>
          ) : null}
        </Form.Group>
        <Form.Group className="col-lg-6 col-xxl-6 col-sm-12">
          <Form.Label className="col-12">Città di nascita</Form.Label>
          {paziente ? (
            <select
              name="cityBirth"
              className={`form-control ${getInputClasses("cityBirth")}`}
              {...formik.getFieldProps("cityBirth")}
            >
              <option value="">Seleziona provincia</option>
              {Array.isArray(citiesB) ? (
                citiesB.map((e, key) => (
                  <option key={key} value={e.value}>
                    {e.label}
                  </option>
                ))
              ) : (
                <Skeleton />
              )}
            </select>
          ) : (
            <Form.Control
              name="cityBirth"
              className={getInputClasses("cityBirth")}
              {...formik.getFieldProps("cityBirth")}
              disabled={true}
            />
          )}
          {formik.touched.cityBirth && formik.errors.cityBirth ? (
            <div className="invalid-feedback display-block">
              {formik.errors.cityBirth}
            </div>
          ) : null}
        </Form.Group>
        <Form.Group className="col-xxl-6 col-lg-6 col-sm-12">
          <Form.Label className="col-12">Codice fiscale</Form.Label>
          <Form.Control
            type="text"
            name="ssn"
            disabled={!paziente}
            className={getInputClasses("ssn")}
            {...formik.getFieldProps("ssn")}
          />
          {formik.touched.ssn && formik.errors.ssn ? (
            <div className="invalid-feedback display-block">
              {formik.errors.ssn}
            </div>
          ) : null}
        </Form.Group>
        <Form.Group className="col-xxl-6 col-lg-6 col-sm-12">
          <Form.Label className="col-12">N° tessera sanitaria</Form.Label>
          <Form.Control
            type="text"
            name="tesseraSanitaria"
            disabled={!paziente}
            className={getInputClasses("tesseraSanitaria")}
            {...formik.getFieldProps("tesseraSanitaria")}
          />
          {formik.touched.tesseraSanitaria && formik.errors.tesseraSanitaria ? (
            <div className="invalid-feedback display-block">
              {formik.errors.tesseraSanitaria}
            </div>
          ) : null}
        </Form.Group>
        <h5 className="card-label font-weight-bolder text-dark my-4 col-12">
          Residenza o domicilio
        </h5>
        <Form.Group className="col-lg-6 col-xxl-6 col-sm-12">
          <Form.Label className="col-12">Nazione di residenza</Form.Label>
          <select
            name="countryAddress"
            className={`form-control ${getInputClasses("countryAddress")}`}
            {...formik.getFieldProps("countryAddress")}
          >
            <option value="">Seleziona nazione</option>
            {Array.isArray(countriesA) ? (
              countriesA.map((e, key) => (
                <option key={key} value={e.value}>
                  {e.label}
                </option>
              ))
            ) : (
              <Skeleton />
            )}
          </select>
          {formik.touched.countryAddress && formik.errors.countryAddress ? (
            <div className="invalid-feedback display-block">
              {formik.errors.countryAddress}
            </div>
          ) : null}
        </Form.Group>
        <Form.Group className="col-lg-6 col-xxl-6 col-sm-12">
          <Form.Label className="col-12">Regione di residenza</Form.Label>
          <select
            name="regionAddress"
            className={`form-control ${getInputClasses("regionAddress")}`}
            {...formik.getFieldProps("regionAddress")}
          >
            <option value="">Seleziona regione</option>
            {Array.isArray(regionsA) ? (
              regionsA.map((e, key) => (
                <option key={key} value={e.value}>
                  {e.label}
                </option>
              ))
            ) : (
              <Skeleton />
            )}
          </select>
          {formik.touched.regionAddress && formik.errors.regionAddress ? (
            <div className="invalid-feedback display-block">
              {formik.errors.regionAddress}
            </div>
          ) : null}
        </Form.Group>
        <Form.Group className="col-lg-6 col-xxl-6 col-sm-12">
          <Form.Label className="col-12">Provincia di residenza</Form.Label>
          <select
            name="provinceAddress"
            className={`form-control ${getInputClasses("provinceAddress")}`}
            {...formik.getFieldProps("provinceAddress")}
          >
            <option value="">Seleziona provincia</option>
            {Array.isArray(provincesA) ? (
              provincesA.map((e, key) => (
                <option key={key} value={e.value}>
                  {e.label}
                </option>
              ))
            ) : (
              <Skeleton />
            )}
          </select>
          {formik.touched.provinceAddress && formik.errors.provinceAddress ? (
            <div className="invalid-feedback display-block">
              {formik.errors.provinceAddress}
            </div>
          ) : null}
        </Form.Group>
        <Form.Group className="col-lg-6 col-xxl-6 col-sm-12">
          <Form.Label className="col-12">Città di residenza</Form.Label>
          <select
            name="cityAddress"
            className={`form-control ${getInputClasses("cityAddress")}`}
            {...formik.getFieldProps("cityAddress")}
          >
            <option value="">Seleziona città</option>
            {Array.isArray(citiesA) ? (
              citiesA.map((e, key) => (
                <option key={key} value={e.value}>
                  {e.label}
                </option>
              ))
            ) : (
              <Skeleton />
            )}
          </select>
          {formik.touched.cityAddress && formik.errors.cityAddress ? (
            <div className="invalid-feedback display-block">
              {formik.errors.cityAddress}
            </div>
          ) : null}
        </Form.Group>
        <Form.Group className="col-lg-6 col-xxl-6 col-sm-12">
          <Form.Label className="col-12">Indirizzo residenza</Form.Label>
          <Form.Control
            name="address"
            className={getInputClasses("address")}
            placeholder="Indirizzo..."
            {...formik.getFieldProps("address")}
          />
          {formik.touched.address && formik.errors.address ? (
            <div className="invalid-feedback display-block">
              {formik.errors.address}
            </div>
          ) : null}
        </Form.Group>
      </Form.Row>
      <div className="pt-3">
        <Button onClick={submit} className="float-right">
          <FontAwesomeIcon icon={faArrowRight} />
        </Button>
        <Button
          onClick={() => setSelectPaziente(true)}
          className="float-right mr-3"
        >
          <FontAwesomeIcon icon={faArrowRotateLeft} />
        </Button>
      </div>
    </Form>
  );
};
