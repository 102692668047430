import arrConfig from "../config";

export const GetPrestazioni = (authToken) => {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify({ accessToken: authToken }),
  };

  return fetch(arrConfig.apiUrl + "appointments/GetPrestazioni.php", options);
};