/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export function StickyToolbar({
  setPage,
  setVideoconference,
}) {
  return (
    <>
      <ul className="sticky-toolbar nav flex-column pl-2 pr-2 pt-3 pb-3 mt-4">
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id="documentations-tooltip">Nuovo appuntamento</Tooltip>
          }
        >
          <li className="nav-item mb-2" data-placement="left">
            <a
              className="btn btn-sm btn-icon btn-bg-light btn-text-primary btn-hover-primary"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                setVideoconference(0);
                setPage("newAppointment");
              }}
            >
              <i className="fa-solid fa-calendar-days"></i>
            </a>
          </li>
        </OverlayTrigger>
        <OverlayTrigger
          placement="left"
          overlay={
            <Tooltip id="documentations-tooltip">Nuovo teleconsulto</Tooltip>
          }
        >
          <li className="nav-item mb-2" data-placement="left">
            <a
              className="btn btn-sm btn-icon btn-bg-light btn-text-danger btn-hover-danger"
              target="_blank"
              rel="noopener noreferrer"
              onClick={() => {
                setVideoconference(1);
                setPage("newAppointment");
              }}
            >
              <i className="fa-solid fa-camera"></i>
            </a>
          </li>
        </OverlayTrigger>
      </ul>
    </>
  );
}
