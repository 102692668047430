import arrConfig from "../config";

export const CreateAppointment = async (payload) => {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: payload,
  };
  const result = await fetch(
    arrConfig.apiUrl + "calendar/CreateAppointment.php",
    options
  );
  return await result.json();
};

export const CreateAppointmentAndUser = async (payload) => {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: payload,
  };
  const result = await fetch(
    arrConfig.apiUrl + "calendar/CreateAppointmentAndUser.php",
    options
  );
  return await result.json();
};

export const GetAppuntamentiTabella = async (payload) => {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify(payload),
  };
  const result = await fetch(
    arrConfig.apiUrl + "calendar/GetAppointmentsTabella.php",
    options
  );
  return await result.json();
};

export const EditAppointment = async (payload) => {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify(payload),
  };
  const result = await fetch(
    arrConfig.apiUrl + "appointments/EditAppointment.php",
    options
  );
  return await result.json();
};

export async function AcceptDeclineAppointment(payload) {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify(payload),
  };
  const result = await fetch(
    arrConfig.apiUrl + "appointments/AcceptDeclineAppointment.php",
    options
  );
  return await result.json();
}

export async function UpdateStatusAppointment(payload) {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify(payload),
  };
  const result = await fetch(
    arrConfig.apiUrl + "appointments/UpdateStatusAppointment.php",
    options
  );
  return await result.json();
}
