/* eslint-disable no-restricted-imports */
import React, { useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  SearchPanel,
  TableRowDetail,
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
  RowDetailState,
} from "@devexpress/dx-react-grid";
import { makeStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { encrypt } from "../../../../config/utils/crypt";
import { HandleRichiesta } from "../../../../config/api/cartellaDigitale";
import { Chip } from "@material-ui/core";
import {
  faCircleCheck,
  faCirclePause,
  faCircleXmark,
  faCheck,
  faXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";

export const Pending = (props) => {
  const [loading, setLoading] = useState(false);
  const [columns] = useState([
    { name: "medic", title: "Medico" },
    { name: "dateCreated", title: "Data richiesta" },
  ]);

  const [rows] = useState(props.richieste);
  const user = useSelector((state) => state.auth.user, shallowEqual);

  const [pageSizes] = useState([10, 20, 50, 0]);
  const [currentPage, setCurrentPage] = useState(10);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchState] = useState("");

  const DataRichiestaColumn = (props) => (
    <DataTypeProvider
      formatterComponent={({ value }) =>
        moment(value).format("DD MMMM YYYY, HH:mm")
      }
      {...props}
    />
  );
  const DataAggiornamentoColumn = (props) => (
    <DataTypeProvider
      formatterComponent={({ value }) =>
        moment(value).format("DD MMMM YYYY, HH:mm")
      }
      {...props}
    />
  );

  const StatoColumn = ({ value }) => {
    value = parseInt(value);
    /*eslint-disable-next-line*/
    if (value == 1)
      return (
        // accettato 1
        <div className="pl-3">
          <Chip
            color="primary"
            variant="outlined"
            label="Accettata"
            icon={
              <FontAwesomeIcon
                icon={faCircleCheck}
                className="svg-icon-success svg-icon-md"
              />
            }
            style={{ borderColor: "var(--success)", color: "var(--success)" }}
          />
        </div>
      );
    /*eslint-disable-next-line*/
    if (value == 0)
      return (
        // attesa 0
        <div className="pl-3">
          <Chip
            color="primary"
            variant="outlined"
            label="In attesa"
            icon={
              <FontAwesomeIcon
                icon={faCirclePause}
                className="svg-icon-warning svg-icon-md"
              />
            }
            style={{ borderColor: "var(--warning)", color: "var(--warning)" }}
          />
        </div>
      );
    /*eslint-disable-next-line*/
    if (value == -1)
      return (
        // rifiutato -1
        <div className="pl-3">
          <Chip
            color="primary"
            variant="outlined"
            label="Rifiutata"
            icon={
              <FontAwesomeIcon
                icon={faCircleXmark}
                className="svg-icon-danger svg-icon-md"
                color="danger"
              />
            }
            style={{ borderColor: "var(--danger)", color: "var(--danger)" }}
          />
        </div>
      );
  };

  const StatoFormattedColumn = (props) => (
    <DataTypeProvider formatterComponent={StatoColumn} {...props} />
  );

  const makeActionBtn = ({
    name,
    disabled,
    tooltip,
    value,
    variant,
    icon,
    pin = "",
    idRichiesta,
    dateExpire = "",
    accessToken,
  }) => {
    const btn = (
      <Button
        variant={variant}
        className={`mx-3 my-2`}
        size="sm"
        disabled={disabled || loading ? true : false}
        title={name}
        onClick={() =>
          HandleRichiesta({
            idRichiesta: idRichiesta,
            action: value,
            dateExpire: dateExpire,
            accessToken: accessToken,
            pin: pin,
          })
        }
      >
        {typeof icon == "string" ? (
          <span className={`svg-icon svg-icon-md svg-icon-white`}>
            {" "}
            <SVG src={toAbsoluteUrl(icon)} />
          </span>
        ) : (
          <FontAwesomeIcon
            icon={icon}
            className={`svg-icon-white`}
            style={{ marginRight: "0.5rem" }}
          />
        )}
        {/* <span className={`text-${variant}`}></span> */}
        {name}
      </Button>
    );
    if (!tooltip) return btn;
    return (
      <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
        {btn}
      </OverlayTrigger>
    );
  };

  const useStyles = makeStyles((theme) => ({
    container: {
      display: "flex",
      flexWrap: "wrap",
    },
    textField: {
      marginLeft: theme.spacing(1),
      marginRight: theme.spacing(1),
      width: 200,
    },
  }));

  const classes = useStyles();

  const RowDetail = ({ row }) => {
    const [expire, setExpire] = useState(
      moment(new Date()).format("YYYY-MM-DDTHH:mm")
    );

    return (
      <>
        <div className="card">
          <>
            <div className="col-12">
              <>
                <div className="p-8">
                  <div className="d-flex align-items-center justify-content-xl-between mb-4">
                    <span className="font-weight-bold text-muted font-size-sm mr-2">
                      Tempo di autorizzazione
                    </span>
                    <span className="font-weight-bolder text-primary text-right">
                      <form className={classes.container} noValidate>
                        <TextField
                          id="datetime-local"
                          label="Fine autorizzazione"
                          type="datetime-local"
                          onChange={(e) => setExpire(e.target.value)}
                          defaultValue={moment(new Date()).format(
                            "YYYY-MM-DDTHH:MM"
                          )}
                          className={classes.textField}
                          InputProps={{
                            min: moment(new Date()).format("YYYY-MM-DDTHH:mm"),
                          }}
                          InputLabelProps={{
                            shrink: true,
                            color: "primary",
                          }}
                        />
                      </form>
                    </span>
                  </div>
                </div>
                <div className="separator separator-solid"></div>
              </>
            </div>
          </>
          <div className="separator separator-solid"></div>
          <div>
            <div className="float-xl-right">
              {makeActionBtn({
                name: "Accetta",
                variant: "primary",
                action: "accept",
                icon: faCheck,
                accessToken: user.authToken,
                pin: encrypt(props.pin, row.token),
                dateExpire: expire,
                idRichiesta: row.id,
                value: 1,
              })}
              {makeActionBtn({
                name: "Rifiuta",
                variant: "danger",
                action: "refuse",
                value: 0,
                accessToken: user.authToken,
                idRichiesta: row.id,
                dateExpire: expire,
                icon: faXmark,
              })}
            </div>
          </div>
        </div>
      </>
    );
  };

  const TableComponent = ({ ...restProps }) => (
    <Table.Table
      {...restProps}
      className="table table-hover table-head-custom table-vertical-center overflow-hidden"
    />
  );

  return (
    <>
      <Grid rows={rows ? rows : []} columns={columns ? columns : []}>
        <RowDetailState />
        <SearchState value={searchValue} onValueChange={setSearchState} />
        <DataRichiestaColumn for={["dateCreated"]} />
        <DataAggiornamentoColumn for={["dateUpdated"]} />
        <StatoFormattedColumn for={["status"]} />
        <IntegratedFiltering />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
        <IntegratedPaging />
        <Table tableComponent={TableComponent} />
        <TableHeaderRow />
        <PagingPanel pageSizes={pageSizes} />
        <Toolbar />
        <SearchPanel />
        <TableRowDetail contentComponent={RowDetail} />
      </Grid>
    </>
  );
};
