import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Button, Form } from "react-bootstrap";
import config from "../../../../../config/config";
import moment from "moment";
import Skeleton from "@material-ui/lab/Skeleton";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faArrowRight,
} from "@fortawesome/free-solid-svg-icons";

export const StepDue = (props) => {
  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );

  const [loading, setLoading] = useState(false);
  const [loadingMedics, setLoadingMedics] = useState(false);
  const [loadingRange, setLoadingRange] = useState(false);
  const [services, setServices] = useState([]);
  const [selectedService, setSelectedService] = useState(0);
  const [idService, setIdService] = useState(0);
  const [idDoc, setIdDoc] = useState(0);
  const [idOrganization, setIdOrganization] = useState(0);
  const [medics, setMedics] = useState([]);

  const [minDate, setMinDate] = useState();
  const [maxDate, setMaxDate] = useState();
  const [minEndDate, setMinEndDate] = useState();

  const [startDate, setStartDate] = useState(
    moment(minDate).format("YYYY-MM-DD")
  );
  const [endDate, setEndDate] = useState(startDate);

  const GetServices = () => {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: organization !== undefined ? organization.id : false,
      }),
    };
    fetch(config.apiUrl + "appointments/GetService.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setServices(data))
      .then(setLoading(false));
  };

  const GetMedicsForService = () => {
    setLoadingMedics(true);
    const requestOptions = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idService: idService,
      }),
    };
    fetch(
      config.apiUrl + "appointments/getMedicsForServices.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        if (data.length > 0) {
          setIdOrganization(data[0].idOrganization);
          setMedics(data);
        }
      })
      .then(() => setLoadingMedics(false));
  };

  const GetRangeDate = () => {
    setLoadingRange(true);
    const requestOptions = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idService: idService,
        idDoc: idDoc,
      }),
    };
    fetch(config.apiUrl + "appointments/GetRangeDate.php", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setStartDate(moment(data.minDate).format("YYYY-MM-DD"));
        setEndDate(moment(data.minDate).format("YYYY-MM-DD"));
        setMinDate(data.minDate);
        setMaxDate(data.maxDate);
      })
      .then(() => setLoadingRange(false));
  };

  useEffect(() => {
    GetServices();
    /*eslint-disable-next-line*/
  }, []);

  useEffect(() => {
    if (parseInt(idService) !== 0) {
      GetMedicsForService();
    }
    /*eslint-disable-next-line*/
  }, [idService]);

  useEffect(() => {
    if (parseInt(idDoc) !== 0) {
      GetRangeDate();
    }
    /*eslint-disable-next-line*/
  }, [idDoc]);

  return (
    <Form className="mx-4 my-4">
      <Form.Row>
        <h5 className="card-label font-weight-bolder text-dark">
          Seleziona tipo di prestazione e azienda
        </h5>
        {!loading ? (
          <Form.Group className="col-12">
            <Form.Label>Prestazione</Form.Label>
            {organization === undefined ? (
              <select
                name="service"
                value={selectedService}
                className={`form-control`}
                onChange={(e) => {
                  props.setNameService(e.target.value);
                  setSelectedService(e.target.value);
                }}
              >
                <option value={false}>Seleziona tipologia</option>
                {Object.keys(services).map((key, i) => (
                  <option value={key} key={key}>
                    {key}
                  </option>
                ))}
              </select>
            ) : (
              <select
                name="service"
                value={idService}
                className={`form-control`}
                onChange={(e) => setIdService(e.target.value)}
              >
                <option value={false}>Seleziona tipologia</option>
                {services.map((e, key) => (
                  <option value={e.id} key={key}>
                    {e.name}
                  </option>
                ))}
              </select>
            )}
          </Form.Group>
        ) : (
          <Skeleton />
        )}

        {parseInt(selectedService) !== 0 ? (
          <Form.Group className="col-12">
            <Form.Label>Centro/Studio medico</Form.Label>
            <select
              className={`form-control`}
              name="organization"
              value={idService}
              onChange={(e) => setIdService(e.target.value)}
            >
              <option value={0}>Seleziona il centro</option>
              {selectedService !== 0
                ? Object.keys(services)
                    .filter((key) => selectedService.includes(key))
                    .reduce((obj, key) => {
                      return services[key].map((e, key) => (
                        <option value={e["idPrestazione"]} key={key}>
                          {e["nameOrganization"]}
                        </option>
                      ));
                    }, {})
                : null}
            </select>
          </Form.Group>
        ) : null}

        {parseInt(idService) !== 0 ? (
          !loadingMedics ? (
            <Form.Group className="col-12">
              <Form.Label>Medico</Form.Label>
              <select
                className="form-control"
                name="medic"
                value={idDoc}
                onChange={(e) => setIdDoc(e.target.value)}
              >
                <option value={0}>Seleziona medico</option>
                {medics.map((e, key) => (
                  <option key={key} value={e.idUser}>
                    {e.medic}
                  </option>
                ))}
              </select>
            </Form.Group>
          ) : null
        ) : null}
        {parseInt(idDoc) !== 0 && !loadingRange ? (
          <>
            <h5 className="card-label font-weight-bolder text-dark col-12">
              Seleziona in quali date cercare
            </h5>
            <Form.Group className="col-6">
              <Form.Label className="col-12">Inizio</Form.Label>
              <input
                className="col-12 form-control"
                type="date"
                min={moment(minDate).format("YYYY-MM-DD")}
                max={moment(maxDate).format("YYYY-MM-DD")}
                value={moment(startDate).format("YYYY-MM-DD")}
                onChange={(e) => {
                  setStartDate(e.target.value);
                  setMinEndDate(moment(e.target.value).format("YYYY-MM-DD"));
                  setEndDate(e.target.value);
                }}
              />
            </Form.Group>
            <Form.Group className="col-6">
              <Form.Label className="col-12">Fine</Form.Label>
              <input
                className="col-12 form-control"
                type="date"
                min={moment(minEndDate).format("YYYY-MM-DD")}
                max={moment(maxDate).format("YYYY-MM-DD")}
                value={moment(endDate).format("YYYY-MM-DD")}
                onChange={(e) => setEndDate(e.target.value)}
              />
            </Form.Group>{" "}
          </>
        ) : null}
      </Form.Row>

      <Button
        className="float-right ml"
        onClick={() => {
          props.setStepDueSelect({
            idService: idService,
            idDoc: idDoc,
            idOrganization: idOrganization,
            startDate: startDate,
            endDate: endDate,
          });
          props.setStep(props.step + 1);
        }}
        disabled={
          organization === undefined
            ? parseInt(selectedService) === 0
            : null ||
              parseInt(idService) === 0 ||
              parseInt(idDoc) === 0 ||
              startDate === "" ||
              endDate === ""
            ? true
            : false
        }
      >
        <FontAwesomeIcon icon={faArrowRight} />
      </Button>
      <Button
        className="float-right mr-2"
        onClick={() => props.setStep(props.step - 1)}
      >
        <FontAwesomeIcon icon={faArrowLeft} />
      </Button>
    </Form>
  );
};
