import React, { useRef, useEffect, useState } from "react";
import { shallowEqual, useSelector, connect, useDispatch } from "react-redux";
import * as auth from "./authRedux";
import { GetRichieste } from "./authCrud";
import deepEqual from "deep-equal";

function AuthInit(props) {
  const didRequest = useRef(false);
  const dispatch = useDispatch();
  const richieste = useSelector(
    (state) => state.richiesteNotification.richieste,
    shallowEqual
  );
  const { authToken } = useSelector(
    ({ auth }) => ({
      authToken: auth.authToken,
    }),

    shallowEqual
  );
  const [count, setCount] = useState(0);

  // We should request user by authToken before rendering the application
  useEffect(() => {
    const requestUser = async () => {
      try {
        if (!didRequest.current) {
          if (authToken) {
            const newRichieste = await GetRichieste(authToken);
            if (!deepEqual(richieste, newRichieste.result)) {
              dispatch(auth.actions.setRichieste(newRichieste.result));
            }
          }
        }
      } catch (error) {
        console.error(error);
      }

      return () => (didRequest.current = true);
    };

    if (authToken) {
      requestUser(authToken);
    } else {
      dispatch(auth.actionTypes.SetRichieste([]));
    }
    //eslint-disable-next-line
  }, [count]);

  setTimeout(() => {
    setCount(count + 1);
  }, 10000);

  return <></>;
}

export default connect(null, auth.actions)(AuthInit);
