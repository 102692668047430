import React, { useEffect, useState } from "react";
import ApexCharts from "apexcharts";
import { Card, Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";

export const Riepilogo = ({ setPage, summary, label }) => {
  const [render, setRender] = useState(false);
  const [data, setData] = useState([]);
  const [categories, setCategories] = useState([]);

  var options = {
    chart: {
      type: "area",
    },
    series: [
      {
        name: "mg",
        data: data,
      },
    ],
    xaxis: {
      categories: categories,
    },
  };

  var chart = new ApexCharts(document.querySelector("#chart"), options);

  useEffect(() => {
    if (!render) {
      let newData = [];
      let newCategories = [];
      for (var el in summary) {
        newData.push(summary[el].lineValue);
        newCategories.push(summary[el].argument);
      }
      setData(newData);
      setCategories(newCategories);
      setTimeout(() => {
        setRender(true);
      }, 50);
    } else {
      chart.render();
    }
    /*eslint-disable-next-line*/
  }, [render]);

  return (
    <Card className="custom-card gutter-b">
      <Card.Header className="border-0 py-4 row">
        <Card.Title className="col-6 float-left pt-3">{label}</Card.Title>
        <div className="col-6 text-right">
          <Button
            className="mr-4"
            variant="success"
            onClick={() => {
              setPage("cards");
              setRender(false);
            }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        </div>
        <Card.Body>
          <div className="col-12">
            <div className="col-12" id="chart"></div>
          </div>
        </Card.Body>
      </Card.Header>
    </Card>
  );
};
