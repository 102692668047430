import React, { useState, useEffect } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import OrganizationInformation from "./OrganizationComponents/OrganizationInformation";
import { useSelector, shallowEqual } from "react-redux";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import config, { roles } from "../../config/config";
import { toAbsoluteUrl } from "../../_metronic/_helpers";
import { Button } from "react-bootstrap";

export const OrganizationPage = ({ history }) => {
  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );

  if (organization === undefined) {
    history.push("/dashboard", { from: "organizzazione/info/profile" });
  }

  const user = useSelector((state) => state.auth.user, shallowEqual);
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(false);

  const GetPageInfo = () => {
    setLoading(true);
    const options = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: organization.id,
        nameOrganization: organization.name,
        idUser: user.id,
      }),
    };
    fetch(config.apiUrl + "organization/GetPageInfo.php", options)
      .then((response) => response.json())
      .then((data) => setInfo(data))
      .then(() => setLoading(false));
  };

  const RequestAccess = () => {
    setLoading(true);
    const options = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: organization.id,
        idUser: user.id,
      }),
    };

    fetch(config.apiUrl + "organization/RequestAccess.php", options)
      .then((response) => response.json())
      .then((data) => {
        if (data === true) {
          setMessage(true);
          setLoading(false);
        }
      });
  };

  const RequestStatus = () => {
    setLoading(true);
    const options = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: organization.id,
        idUser: user.id,
      }),
    };

    fetch(config.apiUrl + "organization/RequestStatus.php", options)
      .then((response) => response.json())
      .then((data) => {
        if (data === true) {
          setMessage(true);
          setLoading(false);
        }
      });
  };

  useEffect(() => {
    if (organization !== undefined) {
      GetPageInfo();
      RequestStatus();
    }
    /*eslint-disable-next-line*/
  }, [window.location.href]);

  if (organization !== undefined) {
    return (
      <>
        {!loading ? (
          <>
            <div className="d-flex flex-row">
              <div
                className="flex-row-auto offcanvas-mobile w-250px w-xxl-350px"
                id="kt_profile_aside"
              >
                <div className="card card-custom card-stretch">
                  <div className="card-body pt-4">
                    <div className="d-flex justify-content-end"></div>

                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-60 symbol-xxl-100 mr-5 align-self-start align-self-xxl-center">
                        <div
                          className="symbol-label"
                          style={{ backgroundImage: `url(${info.profilePic})` }}
                        ></div>
                        {/* style="background-i
                  mage:url('/metronic/theme/html/demo1/dist/assets/media/users/300_21.jpg')" */}
                      </div>
                      <div>
                        <p className="font-weight-bolder font-size-h5 text-dark-75 text-hover-primary">
                          {info.name}
                        </p>
                        {parseInt(user.idRole) === roles.doctor &&
                        organization.memberType === "Ospite" ? (
                          <Button
                            onClick={() => RequestAccess()}
                            size="sm"
                            disabled={message}
                          >
                            {message
                              ? "Richiesta in attesa"
                              : "Richiedi accesso"}
                          </Button>
                        ) : null}
                        {user.role === roles.doctor &&
                        (organization.memberType === "Medico" ||
                          organization.memberType === "Mod") ? (
                          <Button size="sm" variant="danger">
                            Abbandona organizzazione
                          </Button>
                        ) : null}
                        <div className="text-muted">{info.bio}</div>
                      </div>
                    </div>

                    <div className="py-9">
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="font-weight-bold mr-2">Email:</span>
                        <span className="text-muted text-hover-primary">
                          {info.email}
                        </span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="font-weight-bold mr-2">Telefono:</span>
                        <span className="text-muted">{info.phone}</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between mb-2">
                        <span className="font-weight-bold mr-2">PEC:</span>
                        <span className="text-muted">{info.pec}</span>
                      </div>
                      <div className="d-flex align-items-center justify-content-between">
                        <span className="font-weight-bold mr-2">FAX:</span>
                        <span className="text-muted">{info.fax}</span>
                      </div>
                    </div>
                    {/* begin::Nav */}
                    <div className="navi navi-bold navi-hover navi-active navi-link-rounded">
                      <div className="navi-item mb-2">
                        <NavLink
                          to={`/pagina-organizzazione/informazioni-organizzazione`}
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Design/Layers.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">
                            Profilo organizzazione
                          </span>
                        </NavLink>
                      </div>
                      <div className="navi-item mb-2">
                        <NavLink
                          to="/user-profile/personal-information"
                          className="navi-link py-4"
                          activeClassName="active"
                        >
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/General/User.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">
                            I nostri servizi
                          </span>
                        </NavLink>
                      </div>

                      <div className="navi-item mb-2">
                        <p className="navi-link py-4">
                          <span className="navi-icon mr-2">
                            <span className="svg-icon">
                              <SVG
                                src={toAbsoluteUrl(
                                  "/media/svg/icons/Layout/Layout-top-panel-6.svg"
                                )}
                              ></SVG>{" "}
                            </span>
                          </span>
                          <span className="navi-text font-size-lg">
                            Metodi di pagamento accettati
                          </span>
                        </p>
                      </div>
                    </div>
                    {/* end::Nav */}
                  </div>
                </div>
              </div>
              <div className="flex-row-fluid ml-lg-8">
                <Switch>
                  <Redirect
                    from={`/organizzazione/info/`}
                    exact={true}
                    to={`/organizzazione/info/profile/`}
                  />
                  <Route
                    path={`/organizzazione/info/profile/`}
                    component={OrganizationInformation}
                  />
                  {/* <Route
            path="/user-profile/account-information"
            component={AccountInformation}
          /> */}
                  {/* <Route
            path="/user-profile/change-password"
            component={ChangePassword}
            />
         
            <Route
            path="/user-profile/personal-information"
            component={PersonaInformation}
          /> */}
                </Switch>
              </div>
            </div>
          </>
        ) : null}
      </>
    );
  } else return <></>;
};
