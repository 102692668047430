/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useMemo, useState } from "react";
import objectPath from "object-path";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useLocation } from "react-router";
import { useSelector, shallowEqual } from "react-redux";
import SVG from "react-inlinesvg";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { toAbsoluteUrl, checkIsActive } from "../../../_helpers";
import { NavLink } from "react-router-dom";
import { AsideMenu } from "./aside-menu/AsideMenu";
import { QuickUserToggler } from "../extras/QuickUserToggler";
import { Brand } from "../brand/Brand";
import { KTUtil } from "./../../../_assets/js/components/util";
import { roles } from "../../../../config/config";
import { useHistory } from "react-router-dom";

export function Aside() {
  const uiService = useHtmlClassService();
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const richieste = useSelector(
    (state) => state.richiesteNotification.richieste,
    shallowEqual
  );
  const history = useHistory();
  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );

  var url;
  if (window.location.href.indexOf("organizzazione") > -1) {
    url = true;
  } else {
    url = false;
  }

  var active;
  if (parseInt(user.active) === 2) {
    active = false; //profilo non completo -> disattivare funzioni principali
  } else if (parseInt(user.active) === 1) {
    active = true; //profilo completo
  }

  const layoutProps = useMemo(() => {
    return {
      asideClassesFromConfig: uiService.getClasses("aside", true),
      asideSecondaryDisplay: objectPath.get(
        uiService.config,
        "aside.secondary.display"
      ),
      asideSelfMinimizeToggle: objectPath.get(
        uiService.config,
        "aside.self.minimize.toggle"
      ),
      extrasSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      extrasNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      extrasQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      extrasQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      extrasLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      extrasUserDisplay: objectPath.get(
        uiService.config,
        "extras.user.display"
      ),
    };
  }, [uiService]);

  const tabs = {
    tabId2: "kt_aside_tab_2",
  };
  const [activeTab, setActiveTab] = useState(tabs.tabId2);
  const location = useLocation();
  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  const handleTabChange = (id) => {
    setActiveTab(id);
    const asideWorkspace = KTUtil.find(
      document.getElementById("kt_aside"),
      ".aside-secondary .aside-workspace"
    );
    if (asideWorkspace) {
      KTUtil.scrollUpdate(asideWorkspace);
    }
  };
  if (organization !== undefined) {
    return (
      <>
        {/* begin::Aside */}
        <div
          id="kt_aside"
          className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}
        >
          {/* begin::Primary */}
          <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
            <Brand />
            {/* begin::Nav Wrapper */}
            <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
              {/* begin::Nav */}
              <ul className="list-unstyled flex-column" role="tablist">
                {/* begin::Item */}
                {organization.memberType !== "Paziente" &&
                organization.memberType !== "Ospite" ? (
                  <li
                    className={`menu-item py-2 ${getMenuItemActive(
                      "/dashboard",
                      true
                    )}`}
                    data-toggle="tooltip"
                    data-placement="rigth"
                    data-container="body"
                    data-boundary="window"
                    title="Dashboard"
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="metronic-features">Dashboard</Tooltip>
                      }
                    >
                      <NavLink
                        to={
                          window.location.href.indexOf("organizzazione") > -1
                            ? `/organizzazione/dashboard/`
                            : "/dashboard"
                        }
                        className={`nav-link btn btn-icon btn-clean btn-lg`}
                        data-toggle="tab"
                        role="tab"
                      >
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Shopping/Chart-bar1.svg"
                            )}
                          />
                        </span>
                      </NavLink>
                    </OverlayTrigger>
                  </li>
                ) : null}
                {/* end::Item */}

                {/* begin::Item */}
                {!url &&
                organization.memberType !== "Paziente" &&
                organization.memberType !== "Ospite" ? (
                  <li
                    className={`menu-item py-2 ${getMenuItemActive(
                      "/calendario",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-toggle="tooltip"
                    data-placement="rigth"
                    data-container="body"
                    data-boundary="window"
                    title="Calendario"
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="latest-project">Agenda</Tooltip>}
                    >
                      <NavLink
                        to={active ? "/calendario" : ""}
                        className={`nav-link btn btn-icon 
                          btn-clean btn-lg`}
                        data-toggle="tab"
                        role="tab"
                      >
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Layout/Layout-top-panel-1.svg"
                            )}
                          />
                        </span>
                      </NavLink>
                    </OverlayTrigger>
                  </li>
                ) : organization.memberType !== "Paziente" &&
                  organization.memberType !== "Ospite" ? (
                  <li
                    className={`menu-item py-2 ${getMenuItemActive(
                      "/organizzazione/calendario",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-toggle="tooltip"
                    data-placement="rigth"
                    data-container="body"
                    data-boundary="window"
                    title="Calendario"
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="latest-project">Agenda</Tooltip>}
                    >
                      <NavLink
                        to={active ? `/organizzazione/calendario/` : ""}
                        className={`nav-link btn btn-icon 
                          btn-clean btn-lg`}
                        data-toggle="tab"
                        role="tab"
                      >
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Layout/Layout-top-panel-1.svg"
                            )}
                          />
                        </span>
                      </NavLink>
                    </OverlayTrigger>
                  </li>
                ) : null}
                {/* end::Item */}

                {/* begin::Item */}
                {!url ? (
                  <li
                    className={`menu-item py-2 ${getMenuItemActive(
                      "prenotazioni",
                      true
                    )}`}
                    data-toggle="tooltip"
                    data-placement="rigth"
                    data-container="body"
                    data-boundary="window"
                    title="Prenotazioni"
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="metronic-features">
                          Prenota una visita
                        </Tooltip>
                      }
                    >
                      <NavLink
                        to={active ? "/prenotazioni/" : ""}
                        className={`nav-link btn btn-icon 
                          btn-clean btn-lg`}
                        data-toggle="tab"
                        role="tab"
                      >
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Clipboard.svg"
                            )}
                          />
                        </span>
                      </NavLink>
                    </OverlayTrigger>
                  </li>
                ) : (
                  <li
                    className={`menu-item py-2 ${getMenuItemActive(
                      "/organizzazione/prenotazioni/",
                      true
                    )}`}
                    data-toggle="tooltip"
                    data-placement="rigth"
                    data-container="body"
                    data-boundary="window"
                    title="Prenotazioni"
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="metronic-features">
                          Prenota una visita
                        </Tooltip>
                      }
                    >
                      <NavLink
                        to={active ? "/organizzazione/prenotazioni/" : ""}
                        className={`nav-link btn btn-icon 
                          btn-clean btn-lg`}
                        data-toggle="tab"
                        role="tab"
                      >
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Clipboard.svg"
                            )}
                          />
                        </span>
                      </NavLink>
                    </OverlayTrigger>
                  </li>
                )}
                {/* end::Item */}

                {/* begin::Item */}
                {parseInt(user.idRole) === roles.doctor &&
                window.location.href.indexOf("organizzazione") === -1 ? (
                  <li
                    className={`menu-item py-2 ${getMenuItemActive(
                      "/create-organization",
                      false
                    )}`}
                    data-toggle="tooltip"
                    data-placement="rigth"
                    data-container="body"
                    data-boundary="window"
                    title="Crea organizzazione"
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-reports">
                          Crea organizzazione
                        </Tooltip>
                      }
                    >
                      <NavLink
                        to={active ? "/create-organization" : ""}
                        className={`nav-link btn btn-icon 
                          btn-clean btn-lg`}
                        data-toggle="tab"
                        data-target="#kt_aside_tab_3"
                        role="tab"
                      >
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Tools/Compass.svg"
                            )}
                          />
                        </span>
                      </NavLink>
                    </OverlayTrigger>
                  </li>
                ) : null}
                {/* end::Item */}

                {/* begin::Item */}
                {!url ? (
                  <li
                    className={`menu-item py-2 ${getMenuItemActive(
                      "/documenti",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-toggle="tooltip"
                    data-placement="rigth"
                    data-container="body"
                    data-boundary="window"
                    title="Documenti"
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="latest-project">Documenti</Tooltip>}
                    >
                      <NavLink
                        to={active ? "/documenti" : ""}
                        className={`nav-link btn btn-icon 
                          btn-clean btn-lg`}
                        data-toggle="tab"
                        role="tab"
                        onClick={() => handleTabChange(tabs.tabId6)}
                      >
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Files/Folder.svg"
                            )}
                          />
                        </span>
                      </NavLink>
                    </OverlayTrigger>
                  </li>
                ) : null}
                {/* end::Item */}

                {/* begin::Item */}
                {url &&
                parseInt(user.idRole) === roles.doctor &&
                organization.memberType !== "Guest" ? (
                  <li
                    className={`menu-item py-2 ${getMenuItemActive(
                      "/organizzazione/membri/:id/:nameOrganization",
                      true
                    )}`}
                    data-toggle="tooltip"
                    data-placement="rigth"
                    data-container="body"
                    data-boundary="window"
                    title="Membri"
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="metronic-features">Membri</Tooltip>}
                    >
                      <NavLink
                        to={active ? `/organizzazione/membri/` : ""}
                        className={`nav-link btn btn-icon 
                          btn-clean btn-lg`}
                        data-toggle="tab"
                        role="tab"
                      >
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Communication/Address-card.svg"
                            )}
                          />
                        </span>
                      </NavLink>
                    </OverlayTrigger>
                  </li>
                ) : null}
                {/* end::Item */}

                {/* begin::Item */}
                {url &&
                parseInt(user.idRole) === roles.doctor &&
                organization.memberType !== "Ospite" ? (
                  <li
                    className="nav-item mb-3"
                    data-toggle="tooltip"
                    data-placement="rigth"
                    data-container="body"
                    data-boundary="window"
                    title="Pannello controllo"
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="pannello-controllo">
                          Pannello di controllo
                        </Tooltip>
                      }
                    >
                      <a
                        className={`nav-link btn btn-icon 
                        btn-clean btn-lg`}
                        data-toggle="tab"
                        data-target={`#${tabs.tabId2}`}
                        onClick={() => {
                          handleTabChange(tabs.tabId2);
                          history.push(
                            `/organizzazione/pannello-controllo/`,

                            { from: "Dashboard" }
                          );
                        }}
                        role="tab"
                      >
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/General/Settings-1.svg"
                            )}
                          />
                        </span>
                      </a>
                    </OverlayTrigger>
                  </li>
                ) : null}

                {/* end::Item */}

                {/* begin::Item */}
                {url ? (
                  <li
                    className={`menu-item py-2 ${getMenuItemActive(
                      "/organizzazione/info/:id/:nameOrganization",
                      true
                    )}`}
                    data-toggle="tooltip"
                    data-placement="rigth"
                    data-container="body"
                    data-boundary="window"
                    title="Info azienda"
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="metronic-features">Info azienda</Tooltip>
                      }
                    >
                      <NavLink
                        to={active ? `/organizzazione/info/` : ""}
                        className={`nav-link btn btn-icon 
                          btn-clean btn-lg`}
                        data-toggle="tab"
                        role="tab"
                      >
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Code/info-circle.svg"
                            )}
                          />
                        </span>
                      </NavLink>
                    </OverlayTrigger>
                  </li>
                ) : null}
                {/* end::Item */}
              </ul>
              {/* end::Nav */}
            </div>
            {/* end::Nav Wrapper */}

            {/* begin::Footer */}
            <div className="pb-5">
              {/* begin::Aside Toggle */}

              {/* <>
                {url ? (
                  <OverlayTrigger
                    placement="right"
                    overlay={<Tooltip id="toggle-aside">Apri pannello</Tooltip>}
                  >
                    <span
                      className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                      id="kt_aside_toggle"
                    >
                      <i className="fas fa-chevron-left"></i>
                    </span>
                  </OverlayTrigger>
                ) : null}
              </> */}
              {/* end::Aside Toggle */}

              {/* begin::Notifications */}
              {layoutProps.extrasNotificationsDisplay && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="toggle-notifications">Notifiche</Tooltip>
                  }
                >
                  <a
                    href="#"
                    className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                    id="kt_quick_notifications_toggle"
                    data-placement="right"
                    data-container="body"
                    data-boundary="window"
                  >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Design/Layers.svg"
                        )}
                      />
                    </span>
                  </a>
                </OverlayTrigger>
              )}
              {/* end::Notifications */}

              {/* begin::Languages*/}
              {/*layoutProps.extrasLanguagesDisplay && <LanguageSelectorDropdown />*/}
              {/* end::Languages */}

              {/* begin::User*/}
              {layoutProps.extrasUserDisplay && <QuickUserToggler />}
              {/* end::User */}
            </div>
            {/* end::Footer */}
          </div>
          {/* end::Primary */}

          {layoutProps.asideSecondaryDisplay &&
          parseInt(user.idRole) === roles.doctor &&
          organization.memberType !== "Ospite" ? (
            <>
              {/* begin::Secondary */}
              <div className="aside-secondary d-flex flex-row-fluid">
                {/* begin::Workspace */}
                <div className="aside-workspace scroll scroll-push my-2">
                  <div className="tab-content">
                    <h4 className="text-center mr-5 mt-4">
                      Pannello di controllo <br />
                      Azienda: {organization.name}
                    </h4>
                    <AsideMenu isActive={activeTab === tabs.tabId2} />
                  </div>
                </div>
                {/* end::Workspace */}
              </div>
              {/* end::Secondary */}
            </>
          ) : null}
        </div>
        {/* end::Aside */}
      </>
    );
  } else {
    return (
      <>
        <div
          id="kt_aside"
          className={`aside aside-left d-flex ${layoutProps.asideClassesFromConfig}`}
        >
          {/* begin::Primary */}
          <div className="aside-primary d-flex flex-column align-items-center flex-row-auto">
            <Brand />
            {/* begin::Nav Wrapper */}
            <div className="aside-nav d-flex flex-column align-items-center flex-column-fluid py-5 scroll scroll-pull">
              {/* begin::Nav */}
              <ul className="list-unstyled flex-column" role="tablist">
                {/* begin::Item */}

                <li
                  className={`menu-item py-2 ${getMenuItemActive(
                    "/dashboard",
                    true
                  )}`}
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title="Dashboard"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="metronic-features">Dashboard</Tooltip>
                    }
                  >
                    <NavLink
                      to="/dashboard"
                      className={`nav-link btn btn-icon btn-clean btn-lg`}
                      data-toggle="tab"
                      role="tab"
                    >
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/Shopping/Chart-bar1.svg"
                          )}
                        />
                      </span>
                    </NavLink>
                  </OverlayTrigger>
                </li>

                {/* end::Item */}

                {/* begin::Item */}
                {!url ? (
                  <li
                    className={`menu-item py-2 ${getMenuItemActive(
                      "/calendario",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-toggle="tooltip"
                    data-placement="rigth"
                    data-container="body"
                    data-boundary="window"
                    title="Calendario"
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="latest-project">Agenda</Tooltip>}
                    >
                      <NavLink
                        to={active ? "/calendario" : ""}
                        className={`nav-link btn btn-icon ${
                          active === 1 ? "btn-clean" : "inactive"
                        } btn-lg`}
                        data-toggle="tab"
                        role="tab"
                      >
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Layout/Layout-top-panel-1.svg"
                            )}
                          />
                        </span>
                      </NavLink>
                    </OverlayTrigger>
                  </li>
                ) : null}
                {/* end::Item */}

                {/* begin::Item */}
                <li
                  className={`menu-item py-2 ${getMenuItemActive(
                    "/prenotazioni",
                    true
                  )}`}
                  data-toggle="tooltip"
                  data-placement="rigth"
                  data-container="body"
                  data-boundary="window"
                  title="Prenotazioni"
                >
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="metronic-features">
                        Prenota una visita
                      </Tooltip>
                    }
                  >
                    <NavLink
                      to={active ? "/prenotazioni" : ""}
                      className={`nav-link btn btn-icon ${
                        active === 1 ? "btn-clean" : "inactive"
                      } btn-lg`}
                      data-toggle="tab"
                      role="tab"
                    >
                      <span className="svg-icon svg-icon-lg">
                        <SVG
                          src={toAbsoluteUrl(
                            "/media/svg/icons/General/Clipboard.svg"
                          )}
                        />
                      </span>
                    </NavLink>
                  </OverlayTrigger>
                </li>
                {/* end::Item */}

                {/* begin::Item */}
                {parseInt(user.idRole) === roles.doctor &&
                window.location.href.indexOf("organizzazione") === -1 ? (
                  <li
                    className={`menu-item py-2 ${getMenuItemActive(
                      "/create-organization",
                      false
                    )}`}
                    data-toggle="tooltip"
                    data-placement="rigth"
                    data-container="body"
                    data-boundary="window"
                    title="Crea organizzazione"
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-reports">
                          Crea organizzazione
                        </Tooltip>
                      }
                    >
                      <NavLink
                        to={active ? "/create-organization" : ""}
                        className={`nav-link btn btn-icon ${
                          active === 1 ? "btn-clean" : "inactive"
                        } btn-lg`}
                        data-toggle="tab"
                        data-target="#kt_aside_tab_3"
                        role="tab"
                      >
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Tools/Compass.svg"
                            )}
                          />
                        </span>
                      </NavLink>
                    </OverlayTrigger>
                  </li>
                ) : null}
                {/* end::Item */}

                {/* begin::Item */}
                {!url ? (
                  <li
                    className={`menu-item py-2 ${getMenuItemActive(
                      "/documenti",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-toggle="tooltip"
                    data-placement="rigth"
                    data-container="body"
                    data-boundary="window"
                    title="Documenti"
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={<Tooltip id="latest-project">Documenti</Tooltip>}
                    >
                      <NavLink
                        to={active ? "/documenti" : ""}
                        className={`nav-link btn btn-icon ${
                          active === 1 ? "btn-clean" : "inactive"
                        } btn-lg`}
                        data-toggle="tab"
                        role="tab"
                        onClick={() => handleTabChange(tabs.tabId6)}
                      >
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Files/Folder.svg"
                            )}
                          />
                        </span>
                      </NavLink>
                    </OverlayTrigger>
                  </li>
                ) : null}
                {/* end::Item */}

                {/* begin::Item */}
                {!url ? (
                  <li
                    className={`menu-item py-2 ${getMenuItemActive(
                      "/cartella-digitale",
                      true
                    )}`}
                    aria-haspopup="true"
                    data-toggle="tooltip"
                    data-placement="rigth"
                    data-container="body"
                    data-boundary="window"
                    title="Cartella digitale"
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="latest-project">Cartella digitale</Tooltip>
                      }
                    >
                      <NavLink
                        to={active ? "/cartella-digitale" : ""}
                        className={`nav-link btn btn-icon ${
                          active === 1 ? "btn-clean" : "inactive"
                        } btn-lg`}
                        data-toggle="tab"
                        role="tab"
                      >
                        <span
                          className="svg-icon svg-icon-lg"
                          style={{ position: "relative" }}
                        >
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Home/Library.svg"
                            )}
                          />
                          <span
                            style={{ position: "absolute", fontSize: "9px" }}
                            className={`label  ${
                              richieste.pending && richieste.pending.length > 0
                                ? "pulse pulse-primary"
                                : null
                            }`}
                          >
                            <span className="position-relative">
                              {richieste.pending ? richieste.pending.length : 0}
                            </span>
                            {richieste.pending &&
                            richieste.pending.length > 0 ? (
                              <span className="pulse-ring"></span>
                            ) : null}
                          </span>
                        </span>
                      </NavLink>
                    </OverlayTrigger>
                  </li>
                ) : null}
                {/* end::Item */}

                {/* begin::Item */}
                {parseInt(user.idRole) === roles.doctor &&
                organization === undefined ? (
                  <li
                    className={`menu-item py-2 ${getMenuItemActive(
                      "/richiesta-cartella-digitale",
                      true
                    )}`}
                    data-toggle="tooltip"
                    data-placement="rigth"
                    data-container="body"
                    data-boundary="window"
                    title="Richiesta cartella digitale"
                  >
                    <OverlayTrigger
                      placement="right"
                      overlay={
                        <Tooltip id="metronic-features">
                          Richiesta cartella digitale
                        </Tooltip>
                      }
                    >
                      <NavLink
                        to={active ? "/richiesta-cartella-digitale" : ""}
                        className={`nav-link btn btn-icon btn-clean btn-lg`}
                        data-toggle="tab"
                        role="tab"
                      >
                        <span className="svg-icon svg-icon-lg">
                          <SVG
                            src={toAbsoluteUrl(
                              "/media/svg/icons/Design/Difference.svg"
                            )}
                          />
                        </span>
                      </NavLink>
                    </OverlayTrigger>
                  </li>
                ) : null}
                {/* end::Item */}

                {/* begin::Item */}

                {/* end::Item */}
              </ul>
              {/* end::Nav */}
            </div>
            {/* end::Nav Wrapper */}

            {/* begin::Footer */}
            <div className="pb-5">
              {/* begin::Aside Toggle */}
              {/* {layoutProps.asideSecondaryDisplay &&
                layoutProps.asideSelfMinimizeToggle && (
                  <>
                    {url ? (
                      <OverlayTrigger
                        placement="right"
                        overlay={
                          <Tooltip id="toggle-aside">Apri pannello</Tooltip>
                        }
                      >
                        <span
                          className="aside-toggle btn btn-icon btn-primary btn-hover-primary shadow-sm"
                          id="kt_aside_toggle"
                        >
                          <i className="fas fa-chevron-left"></i>
                        </span>
                      </OverlayTrigger>
                    ) : null}
                  </>
                )} */}
              {/* end::Aside Toggle */}

              {/* begin::Search */}
              {layoutProps.extrasSearchDisplay && (
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="toggle-search">Ricerca</Tooltip>}
                >
                  <a
                    href="#"
                    className="btn btn-icon btn-clean btn-lg mb-1"
                    id="kt_quick_search_toggle"
                  >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Search.svg"
                        )}
                      />
                    </span>
                  </a>
                </OverlayTrigger>
              )}
              {/* end::Search */}

              {/* begin::Notifications */}
              {/* {layoutProps.extrasNotificationsDisplay && (
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip id="toggle-notifications">Notifiche</Tooltip>
                  }
                >
                  <a
                    href="#"
                    className="btn btn-icon btn-clean btn-lg mb-1 position-relative"
                    id="kt_quick_notifications_toggle"
                    data-placement="right"
                    data-container="body"
                    data-boundary="window"
                  >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Design/Layers.svg"
                        )}
                      />
                    </span>
                  </a>
                </OverlayTrigger>
              )} */}
              {/* end::Notifications */}

              {/* begin::Languages*/}
              {/*layoutProps.extrasLanguagesDisplay && <LanguageSelectorDropdown />*/}
              {/* end::Languages */}
              {/* begin::Search */}
              {/* {layoutProps.extrasSearchDisplay && (
                <OverlayTrigger
                  placement="right"
                  overlay={<Tooltip id="toggle-search">Ricerca</Tooltip>}
                >
                  <a
                    href="#"
                    className="btn btn-icon btn-clean btn-lg mb-1"
                    id="kt_quick_search_toggle"
                  >
                    <span className="svg-icon svg-icon-lg">
                      <SVG
                        src={toAbsoluteUrl(
                          "/media/svg/icons/General/Search.svg"
                        )}
                      />
                    </span>
                  </a>
                </OverlayTrigger>
              )} */}
              {/* end::Search */}

              {/* begin::User*/}
              {layoutProps.extrasUserDisplay && <QuickUserToggler />}
              {/* end::User */}
            </div>
            {/* end::Footer */}
          </div>
          {/* end::Primary */}

          {layoutProps.asideSecondaryDisplay && (
            <>
              {/* begin::Secondary */}
              <div className="aside-secondary d-flex flex-row-fluid">
                {/* begin::Workspace */}

                {/* end::Workspace */}
              </div>
              {/* end::Secondary */}
            </>
          )}
        </div>
      </>
    );
  }
}
