import React, { useState, useEffect } from "react";
import { Modal, Form } from "react-bootstrap";
import { useFormik } from "formik";
import { useSelector, shallowEqual } from "react-redux";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../controls";
import Skeleton from "@material-ui/lab/Skeleton";
import config from "../../../../../config/config";

export const EditModal = (props) => {
  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );
  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [sites, setSites] = useState([]);
  const [availability, setAvailability] = useState({
    id: 0,
    startTime: "",
    endTime: "",
    date: "2020-12-10",
    idService: 0,
    idSede: 0,
  });

  const [selectedSite, setSelectedSite] = useState(availability.idSede);
  const [selectedService, setSelectedService] = useState(
    availability.idService
  );

  const [checkService, setCheckService] = useState(false);
  const [checkDate, setCheckDate] = useState(false);
  const [checkStartTime, setCheckStartTime] = useState(false);
  const [checkEndTime, setCheckEndTime] = useState(false);

  const RequestOptions = {
    headers: {
      Authorization:
        "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
    },
    method: "POST",
    body: JSON.stringify({
      idDisponibilita: props.idAvailability,
      idOrganization: organization.id,
    }),
  };

  const formik = useFormik({
    initialValues: {
      startTime: availability.startTime,
      endTime: availability.endTime,
      date: availability.date,
    },
    enableReinitialize: true,
    onSubmit: (values) => {
      if (selectedService === 0) {
        setCheckService(true);
      }
      if (values.date === "" || new Date(values.date) < new Date()) {
        setCheckDate(true);
      }
      if (values.startTime === "") {
        setCheckStartTime(true);
      }
      if (values.endTime === "") {
        setCheckEndTime(true);
      }
      if (!checkService && !checkDate && !checkStartTime && !checkEndTime) {
        const requestOptions = {
          headers: {
            Authorization:
              "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
          },
          method: "POST",
          body: JSON.stringify({
            id: props.idAvailability,
            idService: selectedService,
            idSite: selectedSite,
            date: values.date,
            startTime: values.startTime,
            endTime: values.endTime,
          }),
        };
        fetch(
          config.apiUrl + "organization/EditAvailability.php",
          requestOptions
        )
          .then(() => props.closeEdit())
          .then(() => props.setCount(props.count + 1));
      }
    },
  });

  const GetSingleAvailability = () => {
    setLoading(true);
    fetch(
      config.apiUrl + "organization/GetSingleAvailability.php",
      RequestOptions
    )
      .then((response) => response.json())
      .then((data) => {
        setAvailability(data);
        setSelectedSite(data.idSede);
        setSelectedService(data.idService);
      })
      .then(() => GetSitesForService())
      .then(() => setLoading(false));
  };

  const GetMedicalServices = () => {
    setLoading(true);
    fetch(config.apiUrl + "organization/GetMedicalServices.php", RequestOptions)
      .then((response) => response.json())
      .then((data) => {
        setServices(data);
      })
      .then(() => setLoading(false));
  };

  const GetSitesForService = () => {
    const options = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        selectedService: selectedService,
      }),
    };
    fetch(config.apiUrl + "organization/GetSitesForService.php", options)
      .then((response) => response.json())
      .then((data) => setSites(data));
  };

  const ResetForm = () => {
    setSelectedService(availability.idService);
    setSelectedSite(availability.idSede);
    formik.handleReset();
  };

  useEffect(() => {
    GetMedicalServices();
    GetSingleAvailability();
    /*eslint-disable-next-line*/
  }, [props.show]);

  useEffect(() => {
    if (availability.idService !== 0) {
      GetSitesForService();
    }
    /*eslint-disable-next-line*/
  }, [selectedService]);

  return (
    <>
      <Modal show={props.show} size="lg">
        <Card>
          <CardHeader title="Modifica disponibilità">
            <CardHeaderToolbar>
              <button
                type="button"
                onClick={() => {
                  props.closeEdit();
                  setAvailability({
                    id: 0,

                    startTime: "",
                    endTime: "",
                    date: "2020-12-10",
                    idService: 0,
                    idSede: 0,
                  });
                }}
                className="btn btn-light"
              >
                <i className="fas fa-times"></i>
                Chiudi
              </button>
              <button onClick={ResetForm} className="btn btn-light ml-2">
                <i className="fa fa-redo"></i>
                Reset
              </button>
              {`  `}
              <button
                type="submit"
                className="btn btn-primary ml-2"
                onClick={formik.handleSubmit}
              >
                Salva modifiche
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            <Form className="row">
              <h5 className="font-weight-bold my-6 col-12">
                Informazioni di base
              </h5>
              <Form.Group className="col-6">
                {!loading ? (
                  <>
                    <Form.Label className="col-12">Tipo prestazione</Form.Label>
                    <select
                      value={selectedService}
                      name="idService"
                      onChange={(e) => setSelectedService(e.target.value)}
                      className={`form-control`}
                    >
                      <option value={0}>Seleziona prestazione</option>
                      {services.map((e, key) => (
                        <option key={key} value={e.actions.id}>
                          {e.name}
                        </option>
                      ))}
                    </select>
                    {checkService ? (
                      <p>Selezionare un tipo di prestazione</p>
                    ) : null}
                  </>
                ) : (
                  <Skeleton />
                )}
              </Form.Group>
              <Form.Group className="col-6">
                {!loading ? (
                  <>
                    <Form.Label className="col-12">Sede</Form.Label>
                    <select
                      className={`form-control`}
                      value={selectedSite}
                      onChange={(e) => setSelectedSite(e.target.value)}
                      name="idService"
                      disabled={parseInt(selectedService) === 0}
                    >
                      {parseInt(selectedService) === 0 ? (
                        <option value={0}>Seleziona prima un servizio</option>
                      ) : null}
                      {sites.length > 0 ? (
                        <option value={0}>Seleziona una sede</option>
                      ) : (
                        <option value={0}>Nessuna sede disponibile</option>
                      )}
                      {parseInt(selectedService) !== 0 && sites.length > 0
                        ? sites.map((e, key) => (
                            <option key={key} value={e.id}>
                              {e.name}
                            </option>
                          ))
                        : null}
                    </select>
                  </>
                ) : (
                  <Skeleton />
                )}
              </Form.Group>
              <h5 className="font-weight-bold my-6 col-12">Disponibilità</h5>
              <Form.Group className="col-4">
                {!loading ? (
                  <>
                    <Form.Label className="col-12">Data</Form.Label>
                    <Form.Control
                      type="date"
                      value={formik.values.date}
                      name="date"
                      onChange={formik.handleChange}
                    />
                    {checkDate ? <p>Inserire una data di inizio</p> : null}
                  </>
                ) : (
                  <Skeleton />
                )}
              </Form.Group>
              <Form.Group className="col-4">
                {!loading ? (
                  <>
                    <Form.Label className="col-12">Ora inizio</Form.Label>
                    <Form.Control
                      type="time"
                      value={formik.values.startTime}
                      name="startTime"
                      onChange={formik.handleChange}
                    />
                    {checkStartTime ? (
                      <p>Inserire un orario di inizio disponibilità</p>
                    ) : null}
                  </>
                ) : (
                  <Skeleton />
                )}
              </Form.Group>
              <Form.Group className="col-4">
                {!loading ? (
                  <>
                    <Form.Label className="col-12">Ora fine</Form.Label>
                    <Form.Control
                      type="time"
                      value={formik.values.endTime}
                      name="endTime"
                      onChange={formik.handleChange}
                    />
                    {checkEndTime ? (
                      <p>Inserire un orario di fine disponibilità</p>
                    ) : null}
                  </>
                ) : (
                  <Skeleton />
                )}
              </Form.Group>
            </Form>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};
