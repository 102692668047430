import React from "react";
import { useSubheader } from "../../_metronic/layout";
import { CreaOrganizzazione } from "../../_metronic/_partials/components/forms/CreaOrganizzazione";
import { Card } from "react-bootstrap";

export const CreateOrganization = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Crea nuova organizzazione");

  return (
    <Card>
      <CreaOrganizzazione />
    </Card>
  );
};
