import React, { useState, useEffect } from "react";
import { useSubheader } from "../../_metronic/layout";
import { Calendar } from "../../_metronic/_partials/components/calendar/Calendar";
import { TabellaPrenotazioni } from "../../_metronic/_partials/components/tabs/TabellaPrenotazioni";
import { StickyToolbar } from "../../_metronic/_partials/components/calendar/CalendarComponent/StickyToolbar";
import { Tab, Tabs, Modal, Button } from "react-bootstrap";
import { Card } from "react-bootstrap";
import { useSelector, shallowEqual } from "react-redux";
import config, { roles } from "../../config/config";
import { NewAppointment } from "../../_metronic/_partials/components/calendar/CalendarComponent/NewAppointment";

export const Calendario = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Agenda");

  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );

  const user = useSelector((state) => state.auth.user, shallowEqual);

  const returnToAgenda = () => {
    setEventKey("calendar");
    setPage("agenda");
  };

  const [tab, setTab] = useState([]);
  const [calendar, setCalendar] = useState([]);
  const [count, setCount] = useState(0);
  const [loading, setLoading] = useState([]);
  const [eventKey, setEventKey] = useState("calendar");
  const [page, setPage] = useState("agenda");
  const [videoconference, setVideoconference] = useState(0);
  const [endCreate, setEndCreate] = useState(false);

  const GetAppointments = () => {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: organization !== undefined ? organization.id : false,
        idUser: user.id,
      }),
    };
    fetch(config.apiUrl + "calendar/GetAppointments.php", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setTab(data.tab);
        setCalendar(data.calendar);
      })
      .then(() => setLoading(false));
  };

  useEffect(() => {
    GetAppointments();
    /*eslint-disable-next-line*/
  }, [count]);

  const ModalResult = () => (
    <Modal show={endCreate} onHide={() => setEndCreate(false)}>
      <Modal.Header className="border-0 py-4">
        <Modal.Title>
          <h3>Appuntamento creato</h3>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>
          L'appuntamento è stato creato con successo, è stata inviata una mail
          al paziente con le informazioni utili
        </p>
      </Modal.Body>
      <Modal.Footer className="border-0 py-4">
        <Button variant="secondary" onClick={() => setEndCreate(false)}>
          Chiudi
        </Button>
      </Modal.Footer>
    </Modal>
  );

  if (page === "agenda") {
    return (
      <>
        {eventKey === "calendar" && parseInt(user.idRole) === roles.doctor ? (
          <StickyToolbar
            videoconference={videoconference}
            setVideoconference={setVideoconference}
            setPage={setPage}
          />
        ) : null}
        <ModalResult />
        <Tabs
          onSelect={(e) => {
            setEventKey(e);
            setCount(count + 1);
          }}
          defaultActiveKey="calendar"
          id="uncontrolled-tab-example"
        >
          <Tab eventKey="calendar" title="Calendario">
            <Card
              style={{ borderTop: "0px", borderRadius: "0px" }}
              className="p-4"
            >
              <Calendar
                calendar={calendar}
                count={count}
                setCount={setCount}
                loading={loading}
                idUser={user.id}
                user={user}
              />
            </Card>
          </Tab>
          <Tab eventKey="tab" title="Storico">
            <TabellaPrenotazioni
              videoconference={videoconference}
              setVideoconference={setVideoconference}
              setPage={setPage}
              tab={tab}
              count={count}
              setCount={setCount}
              loading={loading}
              user={user}
            />
          </Tab>
        </Tabs>
      </>
    );
  } else if (page === "newAppointment") {
    return (
      <NewAppointment
        idOrganization={organization === undefined ? false : organization.id}
        setEndCreate={setEndCreate}
        videoconference={videoconference}
        returnToAgenda={returnToAgenda}
      />
    );
  }
};
