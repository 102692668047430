import React from "react";
import {
  PDFViewer,
  Page,
  Text,
  Image,
  Document,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";
import { toAbsoluteUrl } from "../../../_helpers";
import { Card } from "react-bootstrap";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";

/* <PDFViewer height={"100%"} width={"100%"}>
  <PDFMaker />
</PDFViewer> */

export const RicevutaPrenotazione = ({
  prenotazioneNumero,
  prestazione,
  medico,
  paziente,
  ssn,
  tesseraSanitaria,
  data,
  orario,
}) => {
  Font.register({
    family: "Oswald",
    src: "https://fonts.gstatic.com/s/oswald/v13/Y_TKV6o8WovbUd3m_X9aAA.ttf",
  });

  const styles = StyleSheet.create({
    body: {
      paddingTop: 35,
      paddingBottom: 65,
      paddingHorizontal: 35,
    },
    title: {
      fontSize: 24,
      textAlign: "center",
      fontFamily: "Oswald",
    },
    author: {
      fontSize: 12,
      textAlign: "center",
      marginBottom: 40,
    },
    subtitle: {
      fontSize: 18,
      margin: 12,
      fontFamily: "Oswald",
      textAlign: "center",
    },
    text: {
      margin: 12,
      fontSize: 14,
      textAlign: "justify",
      fontFamily: "Times-Roman",
    },
    image: {
      marginVertical: 15,
      marginHorizontal: 100,
      marginBottom: 10,
    },
    qr: {
      marginLeft: 210,
      maxHeight: 110,
      maxWidth: 110,
      textAlign: "center",
    },
    header: {
      fontSize: 12,
      marginBottom: 20,
      textAlign: "center",
      color: "grey",
    },
    pageNumber: {
      position: "absolute",
      fontSize: 12,
      bottom: 30,
      left: 0,
      right: 0,
      textAlign: "center",
      color: "grey",
    },
  });

  return (
    <PDFViewer style={{ height: "500px" }}>
      <Document>
        <Page style={styles.body}>
          <Text style={styles.title}>Doctor Chain</Text>
          <Text style={styles.author}>
            Ricevuta prenotazione n. {prenotazioneNumero}
          </Text>
          <Image
            style={styles.image}
            src={toAbsoluteUrl("media/logos/LogoDrChain_Nero.png")}
          />
          <Text style={styles.subtitle}>
            Con la presente confermiamo di aver ricevuto la tua prenotazione.
          </Text>
          <Image
            style={styles.qr}
            src={toAbsoluteUrl("media/demos/fake-qr-code.png")}
          />
          <Text style={styles.subtitle}>
            Utilizza questo codice come ricevuta di prenotazione.
          </Text>
          <Text style={styles.text}>Tipo prestazione: {prestazione}</Text>
          <Text style={styles.text}>
            Medico: {medico} <br />
          </Text>
          <Text style={styles.text}>
            Paziente: {paziente} <br />
          </Text>
          <Text style={styles.text}>
            Codice fiscale paziente: {ssn} <br />
          </Text>
          <Text style={styles.text}>
            Tessera sanitaria paziente: {tesseraSanitaria} <br />
          </Text>
          <Text style={styles.text}>
            Data prenotazione: {data} <br />
          </Text>
          <Text style={styles.text}>
            Orario prenotazione: {orario} <br />
          </Text>
        </Page>
      </Document>
    </PDFViewer>
  );
};
