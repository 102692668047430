import { CreaPrestazione } from "./CreaPrestazione";
import { GestisciMedici } from "./GestisciMedici";
import { GestisciPrestazioni } from "./GestisciPrestazioni";
import { GestisciReparti } from "./GestisciReparti";
import { GestisciSedi } from "./GestisciSedi";
import { ImpostazioniOrganizzazione } from "./ImpostazioniOrganizzazione";
import { ImpostazioniPersonali } from "./ImpostazioniPersonali";
import { RichiesteMedici } from "./RichiesteMedici";
import { CreaReparto } from "./CreaReparto";
import { CreaSede } from "./CreaSede";
import { CreaDisponibilita } from "./CreaDisponibilita";
import { GestisciDisponibilita } from "./GestisciDisponibilita";

export const PannelloPages = {
  CreaPrestazione: CreaPrestazione,
  GestisciMedici: GestisciMedici,
  GestisciPrestazioni: GestisciPrestazioni,
  ImpostazioniOrganizzazione: ImpostazioniOrganizzazione,
  ImpostazioniPersonali: ImpostazioniPersonali,
  RichiesteMedici: RichiesteMedici,
  CreaSede: CreaSede,
  CreaReparto: CreaReparto,
  GestisciSedi: GestisciSedi,
  GestisciReparti: GestisciReparti,
  CreaDisponibilita: CreaDisponibilita,
  GestisciDisponibilita: GestisciDisponibilita,
};
