import React, { useEffect, useState } from "react";
import { ModalServiceDelete } from "../../../_metronic/_partials/components/modals/ModalServiceDelete";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  SearchPanel,
  TableRowDetail,
  TableColumnResizing,
} from "@devexpress/dx-react-grid-bootstrap4";
import { useSelector, shallowEqual } from "react-redux";
import {
  PagingState,
  IntegratedPaging,
  SearchState,
  IntegratedFiltering,
  RowDetailState,
} from "@devexpress/dx-react-grid";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Card, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { faPenToSquare, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import config from "../../../config/config";
import Skeleton from "@material-ui/lab/Skeleton";
import { EditService } from "../../../_metronic/_partials/components/forms/EditService";

export const GestisciPrestazioni = () => {
  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );

  const [columns] = useState([
    { name: "name", title: "Nome" },
    { name: "duration", title: "Durata" },
    { name: "videoconference", title: "Teleconsulto" },
    { name: "deletable", title: "Cancellabile" },
    { name: "expirationDate", title: "Entro il" },
  ]);
  const [service, setService] = useState({
    id: 0,
    name: "",
    department: "",
    sites: "",
    duration: "",
    description: "",
  });
  const [defaultColumnWidths] = useState([
    { columnName: "name", width: 240 },
    { columnName: "duration", width: 220 },
    { columnName: "videoconference", width: 220 },
    { columnName: "deletable", width: 220 },
    { columnName: "expirationDate", width: 200 },
  ]);
  const [rows, setRows] = useState([]);
  const [pageSizes] = useState([0, 10, 20, 50, 100]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchState] = useState("");
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState("summary");
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0);

  const handleClose = () => {
    setService({
      id: 0,
      name: "",
      description: "",
      dateCreated: "",
    });
    setShow(false);
  };

  const requestOptions = {
    headers: {
      Authorization:
        "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
    },
    method: "POST",
    body: JSON.stringify({
      idOrganization: organization.id,
    }),
  };

  const GetMedicalServices = () => {
    setLoading(true);
    fetch(config.apiUrl + "organization/GetMedicalServices.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setRows(data))
      .then(() => setLoading(false));
  };

  const makeActionBtn = ({
    name,
    disabled,
    tooltip,
    callback,
    variant,
    icon,
    value,
    nameService,
    setShow,
    idService,
    type,
    setEdit,
  }) => {
    const btn = (
      <Button
        variant={variant}
        className={`mx-3 my-2`}
        onClick={() => {
          if (type === "edit") {
            setPage("edit");
            setService(idService);
          } else {
            setShow(true);
            setService({ id: idService, name: nameService });
          }
        }}
        disabled={disabled}
        title={name}
      >
        {typeof icon == "string" ? (
          <span className={`svg-icon svg-icon-md svg-icon-white`}>
            {" "}
            <SVG src={toAbsoluteUrl(icon)} />
          </span>
        ) : (
          <FontAwesomeIcon
            icon={icon}
            className={`svg-icon-white`}
            style={{ marginRight: "0.5rem" }}
          />
        )}
        {/* <span className={`text-${variant}`}></span> */}
        {name}
      </Button>
    );
    if (!tooltip) return btn;
    return (
      <OverlayTrigger overlay={<Tooltip>{tooltip}</Tooltip>}>
        {btn}
      </OverlayTrigger>
    );
  };

  const RowDetail = ({ row }) => {
    const [result, setResult] = useState([]);
    const [infoLoading, setInfoLoading] = useState(false);

    const GetMedicalServiceInfo = () => {
      setInfoLoading(true);
      const requestOptions = {
        headers: {
          Authorization: config.keyApi,
        },
        method: "POST",
        body: JSON.stringify({
          idService: row.id,
        }),
      };
      fetch(config.apiUrl + "organization/GetServiceInfo.php", requestOptions)
        .then((response) => response.json())
        .then((data) => setResult(data.result))
        .then(() => setInfoLoading(false));
    };

    useEffect(() => {
      GetMedicalServiceInfo();
      /* eslint-disable-next-line */
    }, [row.id]);
    return (
      <>
        <div className="card">
          <>
            <div className="col-12">
              <div className="d-flex align-items-center justify-content-xl-between p-8">
                <div className="d-flex flex-column mr-2 order-2 order-xl-1 ml-2">
                  {/*eslint-disable-next-line*/}
                  {!infoLoading ? (
                    <span className="font-weight-bold text-dark-75 font-size-lg text-hover-primary">
                      {result.department !== ""
                        ? "Dipartimento:"
                        : "Nessun dipartimento"}
                    </span>
                  ) : (
                    <Skeleton />
                  )}
                  {result.department !== "" ? (
                    !infoLoading ? (
                      <div className="d-flex align-items-center mt-2">
                        <span className="font-weight-bold mr-1 text-dark-75 font-size-3">
                          {result.department}
                        </span>
                      </div>
                    ) : (
                      <Skeleton />
                    )
                  ) : null}
                </div>
              </div>
              <div className="separator separator-solid"></div>
              {!infoLoading && result["infos"] !== undefined ? (
                result["infos"].length > 0 ? (
                  result["infos"].map((e, key) => (
                    <>
                      <div className="p-8">
                        <div className="d-flex align-items-center justify-content-xl-between mb-4">
                          <span className="font-weight-bold text-muted font-size-sm mr-2">
                            Sede:
                          </span>
                          <span className="font-weight-bolder text-primary text-right">
                            {e.siteName}
                          </span>
                        </div>
                        <div className="d-flex align-items-center justify-content-xl-between mb-7">
                          <span className="font-weight-bold text-muted font-size-sm mr-2">
                            Regione
                          </span>
                          <span className="font-weight-bolder text-dark-50 text-right">
                            {e.region}
                          </span>
                        </div>
                        <div className="d-flex align-items-center justify-content-xl-between mb-7">
                          <span className="font-weight-bold text-muted font-size-sm mr-2">
                            Provincia
                          </span>
                          <span className="font-weight-bolder text-dark-50 text-right">
                            {e.province}
                          </span>
                        </div>
                        <div className="d-flex align-items-center justify-content-xl-between mb-7">
                          <span className="font-weight-bold text-muted font-size-sm mr-2">
                            Città
                          </span>
                          <span className="font-weight-bolder text-dark-50 text-right">
                            {e.city}
                          </span>
                        </div>
                      </div>
                      <div className="separator separator-solid"></div>
                    </>
                  ))
                ) : (
                  <>
                    <div className="p-8">
                      <div className="d-flex align-items-center justify-content-xl-between mb-4">
                        <span className="font-weight-bold text-muted font-size-sm mr-2">
                          Nessuna sede selezionata
                        </span>
                        <span className="font-weight-bolder text-primary text-right">
                          -
                        </span>
                      </div>
                    </div>
                  </>
                )
              ) : (
                <Skeleton />
              )}
            </div>
          </>
          <div className="separator separator-solid"></div>

          <div>
            <div className="float-xl-right">
              {makeActionBtn({
                name: "Modifica",
                idService: row.id,
                //callback: editCallback,
                variant: "primary",
                icon: faPenToSquare,
                value: row.idRichiesta,
                setEdit: setPage,
                type: "edit",
              })}
              {makeActionBtn({
                name: "Elimina",
                //callback: evasoCallback,
                type: "delete",
                nameService: row.name,
                idService: row.id,
                variant: "danger",
                setShow: setShow,
                icon: faTrashCan,
              })}
            </div>
          </div>
        </div>
      </>
    );
  };

  useEffect(() => {
    GetMedicalServices();
    /*eslint-disable-next-line*/
  }, [count, page]);

  const TableComponent = ({ ...restProps }) => (
    <Table.Table
      {...restProps}
      className="table table-hover table-head-custom table-vertical-center overflow-hidden"
    />
  );

  if (page === "summary") {
    return (
      <>
        <ModalServiceDelete
          show={show}
          handleClose={handleClose}
          id={service.id}
          name={service.name}
          count={count}
          setCount={setCount}
        />
        <Card className="tab-content table-responsive px-2 py-2">
          {!loading ? (
            <Grid rows={rows} columns={columns}>
              <RowDetailState />
              <SearchState value={searchValue} onValueChange={setSearchState} />
              <IntegratedFiltering />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
              <IntegratedPaging />
              <Table tableComponent={TableComponent} />
              <TableColumnResizing defaultColumnWidths={defaultColumnWidths} />
              <TableHeaderRow />
              <PagingPanel pageSizes={pageSizes} />
              <Toolbar />
              <SearchPanel />
              <TableRowDetail contentComponent={RowDetail} />
            </Grid>
          ) : (
            <>
              <Skeleton variant="text" />
              <Skeleton variant="circle" width={80} height={80} />
              <Skeleton variant="rect" height={300} />
            </>
          )}
        </Card>
      </>
    );
  } else if (page === "edit") {
    return (
      <>
        <EditService
          page={page}
          setPage={setPage}
          idService={service}
          idOrganization={organization.id}
        />
      </>
    );
  }
};
