import React from "react";
import { Link } from "react-router-dom";
import { toAbsoluteUrl } from "../../../_helpers";

export function Brand() {

  return (
    <>
      {/* begin::Brand */}
      <div
        className={`aside-brand d-flex flex-column align-items-center flex-column-auto py-5`}
      >
        {/* begin::Logo */}
        <Link
          to={
            window.location.href.indexOf("organizzazione") > -1
              ? `/organizzazione/dashboard/}`
              : "/dashboard"
          }
          className="brand-logo"
        >
          <img
          style={{paddingRight: "9.5px"}}
            alt="logo"
            src={toAbsoluteUrl("/media/logos/DrChainLogoSquare.png")}
            className="max-h-90px"
          />
        </Link>
        {/* end::Logo */}
      </div>
      {/* end::Brand */}
    </>
  );
}
