/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback, useRef } from "react";
import { useSelector, shallowEqual } from "react-redux";
import useWebSocket, { ReadyState } from "react-use-websocket";
import moment from "moment-timezone";
import { Card } from "react-bootstrap";
import { CardValore } from "../modules/cartellaDigitale/widgets/cardValore";
import { Riepilogo } from "../modules/cartellaDigitale/widgets/Riepilogo";

export const Test = (props) => {
  const [page, setPage] = useState("cards");

  if (page === "cards") {
    return (
      <Card style={{ borderTop: "0px", borderRadius: "0px" }} className="p-4">
        <Card.Body>
          <div className="row">
            <CardValore setPage={setPage} />
            <CardValore setPage={setPage} />
            <CardValore setPage={setPage} />
            <CardValore setPage={setPage} />
            <CardValore setPage={setPage} />
          </div>
        </Card.Body>
      </Card>
    );
  } else return <Riepilogo setPage={setPage} />;
};
