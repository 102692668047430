import config from "../config";

export async function GetCountries(accessToken) {
  const options = {
    headers: {
      Authorization: config.keyApi,
    },
    method: "POST",
    body: JSON.stringify({ accessToken: accessToken }),
  };
  const response = await fetch(
    config.apiUrl + "geolocal/NewGetCountries.php",
    options
  );
  return await response.json();
}

export async function GetRegions(accessToken) {
  const options = {
    headers: {
      Authorization: config.keyApi,
    },
    method: "POST",
    body: JSON.stringify({ accessToken: accessToken }),
  };
  const response = await fetch(
    config.apiUrl + "geolocal/NewGetRegions.php",
    options
  );
  return await response.json();
}

export async function GetProvinces(accessToken, region) {
  const options = {
    headers: {
      Authorization: config.keyApi,
    },
    method: "POST",
    body: JSON.stringify({
      accessToken: accessToken,
      region: region,
    }),
  };
  const response = await fetch(
    config.apiUrl + "geolocal/NewGetProvinces.php",
    options
  );
  return await response.json();
}

export async function GetCities(accessToken, province) {
  const options = {
    headers: {
      Authorization: config.keyApi,
    },
    method: "POST",
    body: JSON.stringify({
      accessToken: accessToken,
      province: province,
    }),
  };
  const response = await fetch(
    config.apiUrl + "geolocal/NewGetCities.php",
    options
  );
  return await response.json();
}
