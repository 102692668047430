import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import config from "../../../config/config";

export const GestisciMedici = () => {
  /*eslint-disable-next-line*/
  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );

  const [columns] = useState([
    { name: "firstname", title: "Nome" },
    { name: "lastname", title: "Cognome" },
    { name: "email", title: "Email" },
    { name: "actions", title: "Azioni" },
  ]);
  /*eslint-disable-next-line*/
  const [rows, setRows] = useState([]);
  const [pageSizes] = useState([0, 10, 20, 50, 100]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchState] = useState("");
  const [actionsColumn] = useState(["actions"]);

  const TableComponent = ({ ...restProps }) => (
    <Table.Table
      {...restProps}
      className="table table-hover table-head-custom table-vertical-center overflow-hidden"
    />
  );

  const editColum = ({ value }) => (
    <>
      <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">Modifica</Tooltip>}
      >
        {/*eslint-disable-next-line*/}
        <a className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3">
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>

      <> </>
      <OverlayTrigger
        overlay={<Tooltip id="products-delete-tooltip">Elimina</Tooltip>}
      >
        {/*eslint-disable-next-line*/}
        <a className="btn btn-icon btn-light btn-hover-danger btn-sm">
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </OverlayTrigger>
    </>
  );

  const ButtonColumn = (props) => (
    <DataTypeProvider formatterComponent={editColum} {...props} />
  );

  const GetMedics = () => {
    const requestOptions = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: organization.id,
      }),
    };
    fetch(config.apiUrl + "organization/GetMedics.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setRows(data));
  };

  useEffect(() => {
    GetMedics();
    /* eslint-disable-next-line */
  }, []);

  return (
    <Card>
      <div className="tab-content table-responsive px-2 py-2">
        <Grid rows={rows} columns={columns}>
          <ButtonColumn for={actionsColumn} />
          <SearchState value={searchValue} onValueChange={setSearchState} />
          <IntegratedFiltering />
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <IntegratedPaging />
          <Table tableComponent={TableComponent} />
          <TableHeaderRow />
          <PagingPanel pageSizes={pageSizes} />
          <Toolbar />
          <SearchPanel />
        </Grid>
      </div>
    </Card>
  );
};
