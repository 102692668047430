/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { Form, OverlayTrigger, Tooltip, Modal, Button } from "react-bootstrap";
import { useSelector, shallowEqual } from "react-redux";
import * as organizationApi from "../../../../../config/api/organizations";
import * as prestazioniApi from "../../../../../config/api/prestazioni";
import { InputFile } from "../../utils/inputFile";
import { useFormik } from "formik";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../controls/";
import * as yup from "yup";
import moment from "moment-timezone";
import * as api from "../../../../../config/api/appointments";
import { makeStyles } from "@material-ui/core/styles";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

export const NewAppointment = ({
  idOrganization,
  returnToAgenda,
  videoconference,
  setEndCreate,
}) => {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const classes = useStyles();

  const [loading, setLoading] = useState(false);
  const [organizations, setOrganizations] = useState([]);
  const [prestazioni, setPrestazioni] = useState([]);
  const [file, setFile] = useState(false);
  const [show, setShow] = useState(false);
  const [result, setResult] = useState([]);
  const [code, setCode] = useState(true);

  useEffect(() => {
    organizationApi
      .GetOrganizations(user.authToken)
      .then((response) =>
        response.json().then((data) => setOrganizations(data.result))
      );
    prestazioniApi
      .GetPrestazioni(user.authToken)
      .then((response) => response.json())
      .then((data) => setPrestazioni(data.result));
    /*eslint-disable-next-line*/
  }, []);

  const [initialValues] = useState({
    idOrganization: !idOrganization ? 0 : idOrganization,
    idDoc: user.id,
    idPrestazione: 0,
    email: "",
    firstname: "",
    lastname: "",
    gender: "",
    paziente: "",
    startTime: "",
    endTime: "",
    startDate: moment().format("YYYY-MM-DD"),
    note: "",
    videoconference: videoconference,
    result: code,
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const renderTooltip = (props) => {
    if (organizations.length === 0 && prestazioni.length > 0) {
      return (
        <Tooltip id="button-tooltip" {...props}>
          Bisogna far parte di un'azienda per creare un appuntamento
        </Tooltip>
      );
    } else if (organizations.length > 0 && prestazioni.length === 0) {
      return (
        <Tooltip id="button-tooltip" {...props}>
          Bisogna aver creato almeno una prestazione per creare un appuntamento
        </Tooltip>
      );
    } else if (organizations.length === 0 && prestazioni.length === 0) {
      return (
        <Tooltip id="button-tooltip" {...props}>
          Bisogna far parte di un'azienda e aver creato almeno una prestazione
          per creare un appuntamento
        </Tooltip>
      );
    }
  };

  const validationSchema = yup.object().shape({
    idOrganization: yup
      .number()
      .positive("Seleziona un'azienda per l'appuntamento")
      .required("Seleziona un'azienda per l'appuntamento"),
    idPrestazione: yup
      .number()
      .positive("Seleziona una prestazione")
      .required("Seleziona una prestazione"),
    paziente: yup
      .string("Inserire codice fiscale valido")
      .required("Inserire codice fiscale valido")
      .matches(
        /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/,
        "Il codice fiscale non è corretto"
      ),
    startTime: yup.string().required("Selezionare un orario"),
    endTime: yup.string().required("Selezionare un orario"),
    startDate: yup
      .date("Inserire una data valida")
      .required("Inserire la data dell'appuntamento"),
    note: yup.string().notRequired(),
    videoconference: yup.bool().required(),
    result: yup.bool(),
    email: yup
      .string()
      .email("Inserire un'email valida")
      .when("result", {
        is: false,
        then: yup.string().required("Inserire email paziente"),
      }),
    firstname: yup.string().when("result", {
      is: false,
      then: yup.string().required("Inserire nome del paziente"),
    }),
    lastname: yup.string().when("result", {
      is: false,
      then: yup.string().required("Inserire cognome del paziente"),
    }),
    gender: yup.string().when("result", {
      is: false,
      then: yup.string().required("Selezionare genere"),
    }),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      setLoading(true);
      let formData = new FormData();
      /*eslint-disable-next-line*/
      Object.entries(values).map(([key, value]) => {
        if (key !== "result") {
          formData.append(key, value);
        } else {
          formData.append(key, value ? 1 : 0);
        }
      });
      formData.append("accessToken", user.authToken);
      if (!file) {
        formData.append("isFile", false);
      } else {
        formData.append("isFile", true);
        formData.append("file", file);
      }
      api
        .CreateAppointment(formData)
        .then((result) => {
          setResult(result);
          if (parseInt(result.code) === 206) {
            formik.values.result = false;
            setCode(false);
          } else if (parseInt(result.code) === 200) {
            setEndCreate(true);
            returnToAgenda();
          }
        })
        .then(() => setLoading(false));
    },
  });

  const fields = [
    {
      value: "paziente",
      label: "Codice fiscale paziente",
      placeholder: "Codice fiscale...",
      type: "text",
    },
    {
      value: "startDate",
      label: "Data appuntamento",
      placeholder: "Codice fiscale...",
      type: "date",
    },
    {
      value: "startTime",
      label: "Ora inizio",
      placeholder: "Ora inizio...",
      type: "time",
    },
    {
      value: "endTime",
      label: "Ora fine",
      placeholder: "Ora fine...",
      type: "time",
    },
    {
      value: "note",
      label: "Eventuali note",
      placeholder: "Note...",
      type: "textarea",
    },
  ];

  const notFoundFields = [
    {
      value: "email",
      label: "Email paziente",
      placeholder: "Email...",
      type: "text",
    },
    {
      value: "firstname",
      label: "Nome paziente",
      placeholder: "Nome...",
      type: "text",
    },
    {
      value: "lastname",
      label: "Cognome paziente",
      placeholder: "Cognome...",
      type: "text",
    },
  ];

  return (
    <>
      <Card>
        <CardHeader
          title={
            videoconference === 0 ? "Nuovo appuntamento" : "Nuovo teleconsulto"
          }
        >
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={returnToAgenda}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              Agenda
            </button>
            <button onClick={formik.resetForm} className="btn btn-light ml-2">
              <i className="fa fa-redo"></i>
              Reset
            </button>
            {`  `}
            {organizations.length === 0 || prestazioni.length === 0 ? (
              <OverlayTrigger
                placement="top"
                trigger={["hover", "focus", "click"]}
                overlay={renderTooltip}
              >
                <button disabled type="submit" className="btn btn-primary ml-2">
                  Crea appuntamento
                </button>
              </OverlayTrigger>
            ) : !loading ? (
              <button
                onClick={formik.handleSubmit}
                type="submit"
                className="btn btn-primary ml-2"
              >
                Crea appuntamento
              </button>
            ) : (
              <div className={classes.root}>
                <CircularProgress />
              </div>
            )}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {!loading ? (
            <Form>
              <Form.Row>
                <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
                  <Form.Label>Azienda</Form.Label>
                  <select
                    name="idOrganization"
                    className={`form-control ${getInputClasses(
                      "idOrganization"
                    )}`}
                    {...formik.getFieldProps("idOrganization")}
                  >
                    <option value={0}>Seleziona azienda</option>
                    {organizations.map((e, key) => (
                      <option key={key} value={e.value}>
                        {e.label}
                      </option>
                    ))}
                  </select>
                  {formik.touched.idOrganization &&
                  formik.errors.idOrganization ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.idOrganization}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
                <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
                  <Form.Label>Prestazione</Form.Label>
                  <select
                    name="idPrestazione"
                    className={`form-control ${getInputClasses(
                      "idPrestazione"
                    )}`}
                    {...formik.getFieldProps("idPrestazione")}
                  >
                    <option value={0}>Seleziona prestazione</option>
                    {prestazioni.map((e, key) => (
                      <option key={key} value={e.value}>
                        {e.label}
                      </option>
                    ))}
                  </select>
                  {formik.touched.idPrestazione &&
                  formik.errors.idPrestazione ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.idPrestazione}
                      </div>
                    </div>
                  ) : null}
                </Form.Group>
                {fields.map((e, key) =>
                  e.type !== "textarea" ? (
                    <Form.Group
                      key={"group-" + key}
                      className="col-xl-6 col-lg-6 col-sm-12"
                    >
                      <Form.Label key={"label-" + key}>{e.label}</Form.Label>
                      <Form.Control
                        key={"control-" + key}
                        type={e.type}
                        name={e.value}
                        className={getInputClasses(e.value)}
                        placeholder={e.placeholder}
                        {...formik.getFieldProps(e.value)}
                        required={
                          e.type === "time" || e.type === "date" ? true : false
                        }
                      />
                      {formik.touched[e.value] && formik.errors[e.value] ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors[e.value]}
                          </div>
                        </div>
                      ) : null}
                    </Form.Group>
                  ) : (
                    <Form.Group key={"group-" + key} className="col-12">
                      <Form.Label key={"label-" + key}>{e.label}</Form.Label>
                      <Form.Control
                        key={"control-" + key}
                        as={e.type}
                        rows={3}
                        style={{ resize: "none" }}
                        name={e.value}
                        className={getInputClasses(e.value)}
                        placeholder={e.placeholder}
                        {...formik.getFieldProps(e.value)}
                      />
                      {formik.touched[e.value] && formik.errors[e.value] ? (
                        <div className="fv-plugins-message-container">
                          <div className="fv-help-block">
                            {formik.errors[e.value]}
                          </div>
                        </div>
                      ) : null}
                    </Form.Group>
                  )
                )}
                {!code ? (
                  <>
                    <Form.Label className="col-12">
                      <h3>Paziente non trovato</h3>
                    </Form.Label>
                    {notFoundFields.map((e, key) =>
                      e.type !== "textarea" ? (
                        <Form.Group
                          key={"group-notfound-" + key}
                          className="col-xl-6 col-lg-6 col-sm-12"
                        >
                          <Form.Label key={"label-notfound-" + key}>
                            {e.label}
                          </Form.Label>
                          <Form.Control
                            key={"control-notfound-" + key}
                            type={e.type}
                            name={e.value}
                            className={getInputClasses(e.value)}
                            placeholder={e.placeholder}
                            {...formik.getFieldProps(e.value)}
                            required={
                              e.type === "time" || e.type === "date"
                                ? true
                                : false
                            }
                          />
                          {formik.touched[e.value] && formik.errors[e.value] ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors[e.value]}
                              </div>
                            </div>
                          ) : null}
                        </Form.Group>
                      ) : (
                        <Form.Group
                          key={"group-notfound-" + key}
                          className="col-12"
                        >
                          <Form.Label key={"label-notfound-" + key}>
                            {e.label}
                          </Form.Label>
                          <Form.Control
                            key={"control-notfound-" + key}
                            as={e.type}
                            rows={3}
                            style={{ resize: "none" }}
                            name={e.value}
                            className={getInputClasses(e.value)}
                            placeholder={e.placeholder}
                            {...formik.getFieldProps(e.value)}
                          />
                          {formik.touched[e.value] && formik.errors[e.value] ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors[e.value]}
                              </div>
                            </div>
                          ) : null}
                        </Form.Group>
                      )
                    )}
                    <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
                      <Form.Label className="col-12">
                        Seleziona genere
                      </Form.Label>
                      <select
                        name="gender"
                        className={`form-control ${getInputClasses("gender")}`}
                        {...formik.getFieldProps("gender")}
                      >
                        <option value="">Seleziona genere</option>
                        <option value="Donna">Donna</option>
                        <option value="Uomo">Uomo</option>
                        <option value="Altro">
                          Preferisco non specificare
                        </option>
                      </select>
                    </Form.Group>
                    {formik.touched.gender && formik.errors.gender ? (
                      <div className="fv-plugins-message-container">
                        <div className="fv-help-block">
                          {formik.errors.gender}
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : null}
                <Form.Label className="col-12">
                  <h3>Allegati</h3>
                </Form.Label>
                <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
                  <Form.Label className="col-12">Ricetta medica</Form.Label>
                  <InputFile
                    name="ricetta"
                    id="ricetta"
                    setFile={setFile}
                    file={file}
                  />
                </Form.Group>
              </Form.Row>
            </Form>
          ) : null}
        </CardBody>
      </Card>
    </>
  );
};
