import arrConfig from "../config";

export const FirstStep = async (payload) => {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify(payload),
  };
  const response = await fetch(
    arrConfig.apiUrl + "appointments/FirstStep.php",
    options
  );
  return await response.json();
};
