/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import SVG from "react-inlinesvg";
import { toAbsoluteUrl, checkIsActive } from "../../../../_helpers";
import { useSelector, shallowEqual } from "react-redux";

export function AsideMenuList({ layoutProps }) {
  const location = useLocation();
  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );

  const getMenuItemActive = (url, hasSubmenu = false) => {
    return checkIsActive(location, url)
      ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
      : "";
  };
  return (
    <>
      {/* begin::Menu Nav */}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        {/* Components */}
        {/* begin::section */}
        {organization.memberType === "Admin" ? (
          <>
            <li className="menu-section ">
              <h4 className="menu-text">Prestazioni</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>
            <li
              className={`menu-item ${getMenuItemActive(
                `/organizzazione/pannello-controllo/gestisci-prestazioni/`,
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link"
                to={`/organizzazione/pannello-controllo/gestisci-prestazioni/`}
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Files/Selected-file.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Gestisci prestazioni</span>
              </NavLink>
            </li>
          </>
        ) : null}
        {/*end::1 Level*/}

        {/*begin::1 Level*/}
        {organization.memberType === "Admin" ? (
          <li
            className={`menu-item ${getMenuItemActive(
              `/organizzazione/pannello-controllo/crea-prestazione/`,
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink
              className="menu-link"
              to={`/organizzazione/pannello-controllo/crea-prestazione/`}
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Communication/Write.svg"
                  )}
                />
              </span>
              <span className="menu-text">Crea prestazione</span>
            </NavLink>
          </li>
        ) : null}
        {/*end::1 Level*/}

        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Agenda</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        <li
          className={`menu-item ${getMenuItemActive(
            `/organizzazione/pannello-controllo/gestisci-disponibilita/`,
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink
            className="menu-link"
            to={`/organizzazione/pannello-controllo/gestisci-disponibilita/`}
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Clipboard-check.svg"
                )}
              />
            </span>
            <span className="menu-text">Gestisci disponibilità</span>
          </NavLink>
        </li>

        <li
          className={`menu-item ${getMenuItemActive(
            `/organizzazione/pannello-controllo/crea-disponibilita/`,
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink
            className="menu-link"
            to={`/organizzazione/pannello-controllo/crea-disponibilita/`}
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Clipboard-list.svg"
                )}
              />
            </span>
            <span className="menu-text">Crea disponibilità</span>
          </NavLink>
        </li>

        {/* Staff */}
        {/* begin::section */}
        {organization.memberType === "Admin" ? (
          <>
            <li className="menu-section ">
              <h4 className="menu-text">Staff</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>

            <li
              className={`menu-item ${getMenuItemActive(
                `/organizzazione/pannello-controllo/gestisci-medici/`,
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link"
                to={`/organizzazione/pannello-controllo/gestisci-medici/`}
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Devices/Diagnostics.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Gestisci medici</span>
              </NavLink>
            </li>

            <li
              className={`menu-item ${getMenuItemActive(
                `/organizzazione/pannello-controllo/richieste-medici/`,
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link"
                to={`/organizzazione/pannello-controllo/richieste-medici/`}
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Navigation/Waiting.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Richieste medici</span>
              </NavLink>
            </li>
          </>
        ) : /*end::1 Level*/ null}

        {/* begin::section */}
        {organization.memberType === "Admin" ? (
          <>
            <li className="menu-section ">
              <h4 className="menu-text">Sedi</h4>
              <i className="menu-icon flaticon-more-v2"></i>
            </li>

            <li
              className={`menu-item ${getMenuItemActive(
                `/organizzazione/pannello-controllo/gestisci-sedi/`,
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link"
                to={`/organizzazione/pannello-controllo/gestisci-sedi/`}
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl("/media/svg/icons/Home/Building.svg")}
                  />
                </span>
                <span className="menu-text">Gestisci sedi</span>
              </NavLink>
            </li>

            <li
              className={`menu-item ${getMenuItemActive(
                `/organizzazione/pannello-controllo/crea-sede/`,
                false
              )}`}
              aria-haspopup="true"
            >
              <NavLink
                className="menu-link"
                to={`/organizzazione/pannello-controllo/crea-sede/`}
              >
                <span className="svg-icon menu-icon">
                  <SVG
                    src={toAbsoluteUrl(
                      "/media/svg/icons/Communication/Write.svg"
                    )}
                  />
                </span>
                <span className="menu-text">Crea sede</span>
              </NavLink>
            </li>
          </>
        ) : /*end::1 Level*/ null}

        {/* dipartimenti */}
        {
          /* begin::section */
          organization.memberType === "Admin" ? (
            <>
              <li className="menu-section ">
                <h4 className="menu-text">Reparti</h4>
                <i className="menu-icon flaticon-more-v2"></i>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  `/organizzazione/pannello-controllo/gestisci-reparti/`,
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to={`/organizzazione/pannello-controllo/gestisci-reparti/`}
                >
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl("/media/svg/icons/Home/Cupboard.svg")}
                    />
                  </span>
                  <span className="menu-text">Gestisci reparti</span>
                </NavLink>
              </li>

              <li
                className={`menu-item ${getMenuItemActive(
                  `/organizzazione/pannello-controllo/crea-reparto/`,
                  false
                )}`}
                aria-haspopup="true"
              >
                <NavLink
                  className="menu-link"
                  to={`/organizzazione/pannello-controllo/crea-reparto/`}
                >
                  <span className="svg-icon menu-icon">
                    <SVG
                      src={toAbsoluteUrl(
                        "/media/svg/icons/Communication/Write.svg"
                      )}
                    />
                  </span>
                  <span className="menu-text">Crea reparto</span>
                </NavLink>
              </li>
            </>
          ) : /*end::1 Level*/ null
        }

        {/* Generali */}
        {/* begin::section */}
        <li className="menu-section ">
          <h4 className="menu-text">Generali</h4>
          <i className="menu-icon flaticon-more-v2"></i>
        </li>
        {/* end:: section */}

        {/* Error Pages */}
        {/*begin::1 Level*/}
        {organization.memberType === "Admin" ? (
          <li
            className={`menu-item ${getMenuItemActive(
              `/organizzazione/pannello-controllo/impostazioni-organizzazione/`,
              false
            )}`}
            aria-haspopup="true"
          >
            <NavLink
              className="menu-link"
              to={`/organizzazione/pannello-controllo/impostazioni-organizzazione/`}
            >
              <span className="svg-icon menu-icon">
                <SVG
                  src={toAbsoluteUrl("/media/svg/icons/General/Settings-2.svg")}
                />
              </span>
              <span className="menu-text">Impostazioni organizzazione</span>
            </NavLink>
          </li>
        ) : null}

        <li
          className={`menu-item ${getMenuItemActive(
            `/organizzazione/pannello-controllo/impostazioni-personali/`,
            false
          )}`}
          aria-haspopup="true"
        >
          <NavLink
            className="menu-link"
            to={`/organizzazione/pannello-controllo/impostazioni-personali/`}
          >
            <span className="svg-icon menu-icon">
              <SVG
                src={toAbsoluteUrl(
                  "/media/svg/icons/Communication/Contact1.svg"
                )}
              />
            </span>
            <span className="menu-text">Impostazioni personali</span>
          </NavLink>
        </li>
        {/*end::1 Level*/}
      </ul>

      {/* end::Menu Nav */}
    </>
  );
}
