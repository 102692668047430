/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import momentTimezonePlugin from "@fullcalendar/moment-timezone";
import timeGrid from "@fullcalendar/timegrid";
import bootstrapPlugin from "@fullcalendar/bootstrap";
import itLocale from "@fullcalendar/core/locales/it";
import { Skeleton } from "@material-ui/lab";
import { ModalAppointment } from "./CalendarComponent/ModalAppointment";
import moment from "moment-timezone";

export const Calendar = (props) => {
  const [render, setRender] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(
    moment(new Date()).subtract(1, "days").format("YYYY-MM-DD")
  );
  const [event, setEvent] = useState({
    title: "",
    allday: false,
    patient: "",
    prestazione: "",
    note: "",
    start: moment(new Date()).format("DD MMMM YYYY, HH:mm"),
    end: moment(new Date()).format("DD MMMM YYYY, HH:mm"),
  });
  const [showAppointment, setShowAppointment] = useState(false);

  const RenderTimer = () => {
    setRender(true);
    setTimeout(function () {
      setRender(false);
    }, 300);
  };

  useEffect(() => {
    RenderTimer();
    /*eslint-disable-next-line*/
  }, [props.count]);

  function selectDate(info) {
    setStartDate(info.startStr);
    setEndDate(info.endStr);
  }

  function handleEventClick({ event, el }) {
    if (event.extendedProps.allDay === false) {
      var newEvent = {
        title: event.title,
        allday: false,
        patient: event.extendedProps.patient,
        prestazione: event.extendedProps.prestazione,
        note: event.extendedProps.note,
        sede: event.extendedProps.nameOrganization,
        idPaziente: event.extendedProps.idPaziente,
        idMedico: parseInt(event.extendedProps.idDoc),
        idAppointment: event.id,
        videoconference:
          parseInt(event.extendedProps.videoconference) === 0 ? false : true,
        meet: parseInt(event.extendedProps.meet) === 0 ? false : true,
        start: moment(event.start.getTime()).format("DD MMMM YYYY, HH:mm"),
        end: moment(event.end.getTime()).format("DD MMMM YYYY, HH:mm"),
        dateAppointment: event.start,
      };
    } else {
      newEvent = {
        title: event.title,
        allday: true,
        description: event.extendedProps.description,
        department: event.extendedProps.department,
        id: parseInt(event.extendedProps.id),
        start: event.start,
      };
    }
    setEvent(newEvent);
    setShowAppointment(true);
  }

  return (
    <>
      <ModalAppointment
        show={showAppointment}
        setShow={setShowAppointment}
        appointment={event}
        count={props.count}
        setCount={props.setCount}
        user={props.user}
      />
      {/*  <SwipeableDrawer
        open={openAdd}
        onClose={() => setOpenAdd(false)}
        onOpen={() => setOpenAdd(true)}
        anchor="top"
      >
        <AddEvent />
      </SwipeableDrawer>
      <></>
      <SwipeableDrawer
        open={openMeet}
        onClose={() => setOpenMeet(false)}
        onOpen={() => setOpenMeet(true)}
        anchor="top"
      >
        <AddMeet />
      </SwipeableDrawer> */}
      {/* <></>
      <StickyToolbar
        openAdd={openAdd}
        setOpenAdd={setOpenAdd}
        openMeet={openMeet}
        setOpenMeet={setOpenMeet}
      /> */}
      <></>
      
      {!props.loading && !render ? (
        <FullCalendar
          plugins={[
            momentTimezonePlugin,
            dayGridPlugin,
            interactionPlugin,
            timeGrid,
            bootstrapPlugin,
          ]}
          timeZone="Europe/Rome"
          headerToolbar={{
            left: "prev,next today",
            center: "title",
            right: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          handleWindowResize={true}
          weekends={true}
          //dayCellDidMount={dateRender}
          themeSystem="bootstrap"
          locales={itLocale}
          locale={"it"}
          initialView="dayGridMonth"
          eventClick={handleEventClick}
          selectable={true}
          editable={true}
          events={props.calendar}
          select={selectDate}
        />
      ) : (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="text" />
          <Skeleton variant="rect" height={400} />
        </>
      )}
    </>
  );
};
