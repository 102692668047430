import React, { useState, useEffect } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { Tooltip } from "@material-ui/core";
import { RicevutaPrenotazione } from "../../_metronic/_partials/components/model/ricevutaPrenotazione";
import { useSelector, shallowEqual } from "react-redux";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { Skeleton } from "@material-ui/lab";
import { useSubheader } from "../../_metronic/layout";
import moment from "moment-timezone";
import config from "../../config/config";

export const Documenti = () => {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const suhbeader = useSubheader();
  suhbeader.setTitle("Documenti");

  const [columns] = useState([
    { name: "file", title: "File" },
    { name: "rifPrestazione", title: "Codice prestazione" },
    { name: "prestazione", title: "Prestazione" },
    { name: "data", title: "Data caricamento" },
    { name: "actions", title: "Azioni" },
  ]);
  const [loading, setLoading] = useState(false);
  const [rows, setRows] = useState([]);
  const [pageSizes] = useState([0, 10, 20, 50, 100]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchState] = useState("");
  const [documentColum] = useState(["actions"]);
  const [file, setFile] = useState([]);
  const [show, setShow] = useState(false);
  const [dati, setDati] = useState({
    prestazione: "",
    medico: "",
    dateAppointment: "",
    startTime: "",
    codice: "",
  });

  const TableComponent = ({ ...restProps }) => (
    <Table.Table
      {...restProps}
      className="table table-hover table-head-custom table-vertical-center overflow-hidden"
    />
  );

  const editColum = ({ value }) => (
    <>
      {/*eslint-disable-next-line*/}
      <span className="font-weight-bolder text-warning text-hover-warning py-1 font-size-lg pl-5">
        <Tooltip title="Visualliza" placement="top">
          <i
            onClick={() => {
              setDati(value);
              setShow(true);
            }}
            style={{ cursor: "pointer" }}
            className="fa-solid fa-file"
          ></i>
        </Tooltip>
      </span>
    </>
  );

  const DocumentColumn = (props) => (
    <DataTypeProvider formatterComponent={editColum} {...props} />
  );

  const dateColum = ({ value }) => (
    <span>{moment(value).format("DD/MM/YYYY")}</span>
  );

  const DateColumn = (props) => (
    <DataTypeProvider formatterComponent={dateColum} {...props} />
  );

  const ModalPrenotazione = () => {
    return (
      <Modal size="xl" show={show} onHide={() => setShow(false)}>
        <Modal.Header className="border-0 py-5">
          <Modal.Title>
            <h3 className="font-weight text-bold text-dark">
              Ricevuta prenotazione
            </h3>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="p-0">
          <RicevutaPrenotazione
            prenotazioneNumero={dati.codice}
            prestazione={dati.prestazione}
            medico={dati.medico}
            paziente={user.firstname + " " + user.lastname}
            ssn={user.ssn}
            tesseraSanitaria={user.tesseraSanitaria}
            data={moment(dati.dateAppointment).format("DD/MM/YYYY")}
            orario={dati.startTime}
          />
        </Modal.Body>
        <Modal.Footer className="border-0 py-4">
          <Button
            onClick={() => setShow(false)}
            variant="secondary float-right"
          >
            Chiudi
          </Button>
        </Modal.Footer>
      </Modal>
    );
  };

  const GetAppointmentDoc = () => {
    setLoading(true);
    const options = {
      headers: {
        Authorization: config.keyApi,
      },
      method: "POST",
      body: JSON.stringify({
        idUser: user.id,
      }),
    };
    fetch(config.apiUrl + "appointments/GetAppointmentDoc.php", options)
      .then((response) => response.json())
      .then((data) => setRows(data))
      .then(() => setLoading(false));
  };

  useEffect(() => {
    GetAppointmentDoc();
    /*eslint-disable-next-line*/
  }, []);

  return (
    <>
      <ModalPrenotazione />
      <Card className="card-custom gutter-b">
        <Card.Header className="border-0 py-4">
          <Card.Title>
            <h3 className="font-weight text-dark">Gestione documenti</h3>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          {!loading ? (
            <Grid rows={rows} columns={columns}>
              <DocumentColumn for={documentColum} />
              <DateColumn for={["data"]} />
              <SearchState value={searchValue} onValueChange={setSearchState} />
              <IntegratedFiltering />
              <PagingState
                currentPage={currentPage}
                onCurrentPageChange={setCurrentPage}
                pageSize={pageSize}
                onPageSizeChange={setPageSize}
              />
              <IntegratedPaging />
              <Table tableComponent={TableComponent} />
              <TableHeaderRow />
              <PagingPanel pageSizes={pageSizes} />
              <Toolbar />
              <SearchPanel />
            </Grid>
          ) : (
            <div className="row">
              <div className="col-12">
                <Skeleton variant="text" />
              </div>
              <div className="col-12">
                <Skeleton variant="text" />
              </div>
              <div className="col-12">
                <Skeleton variant="text" />
              </div>
              <div className="col-12">
                <Skeleton variant="rect" height={600} />
              </div>
            </div>
          )}
        </Card.Body>
      </Card>
    </>
  );
};
