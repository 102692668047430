/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_partials/controls";
import { useSelector, shallowEqual } from "react-redux";
import { Form, Tooltip, OverlayTrigger, Button } from "react-bootstrap";
import { useFormik } from "formik";
import { MakeForm } from "../utils/functions";
import * as yup from "yup";
import { GetInfoService } from "../../../../config/api/organizations";
import { Skeleton } from "@material-ui/lab";
import Select from "react-select";
import {
  DeleteSiteFromService,
  UpdateService,
} from "../../../../config/api/organizations";
import Snackbar from "@material-ui/core/Snackbar";
import MuiAlert from "@material-ui/lab/Alert";

function Alert(props) {
  return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const EditService = ({ page, setPage, idService, idOrganization }) => {
  const ReturnToSiteSummary = () => {
    setPage("summary");
  };

  const [open, setOpen] = useState(false);
  const [condition, setCondition] = useState("");

  const handleClick = () => {
    setOpen(true);
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const user = useSelector((state) => state.auth.user, shallowEqual);

  const [loading, setLoading] = useState(true);
  const [selectedSites, setSelectedSites] = useState([]);
  const [count, setCount] = useState(0);

  const [initialValues, setInitalValues] = useState({
    name: "",
    duration: "",
    videoconference: "",
    deletable: "",
    exipreDelete: "",
    description: "",
    department: "",
  });

  const [department, setDepartment] = useState([]);
  const [sites, setSites] = useState([]);
  const [availableSites, setAvailableSites] = useState([]);

  const payload = {
    accessToken: user.authToken,
    idService: idService,
    idOrganization: idOrganization,
  };

  useEffect(() => {
    setLoading(true);
    GetInfoService(payload)
      .then((data) => {
        setInitalValues(...data.result.infos);
        setSites(data.result.sites);
        setDepartment(data.result.department);
        setAvailableSites(data.result.availableSites);
      })
      .then(() => setLoading(false));
    /*eslint-disable-next-line*/
  }, [idService, count]);

  const schema = yup.object({
    name: yup
      .string("Inserire un nome valido")
      .required("Inserire uno nome valido")
      .min(3, "Minimo 3 caratteri")
      .max(100, "Massimo 100 caratteri"),
    duration: yup
      .number("Inserire una durata valida")
      .required("Inserire una durata valida")
      .positive("La durata non può essere un numero negativo")
      .integer("Numeri decimali non ammessi"),
    expireDelete: yup
      .number("Inserire entro quanti giorni può essere annullato")
      .required("Inserire entro quanti giorni può essere annullato")
      .positive("La scadenza non può essere un numero negativo")
      .integer("Numeri decimali non ammessi"),
    description: yup
      .string("Inserire una descrizione valida")
      .notRequired()
      .min(3, "Minimo 3 caratteri")
      .max(255, "Massimo 255 caratteri"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      values.deletable = values.deletable === true ? 1 : 0;
      values.videoconference = values.videoconference === true ? 1 : 0;
      const payload = {
        accessToken: user.authToken,
        selectedSites: selectedSites,
        idService: idService,
        ...values,
      };
      UpdateService(payload)
        .then((data) => setCondition(data.code))
        .then(() => handleClick())
        .then(() => setCount(count + 1));
    },
  });

  const sitesTooltip = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Nessuna sede disponibile o creata per questa organizzazione
    </Tooltip>
  );

  const fields = [
    {
      name: "name",
      label: "Nome prestazione",
      placeholder: "Nome prestazione...",
      type: "text",
      cols: "col-xl-6 col-lg-6 col-sm-12",
      disabled: false,
      subField: false,
    },
    {
      name: "department",
      value: formik.values.department,
      label: "Seleziona dipartimento",
      baseOption: { label: "Nessun dipartimento", value: 0 },
      type: "select",
      cols: "col-xl-6 col-lg-6 col-sm-12",
      options: department,
      disabled: false,
      subField: false,
    },
    {
      name: "duration",
      label: "Durata prestazione",
      placeholder: "Durata...",
      type: "number",
      cols: "col-xl-6 col-lg-6 col-sm-12",
      disabled: false,
      subField: false,
    },
    {
      name: "deletable",
      label: "La prestazione si può annullare?",
      type: "checkbox",
      cols: "col-xl-6 col-lg-6 col-sm-12",
      disabled: false,
      subField: false,
    },
    {
      name: "expireDelete",
      label: "Giorni entro quale si può annullare",
      placeholder: "Giorni...",
      type: "number",
      cols: "col-xl-6 col-lg-6 col-sm-12",
      disabled: false,
      subField: true,
      field: formik.values.deletable,
    },
    {
      name: "videoconference",
      label: "Si può eseguire tramite teleconsulto?",
      type: "checkbox",
      cols: "col-xl-6 col-lg-6 col-sm-12",
      disabled: false,
      subField: false,
    },
  ];

  return (
    <>
      <Snackbar
        open={open}
        autoHideDuration={2500}
        onClose={handleClose}
      >
        <Alert
          onClose={handleClose}
          severity={condition === 200 ? "success" : "error"}
        >
          {condition === 200 ? "Modifica salvata" : "Errore"}
        </Alert>
      </Snackbar>
      <Card>
        <CardHeader title="Modifica prestazione">
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={ReturnToSiteSummary}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              Prestazioni
            </button>
            {`  `}
            <button onClick={formik.resetForm} className="btn btn-light ml-2">
              <i className="fa fa-redo"></i>
              Reset
            </button>
            {`  `}
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={formik.handleSubmit}
            >
              Salva modifiche
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {!loading ? (
            <Form>
              <Form.Row>
                <MakeForm fields={fields} formik={formik} />
                <Form.Group className="col-xl-6 col-lg-6 col-sm-12">
                  {availableSites.length > 0 ? (
                    <>
                      <Form.Label className="col-12">Seleziona sedi</Form.Label>
                      <Select
                        defaultValue={selectedSites}
                        isMulti
                        isClearable={true}
                        onChange={(e) => {
                          setSelectedSites(e);
                        }}
                        options={availableSites}
                        name="site"
                        placeholder="Seleziona sedi..."
                      />
                    </>
                  ) : (
                    <>
                      <Form.Label className="col-12">
                        Seleziona sedi
                        <OverlayTrigger
                          placement="bottom"
                          delay={{ show: 250, hide: 400 }}
                          overlay={sitesTooltip}
                        >
                          <i className="far fa-question-circle fa-sm pl-2"></i>
                        </OverlayTrigger>
                      </Form.Label>
                      <Select
                        options={sites}
                        name="site"
                        placeholder="Seleziona sedi..."
                        isDisabled={true}
                      />
                    </>
                  )}
                </Form.Group>
              </Form.Row>
            </Form>
          ) : (
            <Skeleton />
          )}
          {sites.length > 0 ? (
            <>
              <div className="separator separator-solid"></div>
              <>
                {sites.map((e, key) => (
                  <>
                    <div key={"1" + key} className="p-8">
                      <div
                        key={"2" + key}
                        className="d-flex align-items-center justify-content-xl-between mb-4"
                      >
                        <span
                          key={"3" + key}
                          className="font-weight-bold text-muted font-size-sm mr-2"
                        >
                          Sede:
                        </span>
                        <span
                          key={"4" + key}
                          className="font-weight-bolder text-primary text-right"
                        >
                          {e.siteName}
                        </span>
                      </div>
                      <div
                        key={"5" + key}
                        className="d-flex align-items-center justify-content-xl-between mb-7"
                      >
                        <span
                          key={"6" + key}
                          className="font-weight-bold text-muted font-size-sm mr-2"
                        >
                          Regione
                        </span>
                        <span
                          key={"7" + key}
                          className="font-weight-bolder text-dark-50 text-right"
                        >
                          {e.region}
                        </span>
                      </div>
                      <div
                        key={"8" + key}
                        className="d-flex align-items-center justify-content-xl-between mb-7"
                      >
                        <span
                          key={"9" + key}
                          className="font-weight-bold text-muted font-size-sm mr-2"
                        >
                          Provincia
                        </span>
                        <span
                          key={"10" + key}
                          className="font-weight-bolder text-dark-50 text-right"
                        >
                          {e.province}
                        </span>
                      </div>
                      <div
                        key={"11" + key}
                        className="d-flex align-items-center justify-content-xl-between mb-7"
                      >
                        <span
                          key={"12" + key}
                          className="font-weight-bold text-muted font-size-sm mr-2"
                        >
                          Città
                        </span>
                        <span
                          key={"13" + key}
                          className="font-weight-bolder text-dark-50 text-right"
                        >
                          {e.city}
                        </span>
                      </div>
                      <div
                        key={"divButton" + key}
                        className="d-flex align-items-right justify-content-xl-between float-right"
                      >
                        <Button
                          type="checkbox"
                          variant={!e.delete ? "outline-danger" : "danger"}
                          checked={e.delete}
                          className="ml-2"
                          onClick={() => {
                            DeleteSiteFromService({
                              idSite: e.idSite,
                              accessToken: user.authToken,
                              idService: idService,
                            })
                              .then((data) => setCondition(data.code))
                              .then(() => setCount(count + 1))
                              .then(() => handleClick());
                          }}
                        >
                          Elimina
                        </Button>
                      </div>
                    </div>
                    <div
                      key={"14" + key}
                      className="separator separator-solid mt-7"
                    ></div>
                  </>
                ))}
              </>
            </>
          ) : null}
        </CardBody>
      </Card>
    </>
  );
};
