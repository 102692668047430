/* eslint-disable no-restricted-imports */
import React, { useState } from "react";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import moment from "moment";

export const Accepted = (props) => {
  const [columns] = useState([
    { name: "medic", title: "Medico" },
    { name: "dateExpire", title: "Scadenza autorizzazione" },
    { name: "dateUpdated", title: "Accettazione" },
    { name: "dateCreated", title: "Data richiesta" },
  ]);

  const [rows] = useState(props.richieste);

  const [pageSizes] = useState([10, 20, 50, 0]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchState] = useState("");

  const DataRichiestaColumn = (props) => (
    <DataTypeProvider
      formatterComponent={({ value }) =>
        moment(value).format("DD MMMM YYYY, HH:mm")
      }
      {...props}
    />
  );

  const DataAggiornamentoColumn = (props) => (
    <DataTypeProvider
      formatterComponent={({ value }) =>
        moment(value).format("DD MMMM YYYY, HH:mm")
      }
      {...props}
    />
  );

  const TableComponent = ({ ...restProps }) => (
    <Table.Table
      {...restProps}
      className="table table-hover table-head-custom table-vertical-center overflow-hidden"
    />
  );

  return (
    <>
      <Grid rows={rows ? rows : []} columns={columns ? columns : []}>
        <SearchState value={searchValue} onValueChange={setSearchState} />
        <DataRichiestaColumn for={["dateCreated"]} />
        <DataAggiornamentoColumn for={["dateUpdated", "dateExpire"]} />
        <IntegratedFiltering />
        <PagingState
          currentPage={currentPage}
          onCurrentPageChange={setCurrentPage}
          pageSize={pageSize}
          onPageSizeChange={setPageSize}
        />
        <IntegratedPaging />
        <Table tableComponent={TableComponent} />
        <TableHeaderRow />
        <PagingPanel pageSizes={pageSizes} />
        <Toolbar />
        <SearchPanel />
      </Grid>
    </>
  );
};
