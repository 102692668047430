import React, { useEffect, useState } from "react";
import { Button, Card } from "react-bootstrap";
import { GetPazienteCartella } from "../../../config/api/cartellaDigitale";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { decrypt } from "../../../config/utils/crypt";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { CardValore } from "../cartellaDigitale/widgets/cardValore";
import { Skeleton } from "@material-ui/lab";
import { Riepilogo } from "../cartellaDigitale/widgets/Riepilogo";
import moment from "moment-timezone";

export const CardCartella = ({ idRichiesta, setPage, accessToken, name }) => {
  useEffect(() => {
    setLoading(true);
    GetPazienteCartella({
      accessToken: accessToken,
      idRichiesta: idRichiesta,
    })
      .then((data) => {
        let newObj = {};
        let objGrid = [];
        for (var k in data.result.data) {
          newObj[k] = data.result.data[k].map((e) => ({
            argument: moment(e.argument).format("DD MMMM YYYY"),
            lineValue: decrypt(
              e.lineValue,
              decrypt(data.result.access.pin, data.result.access.token)
            ),
          }));
          let label = k;

          data.result.data[k].map((e) =>
            objGrid.push({
              label: label,
              /* "": "", */
              dateCreated: moment(e.argument).format("DD MMMM YYYY"),
              value: decrypt(
                e.lineValue,
                decrypt(data.result.access.pin, data.result.access.token)
              ),
            })
          );
        }
        setDati(newObj);
      })
      .then(() => setLoading(false));
    /*eslint-disable-next-line*/
  }, []);
  const [dati, setDati] = useState([]);
  const [loading, setLoading] = useState(false);
  const [summary, setSummary] = useState({});
  const [grafico, setGrafico] = useState("cards");

  return (
    <Card className="border-0">
      <Card.Header className="border-0 row">
        <Card.Title className="float-left col-6">
          Cartella digitale - {name}
        </Card.Title>
        <div className="float-right text-right col-6">
          <Button onClick={() => setPage("pazienti")}>
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        </div>
      </Card.Header>
      {grafico === "cards" ? (
        <Card.Body className="border-0">
          {!loading ? (
            <div className="row">
              {Object.keys(dati).map((e, key) => {
                let last = Array.from(dati[e].values()).pop();
                return (
                  <CardValore
                    setPage={setGrafico}
                    label={e}
                    value={last.lineValue}
                    summary={dati[e]}
                    setSummary={setSummary}
                  />
                );
              })}
            </div>
          ) : (
            <div className="row">
              <div className="col-4">
                <Skeleton variant="rect" height={300} />
              </div>
              <div className="col-4">
                <Skeleton variant="rect" height={300} />
              </div>
              <div className="col-4">
                <Skeleton variant="rect" height={300} />
              </div>
              <div className="col-4 mt-4">
                <Skeleton variant="rect" height={300} />
              </div>
              <div className="col-4 mt-4">
                <Skeleton variant="rect" height={300} />
              </div>
              <div className="col-4 mt-4">
                <Skeleton variant="rect" height={300} />
              </div>
            </div>
          )}
        </Card.Body>
      ) : (
        <Card.Body>
          <Riepilogo setPage={setGrafico} summary={summary} />
        </Card.Body>
      )}
    </Card>
  );
};
