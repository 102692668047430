import moment from "moment-timezone";

const TimenozeInMinutes = () => {
  var tz_guess = moment.tz.guess();
  var tz_tm = moment.tz(tz_guess).format("Z");

  var sign = tz_tm.charAt(0);
  var result = tz_tm.substring(1) + ":00";
  var hms = result; // your input string
  var a = hms.split(":"); // split it at the colons
  // Hours are worth 60 minutes.
  var minutes = +a[0] * 60 + +a[1];

  return sign + "" + minutes;
};

function GetBrowser() {
  var name;
  if (
    (navigator.userAgent.indexOf("Opera") ||
      navigator.userAgent.indexOf("OPR")) !== -1
  ) {
    name = "Opera";
  } else if (navigator.userAgent.indexOf("Chrome") !== -1) {
    name = "Chrome";
  } else if (navigator.userAgent.indexOf("Safari") !== -1) {
    name = "Safari";
  } else if (navigator.userAgent.indexOf("Firefox") !== -1) {
    name = "Firefox";
  } else if (
    navigator.userAgent.indexOf("MSIE") !== -1 ||
    !!document.documentMode === true
  ) {
    //IF IE > 10
    name = "IE";
  } else {
    name = "unknown";
  }

  return name;
}

var path;
if (window.location.origin.includes("localhost")) {
  path = "http://localhost/doctor-chain/public";
} else {
  path = window.location.origin;
}

const arrConfig = {
  apiUrl: path + "/inc/api/",
  siteUrl: window.location.origin + "/dashboard",
  sitePath: window.location.origin,
  timezone: TimenozeInMinutes(),
  browser: GetBrowser(),
  keyApi: "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
};
export default arrConfig;

// Description for auction
export const SetDataTimeZone = (date, format) => {
  function RefreshDataTimeZone(date) {
    var dateType = date;

    var event = new Date(dateType);

    event.setMinutes(TimenozeInMinutes());

    var dateSet = moment(event).format(format);

    return dateSet;
  }

  return RefreshDataTimeZone(date);
};

export const icons = {
  edit: "/media/svg/icons/Communication/Write.svg",
  delete: "/media/svg/icons/General/Trash.svg",
  addToCart: "/media/svg/icons/Shopping/Cart1.svg",
  close: "/media/svg/icons/Navigation/Close.svg",
  reset: "/media/svg/icons/Media/Repeat.svg",
  go_back: "/media/svg/icons/Navigation/Angle-left.svg",
  ok: "/media/svg/icons/Navigation/Check.svg",
  go: "/media/svg/icons/Communication/Send.svg",
};

export const statusRichiesta = {
  inAttesa: 0,
  approvato: 1, //
  annullato: 2, // cancellato da agente o rifiutato da magazziniere
};

// from a full date-time-offset ISO8601/RFC3339 value
// console.log(SetDataTimeZone('2017/05/22 02:00', "YYYY-MM-DD HH:mm:ss"))

export const Pad = (n, width, z) => {
  z = z || "0";
  n = n + "";
  return n.length >= width ? n : new Array(width - n.length + 1).join(z) + n;
};

export const roles = {
  doctor: 1,
  patient: 2,
};

export const url = () => {
  var url;
  if (window.location.href.indexOf("organizzazione") > -1) {
    url = true;
  } else {
    url = false;
  }
  return url;
};
