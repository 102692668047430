/**
 * TODO: 
 * inserire disclaimer per accettare utilizzo di blockchain e trattamento dei dati.
 */
import React, { useState } from "react";
import { useFormik } from "formik";
import { connect } from "react-redux";
import * as Yup from "yup";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import config, { roles } from "../../../../config/config";

const initialValues = {
  role: roles.patient,
  email: "",
  password: "",
  changepassword: "",
  acceptTerms: false,
};

function Registration(props) {
  const { intl } = props;
  /*eslint-disable-next-line*/
  const [loading, setLoading] = useState(false);
  const [result, setResult] = useState(false);
  const [show, setShow] = useState(false);
  const RegistrationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Inserisci un'email valida")
      .min(3, "Minimo 3 caratteri")
      .max(50, "Massimo 50 caratteri")
      .required("Inserisci un'email valida"),
    password: Yup.string()
      .min(8, "Minimo 8 caratteri")
      .max(16, "Massimo 16 caratteri")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    changepassword: Yup.string()
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      )
      .when("password", {
        is: (val) => (val && val.length > 0 ? true : false),
        then: Yup.string().oneOf(
          [Yup.ref("password")],
          "Le due password non corrispondono"
        ),
      }),
  });

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: RegistrationSchema,
    onSubmit: (values) => {
      const options = {
        headers: {
          Authorization:
            "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
        },
        method: "POST",
        body: JSON.stringify({
          role: values.role,
          email: values.email,
          password: values.password,
        }),
      };
      fetch(config.apiUrl + "users/CreateUser.php", options)
        .then((response) => response.json())
        .then((data) => {
          if (data) {
            window.location.href = "auth/login";
          } else {
            setResult(true);
          }
        });
    },
  });

  return (
    <div className="login-form login-signin" style={{ display: "block" }}>
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.REGISTER.TITLE" />
        </h3>
        <p className="text-muted font-weight-bold">Crea il tuo account</p>
      </div>

      <form
        id="kt_login_signin_form"
        className="form fv-plugins-bootstrap fv-plugins-framework animated animate__animated animate__backInUp"
        onSubmit={formik.handleSubmit}
      >
        {/* begin: Alert */}
        {formik.status && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        )}
        {/* end: Alert */}

        {/* select per il ruolo*/}
        <div>
          <select
            type="role"
            className={`form-control mb-5 ${getInputClasses("role")}`}
            onChange={formik.handleChange}
            name="role"
            {...formik.getFieldProps("role")}
          >
            <option value={parseInt(roles.doctor)}>
              Dottore - Organizzazione
            </option>
            <option value={parseInt(roles.patient)}>Paziente</option>
          </select>
          {formik.touched.role && formik.errors.role ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.role}</div>
            </div>
          ) : null}
        </div>
        {/* fine select per il ruolo*/}

        {/* begin: Email */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            onClick={() => setResult(false)}
            {...formik.getFieldProps("email")}
          />
          {result ? (
            <span style={{ color: "red" }}>Email già esistente</span>
          ) : null}
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        {/* end: Email */}

        {/* begin: Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Password"
            type={show ? "text" : "password"}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "password"
            )}`}
            name="password"
            {...formik.getFieldProps("password")}
          />
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* end: Password */}

        {/* begin: Confirm Password */}
        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Conferma password"
            type={show ? "text" : "password"}
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "changepassword"
            )}`}
            name="changepassword"
            {...formik.getFieldProps("changepassword")}
          />
          {formik.touched.changepassword && formik.errors.changepassword ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">
                {formik.errors.changepassword}
              </div>
            </div>
          ) : null}
        </div>
        {/* end: Confirm Password */}

        {/* begin: Terms and Conditions */}
        <div className="form-group">
          <label className="checkbox">
            <input
              type="checkbox"
              name="showPassword"
              className="m-1"
              onChange={() => setShow(!show)}
            />
            <div className="mr-2">
              {!show ? "Mostra password" : "Nascondi password"}
            </div>
            <span />
          </label>
        </div>
        {/* end: Terms and Conditions */}
        <div className="form-group d-flex flex-wrap float-right">
          <button
            type="submit"
            onClick={formik.handleSubmit}
            // disabled={
            //   formik.isSubmitting ||
            //   !formik.isValid ||
            //   !formik.values.acceptTerms
            // }
            className="btn btn-primary font-weight-bold px-9 py-4 my-3 mx-4"
          >
            <span>Registrati</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
      </form>
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Registration));
