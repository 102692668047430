import CryptoJS from "crypto-js";

/**
 * This function uses CryptoJS. Pass the value to be encrypted and the key
 * that will be used from CryptoJS to encrypt the value.
 *
 * @param {String} value the value you want to encrypt
 * @param {String} key the key used to encrypt
 * @returns {String} encrypted value from function ready to be stored
 */
export const encrypt = (value, key) => {
  var ciphertext = CryptoJS.AES.encrypt(JSON.stringify(value), key).toString();

  return ciphertext;
};

/**
 * This function uses CryptoJS. Pass the value to be decrypted and the key used the first time
 * you encrypted the value.
 * CryptoJS will return the orignal value stored by the user.
 *
 * @param {String} value the encrypted value
 * @param {String} key the key used to encrypt the value in past
 * 
 * @returns {String} the value that was originally encrypted in his original form
 */
export const decrypt = (value, key) => {
  var bytes = CryptoJS.AES.decrypt(value, key);
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

  return decryptedData;
};
