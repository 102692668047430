import React from "react";
import { useFormik } from "formik";
import { useSelector, shallowEqual } from "react-redux";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import config from "../../../config/config";

export const CreaReparto = ({history}) => {
  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );

  const ReturnToSiteSummary = () => {
    history.push(
      `/organizzazione/pannello-controllo/gestisci-reparti`,
      { from: "pannello-controllo" }
    );
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const schema = Yup.object({
    name: Yup.string("Inserire un nome valido")
      .required("Inserire uno nome valido")
      .min(3, "Minimo 3 caratteri")
      .max(100, "Massimo 100 caratteri"),
    description: Yup.string("Inserire una descrizione valida")
      .notRequired()
      .min(3, "Minimo 3 caratteri")
      .max(255, "Massimo 255 caratteri"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const requestOptions = {
        headers: {
          Authorization:
            "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
        },
        method: "POST",
        body: JSON.stringify({
          name: values.name,
          description: values.description,
          idOrganization: organization.id,
        }),
      };

      fetch(config.apiUrl + "organization/CreateDepartment.php", requestOptions)
        .then(() => formik.resetForm())
        .then(() => ReturnToSiteSummary());
    },
  });

  return (
    <Card>
      <CardHeader title="Crea reparto">
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={ReturnToSiteSummary}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Reparti
          </button>
          <button onClick={formik.resetForm} className="btn btn-light ml-2">
            <i className="fa fa-redo"></i>
            Reset
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={formik.handleSubmit}
          >
            Salva
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Form className="row">
          <Form.Group className="col-12">
            <Form.Label className="col-12">Nome reparto</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome..."
              value={formik.values.name}
              onChange={formik.handleChange}
              className={getInputClasses("name")}
              {...formik.getFieldProps("name")}
              name="name"
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.name}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-12">
            <Form.Label className="col-12">Descrizione</Form.Label>
            <Form.Control
              as="textarea"
              style={{
                resize: "none",
              }}
              rows={4}
              placeholder="Descrizione..."
              value={formik.values.description}
              onChange={formik.handleChange}
              className={getInputClasses("description")}
              {...formik.getFieldProps("description")}
              name="description"
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.description}</div>
              </div>
            ) : null}
          </Form.Group>
        </Form>
      </CardBody>
    </Card>
  );
};
