import React, { useState } from "react";
import { useSubheader } from "../../_metronic/layout";
import { Card } from "react-bootstrap";
import { TabellaPazienti } from "../modules/RichiestaCartellaDigitale/TabellaPazienti";

export const RichiestaCartellaDigitale = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Richiesta cartella digitale");

  return (
    <Card>
      <Card.Header className="border-0">
        <Card.Title>Cartelle cliniche digitali</Card.Title>
      </Card.Header>
      <Card.Body className="border-0">
        <TabellaPazienti />
      </Card.Body>
    </Card>
  );
};
