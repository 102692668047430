import React, { useEffect, useState } from "react";
import config from "../../../../../config/config";
import { Button, Card } from "react-bootstrap";
import Skeleton from "@material-ui/lab/Skeleton";
import moment from "moment";
import "moment/locale/it";
import { ModalPrenotazione } from "../../modals/ModalPrenotazione";

export const StepDate = (props) => {
  const selectedStepDue = props.stepDueSelect;

  const [slots, setSlots] = useState([]);
  const [loading, setLoading] = useState(false);
  const [selectedSlot, setSelectedSlot] = useState({
    startTime: "",
    endTime: "",
    date: "",
  });
  const [show, setShow] = useState(false);
  const handleOpen = (startTime, endTime, date) => {
    setSelectedSlot({ startTime: startTime, endTime: endTime, date: date });
    setShow(true);
  };
  const handleClose = () => {
    setShow(false);
    setSelectedSlot({ startTime: "", endTime: "", date: "" });
  };

  var months = "Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre".split(
    "_"
  );
  moment.locale("it");
  moment.updateLocale("it", { months: months });

  const GetSlots = () => {
    setLoading(true);
    const requestOptions = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: selectedStepDue.idOrganization,
        idDoc: selectedStepDue.idDoc,
        idService: selectedStepDue.idService,
        startDate: selectedStepDue.startDate,
        endDate: selectedStepDue.endDate,
      }),
    };
    fetch(config.apiUrl + "appointments/GetSlots.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setSlots(data))
      .then(() => setLoading(false));
  };

  useEffect(() => {
    GetSlots();
    /*eslint-disable-next-line*/
  }, []);

  return (
    <>
      <ModalPrenotazione
        prestazione={selectedStepDue.idService}
        setIdPrenotazione={props.setIdPrenotazione}
        step={props.step}
        setStep={props.setStep}
        idDoc={selectedStepDue.idDoc}
        idOrganization={selectedStepDue.idOrganization}
        startTime={selectedSlot.startTime}
        endTime={selectedSlot.endTime}
        date={selectedSlot.date}
        show={show}
        handleClose={handleClose}
        selectedSlot={selectedSlot}
        nameService={props.nameService}
      />

      <div className="d-flex flex-column">
        {!loading ? (
          slots.map((e, key) => (
            <div className="col-12 card-appointment-box py-2 my-2">
              <div className="card slot-appointment">
                <div className="card-body card-box-appointment text-center p-8">
                  <div className="row">
                    <div className="col">
                      <div className="label-appointment">{e.startTime}</div>
                      <span className="text-muted">Ora inizio</span>
                    </div>
                    <div className="col">
                      <div className="hrAppointment">
                        <hr className="hrLineAppointment" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="label-duration p-3">
                        <div>{e.duration}'</div>
                        <span className="text-muted">durata</span>
                      </div>
                    </div>
                    <div className="col">
                      <div className="hrAppointment">
                        <hr className="hrLineAppointment" />
                      </div>
                    </div>
                    <div className="col">
                      <div className="label-appointment">{e.endTime}</div>
                      <span className="text-muted">Ora fine</span>
                    </div>
                  </div>
                </div>
                <div className="card-footer pt-4">
                  <div className="float-left">
                    <span className="mr-3 text-center">
                      <span className="label-date text-center">
                        {moment(e.date).format("DD MMMM YYYY")}
                      </span>
                      <br />
                      <span className="label-text-date">Data appuntamento</span>
                    </span>
                  </div>
                  <Button
                    onClick={() => handleOpen(e.startTime, e.endTime, e.date)}
                    className="float-right"
                  >
                    Seleziona
                  </Button>
                </div>
              </div>
            </div>
          ))
        ) : (
          <Skeleton />
        )}
      </div>

      <div className="col-12">
        <Button
          className="float-right mx-5 my-5"
          onClick={() => props.setStep(props.step - 1)}
        >
          Indietro
        </Button>
      </div>
    </>
  );
};
