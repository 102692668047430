import React, { useMemo, useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import ApexCharts from "apexcharts";
import { useHtmlClassService } from "../../../layout";
import objectPath from "object-path";

export const PrestazioniChart = (props) => {
  const uiService = useHtmlClassService();
  const [render, setRender] = useState(false);
  const layoutProps = useMemo(() => {
    return {
      colorsGrayGray500: objectPath.get(
        uiService.config,
        "js.colors.gray.gray500"
      ),
      colorsGrayGray200: objectPath.get(
        uiService.config,
        "js.colors.gray.gray200"
      ),
      colorsThemeBaseColor: objectPath.get(
        uiService.config,
        `js.colors.theme.base.danger`
      ),
      colorsThemeLightColor: objectPath.get(
        uiService.config,
        `js.colors.theme.light.danger`
      ),
      fontFamily: objectPath.get(uiService.config, "js.fontFamily"),
    };
  }, [uiService]);

  const renderTimer = () => {
    setRender(true)
    setTimeout(function () {
      setRender(false)
    }, 300)
  }

  useEffect(() => {
    renderTimer()
    /*eslint-disable-next-line*/
  }, []);


  useEffect(() => {
    if (!render) {

      const element = document.getElementById("kt_chart_widget_6_chart");

      if (!element) {
        return;
      }

      const options = getChartOption(layoutProps);
      const chart = new ApexCharts(element, options);
      chart.render();
      return function cleanUp() {
        chart.destroy();
      }
    };
  }, [layoutProps, render]);

  return (
    <>
      <Card className={props.className}>
        <Card.Header className='card-header border-0 pt-5'>
          <Card.Title>
            <h3 className="card-title font-weight-bolder text-black">
              Prenotazioni
            </h3>
          </Card.Title>
        </Card.Header>
        <Card.Body>
          <div className="row">
            <div className="col-lg-3 col-md-3 col-sm-3 d-flex flex-column">
              <div className="card" style={{ border: "none" }}>
                <div
                  className="bg-light-warning p-8 roudend-xl flex-grow-1"
                  style={{ borderRadius: "25px" }}
                >
                  <div className="d-flex align-items-center mb-2">
                    <div className="d-flex align-items-center mb-2">
                      <div className="symbol symbol-circle symbol-white symbol-30 flex-shrink-0 mr-3">
                        <div className="symbol-label">
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <i
                              className="fa-solid fa-l pt-1 "
                              style={{ color: "#EF476F" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="font-size-sm font-weight-bold">
                          Lunedì
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <div className="d-flex align-items-center mb-2">
                      <div className="symbol symbol-circle symbol-white symbol-30 flex-shrink-0 mr-3">
                        <div className="symbol-label">
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <i
                              className="fa-solid fa-m pt-1"
                              style={{ color: "#F78C6B" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="font-size-sm font-weight-bold">
                          Martedì
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <div className="d-flex align-items-center mb-2">
                      <div className="symbol symbol-circle symbol-white symbol-30 flex-shrink-0 mr-3">
                        <div className="symbol-label">
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <i
                              className="fa-solid fa-m pt-1"
                              style={{ color: "#FFD166" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="font-size-sm font-weight-bold">
                          Mercoledì
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <div className="d-flex align-items-center mb-2">
                      <div className="symbol symbol-circle symbol-white symbol-30 flex-shrink-0 mr-3">
                        <div className="symbol-label">
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <i
                              className="fa-solid fa-g pt-1"
                              style={{ color: "#06D6A0" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="font-size-sm font-weight-bold">
                          Giovedì
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <div className="d-flex align-items-center mb-2">
                      <div className="symbol symbol-circle symbol-white symbol-30 flex-shrink-0 mr-3">
                        <div className="symbol-label">
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <i
                              className="fa-solid fa-v pt-1"
                              style={{ color: "#0CB0A9" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="font-size-sm font-weight-bold">
                          Venerdì
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center mb-2">
                    <div className="d-flex align-items-center mb-2">
                      <div className="symbol symbol-circle symbol-white symbol-30 flex-shrink-0 mr-3">
                        <div className="symbol-label">
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <i
                              className="fa-solid fa-s pt-1"
                              style={{ color: "#118AB2" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="font-size-sm font-weight-bold">
                          Sabato
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="d-flex align-items-center">
                    <div className="d-flex align-items-center">
                      <div className="symbol symbol-circle symbol-white symbol-30 flex-shrink-0 mr-3">
                        <div className="symbol-label">
                          <span className="svg-icon svg-icon-md svg-icon-danger">
                            <i
                              className="fa-solid fa-d pt-1"
                              style={{ color: "#073B4C" }}
                            ></i>
                          </span>
                        </div>
                      </div>
                      <div>
                        <div className="font-size-sm font-weight-bold">
                          Domenica
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-9 col-md-9 col-sm-12">
              {/* begin::Chart */}
              {!render ? <div
                id="kt_chart_widget_6_chart"
                data-color="danger"
                style={{ height: "400px", minHeight: "400px" }}
              />
                : null}
            </div>
            {/* end::Chart */}
          </div>
        </Card.Body>
      </Card>
    </>
  );
};

const getChartOption = (layoutProps) => {
  const options = {
    series: [
      {
        name: "Lunedì",
        type: "bar",
        stacked: true,
        data: [10, 10, 5, 20, 10, 10, 30, 15, 10, 20, 5, 10],
      },
      {
        name: "Martedì",
        type: "bar",
        stacked: true,
        data: [20, 20, 25, 10, 20, 10, 10, 10, 5, 30, 10, 20],
      },
      {
        name: "Mercoledì",
        type: "bar",
        data: [13, 20, 10, 5, 10, 20, 10, 5, 15, 10, 20, 10],
      },
      {
        name: "Giovedì",
        type: "bar",
        data: [17, 10, 5, 10, 5, 20, 5, 20, 15, 5, 10, 10],
      },
      {
        name: "Venerdì",
        type: "bar",
        data: [20, 5, 20, 10, 20, 10, 10, 10, 5, 10, 15, 10],
      },
      {
        name: "Sabato",
        type: "bar",
        data: [5, 20, 25, 5, 10, 20, 5, 10, 5, 10, 5, 10],
      },
      {
        name: "Domenica",
        type: "bar",
        data: [5, 10, 5, 10, 20, 15, 20, 10, 15, 25, 20, 10],
      },
      {
        name: "Totale",
        type: "area",
        data: [87, 96, 96, 75, 96, 108, 87, 75, 72, 108, 85, 83],
      },
    ],
    chart: {
      stacked: true,
      height: 350,
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        stacked: true,
        horizontal: false,
        endingShape: "rounded",
        columnWidth: ["17%"],
      },
    },
    legend: {
      show: false,
    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      show: true,
      width: 2,
      colors: ["transparent"],
    },
    xaxis: {
      categories: ["Gen", "Feb", "Mar", "Apr", "Mag", "Giu", "Lug", "Ago", "Set", "Ott", "Nov", "Dic"],
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    yaxis: {
      max: 120,
      labels: {
        style: {
          colors: layoutProps.colorsGrayGray500,
          fontSize: "12px",
          fontFamily: layoutProps.fontFamily,
        },
      },
    },
    fill: {
      opacity: 1,
    },
    states: {
      normal: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      hover: {
        filter: {
          type: "none",
          value: 0,
        },
      },
      active: {
        allowMultipleDataPointsSelection: false,
        filter: {
          type: "none",
          value: 0,
        },
      },
    },
    tooltip: {
      style: {
        fontSize: "12px",
        fontFamily: layoutProps.fontFamily,
      },
      y: {
        formatter: function (val) {
          return val;
        },
      },
    },
    colors: ["#EF476F", "#F78C6B", "#FFD166", "#06D6A0", "#0CB0A9", "#118AB2", "#073B4C", "#FFF4DE"],
    grid: {
      borderColor: layoutProps.colorsGrayGray200,
      strokeDashArray: 4,
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
      },
    },
  };
  return options;
};
