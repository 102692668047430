import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { useSelector, shallowEqual } from "react-redux";
import config from "../../../config/config";
import Skeleton from "@material-ui/lab/Skeleton";
import Calendar from "react-select-date";

export const CreaDisponibilita = () => {
  const history = useHistory();

  const [loading, setLoading] = useState(false);
  const [services, setServices] = useState([]);
  const [sites, setSites] = useState([]);
  const [sitesLoading, setSitesLoading] = useState(false);
  const [selectedService, setSelectedService] = useState(0);
  const [selectedDate, setSelectedDate] = useState([]);
  const [errorDate, setErrorDate] = useState(false);
  const [message] = useState("Selezionare almeno una data");

  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );

  const user = useSelector((state) => state.auth.user, shallowEqual);

  const requestOptions = {
    headers: {
      Authorization:
        "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
    },
    method: "POST",
    body: JSON.stringify({
      idOrganization: organization.id,
      accessToken: user.authToken,
    }),
  };

  const GetMedicalServices = () => {
    setLoading(true);
    fetch(config.apiUrl + "organization/GetMedicalServices.php", requestOptions)
      .then((response) => response.json())
      .then((data) => {
        setServices(data);
      })
      .then(() => setLoading(false));
  };

  const GetSitesForService = () => {
    setSitesLoading(true);
    const options = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        selectedService: formik.values.tipoPrestazione,
        accessToken: user.authToken,
      }),
    };
    fetch(config.apiUrl + "organization/GetSitesForService.php", options)
      .then((response) => response.json())
      .then((data) => setSites(data.result))
      .then(() => setSitesLoading(false));
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const schema = Yup.object({
    tipoPrestazione: Yup.number("Selezionare un tipo prestazione")
      .required("Selezionare un tipo prestazione")
      .positive("Selezionare tipo prestazione"),
    sede: Yup.string("Selezionare una sede").notRequired(),
    startTime: Yup.string(
      "Selezionare un orario di inizio disponibilità"
    ).required("Selezionare un orario di inizio disponibilità"),
    endTime: Yup.string("Selezionare un orario di fine disponibilità").required(
      "Selezionare un orario di fine disponibilità"
    ),
  });

  const formik = useFormik({
    initialValues: {
      tipoPrestazione: parseInt(selectedService),
      sede: "",
      startTime: "",
      endTime: "",
    },
    validationSchema: schema,
    onSubmit: (values) => {
      if (selectedDate.length > 0) {
        const options = {
          headers: {
            Authorization:
              "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
          },
          method: "POST",
          body: JSON.stringify({
            idUser: user.id,
            idOrganization: organization.id,
            tipoPrestazione: values.tipoPrestazione,
            sede: values.sede,
            startTime: values.startTime,
            endTime: values.endTime,
            date: selectedDate,
          }),
        };
        fetch(
          config.apiUrl + "organization/AddAvailability.php",
          options
        ).then(() => ReturnToAvailabilitySummary());
      } else {
        setErrorDate(true);
      }
    },
  });

  const ReturnToAvailabilitySummary = () => {
    history.push(`/organizzazione/pannello-controllo/gestisci-disponibilita/`, {
      from: "pannello-controllo",
    });
  };

  useEffect(() => {
    GetMedicalServices();
    /*eslint-disable-next-line*/
  }, []);

  useEffect(() => {
    if (parseInt(formik.values.tipoPrestazione) !== 0) {
      GetSitesForService();
    }
    /*eslint-disable-next-line*/
  }, [formik.values.tipoPrestazione]);

  return (
    <>
      <Card>
        <CardHeader title="Gestisci la tua disponibilità">
          <CardHeaderToolbar>
            <button
              type="button"
              onClick={ReturnToAvailabilitySummary}
              className="btn btn-light"
            >
              <i className="fa fa-arrow-left"></i>
              Disponibilità
            </button>
            <button onClick={formik.resetForm} className="btn btn-light ml-2">
              <i className="fa fa-redo"></i>
              Reset
            </button>
            {`  `}
            <button
              type="submit"
              className="btn btn-primary ml-2"
              onClick={formik.handleSubmit}
            >
              Salva
            </button>
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          <Form className="row">
            <Form.Group className="col-6">
              {!loading ? (
                <>
                  {" "}
                  <Form.Label className="col-12">Tipo prestazione</Form.Label>
                  <select
                    className={`form-control ${getInputClasses(
                      "tipoPrestazione"
                    )}`}
                    onChange={(e) => setSelectedService(e.target.value)}
                    {...formik.getFieldProps("tipoPrestazione")}
                  >
                    <option value={0}>Seleziona una prestazione</option>
                    {services.map((e, key) => {
                      return (
                        <option key={key} value={e.actions.id}>
                          {e.name}
                        </option>
                      );
                    })}
                  </select>
                  {formik.touched.tipoPrestazione &&
                  formik.errors.tipoPrestazione ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.tipoPrestazione}
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                <Skeleton />
              )}
            </Form.Group>
            <Form.Group className="col-6">
              {!loading ? (
                <>
                  {" "}
                  <Form.Label className="col-12">Sede disponibilità</Form.Label>
                  <select
                    disabled={
                      parseInt(formik.values.tipoPrestazione) !== 0
                        ? false
                        : true
                    }
                    className={`form-control ${getInputClasses("sede")}`}
                    name="sede"
                    value={formik.values.sede}
                    onChange={formik.handleChange}
                  >
                    {parseInt(formik.values.tipoPrestazione) === 0 ? (
                      <option value={0}>
                        Selezionare prima una prestazione
                      </option>
                    ) : null}
                    <option value={0}>Selezionare una sede</option>
                    {parseInt(formik.values.tipoPrestazione) !== 0 &&
                    sites.length > 0 &&
                    !sitesLoading
                      ? sites.map((e, key) => (
                          <option key={key} value={e.id}>
                            {e.name}
                          </option>
                        ))
                      : null}
                  </select>
                  {formik.touched.sede && formik.errors.sede ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">{formik.errors.sede}</div>
                    </div>
                  ) : null}
                </>
              ) : (
                <Skeleton />
              )}
            </Form.Group>
            <h5 className="font-weight-bold my-6 col-12">
              Orari disponibilità
            </h5>
            <Form.Group className="col-6">
              {!loading ? (
                <>
                  {" "}
                  <Form.Label className="col-12">Inizio</Form.Label>
                  <Form.Control
                    className={getInputClasses("startTime")}
                    value={formik.values.startTime}
                    name="startTime"
                    {...formik.getFieldProps("startTime")}
                    type="time"
                  />
                  {formik.touched.startTime && formik.errors.startTime ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.startTime}
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                <Skeleton />
              )}
            </Form.Group>
            <Form.Group className="col-6">
              {!loading ? (
                <>
                  {" "}
                  <Form.Label className="col-12">Fine</Form.Label>
                  <Form.Control
                    className={getInputClasses("endTime")}
                    value={formik.values.endTime}
                    {...formik.getFieldProps("endTime")}
                    name="endTime"
                    type="time"
                  />
                  {formik.touched.endTime && formik.errors.endTime ? (
                    <div className="fv-plugins-message-container">
                      <div className="fv-help-block">
                        {formik.errors.endTime}
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                <Skeleton />
              )}
            </Form.Group>
            <h5 className="font-weight-bold my-6 col-12">Date disponibilità</h5>
            <div className="col-12 d-flex justify-content-center">
              <Calendar
                onSelect={(date) => setSelectedDate(date)}
                selectDateType="multiple"
              />
            </div>
            {errorDate ? (
              <div className="col-12">
                <p style={{ color: "red" }}>{message}</p>
              </div>
            ) : null}
          </Form>
        </CardBody>
      </Card>
    </>
  );
};
