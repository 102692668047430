import React, { Suspense, lazy } from "react";
import { Redirect, Switch, Route } from "react-router-dom";
import { LayoutSplashScreen, ContentRoute } from "../_metronic/layout";
import { BuilderPage } from "./pages/BuilderPage";
import { Calendario } from "./pages/Calendario";
import { Prenotazioni } from "./pages/Prenotazioni";
import { DashboardPage } from "./pages/DashboardPage";
import { CreateOrganization } from "./pages/CreateOrganization";
import { PannelloControllo } from "./pages/PannelloControllo";
import { OrganizationPage } from "./pages/OrganizationPage";
import { Documenti } from "./pages/Documenti";
import { Membri } from "./pages/Membri";
import { DashboardAzienda } from "../_metronic/_partials/dashboards/DashboardAzienda";
import { VideoConference } from "./pages/VideoConference";
import { CartellaDigitale } from "./pages/CartellaDigitale";
import { RichiestaCartellaDigitale } from "./pages/RichiestaCartellaDigitale";
import NotificationInit from "./modules/NotificationRichieste/NotificationInit";
import { Test } from "./pages/test";

const GoogleMaterialPage = lazy(() =>
  import("./modules/GoogleMaterialExamples/GoogleMaterialPage")
);
const ReactBootstrapPage = lazy(() =>
  import("./modules/ReactBootstrapExamples/ReactBootstrapPage")
);
const ECommercePage = lazy(() =>
  import("./modules/ECommerce/pages/eCommercePage")
);
const UserProfilepage = lazy(() =>
  import("./modules/UserProfile/UserProfilePage")
);

export default function BasePage() {
  // useEffect(() => {
  //   console.log('Base page');
  // }, []) // [] - is required if you need only one call
  // https://reactjs.org/docs/hooks-reference.html#useeffect

  return (
    <Suspense fallback={<LayoutSplashScreen />}>
      <NotificationInit />
        <Switch>
          {
            /* Redirect from root URL to /dashboard. */
            <Redirect exact from="/" to="/dashboard" />
          }
          <ContentRoute path="/dashboard" component={DashboardPage} />
          <ContentRoute path="/builder" component={BuilderPage} />
          <ContentRoute path="/calendario" component={Calendario} />
          <ContentRoute
            path="/meet/:room/:username/:password/:idDestinatario/:idMeet"
            component={VideoConference}
          />
          <ContentRoute
            path="/organizzazione/calendario/"
            component={Calendario}
          />
          <ContentRoute path="/TestPage" component={Test} />
          <ContentRoute
            path="/cartella-digitale"
            component={CartellaDigitale}
          />
          <ContentRoute
            path="/richiesta-cartella-digitale"
            component={RichiestaCartellaDigitale}
          />
          <ContentRoute
            path="/organizzazione/info/"
            component={OrganizationPage}
          />
          <ContentRoute path="/prenotazioni/" component={Prenotazioni} />
          <ContentRoute
            path="/organizzazione/prenotazioni/"
            component={Prenotazioni}
          />
          <ContentRoute
            path="/organizzazione/dashboard/"
            component={DashboardAzienda}
          />
          <ContentRoute
            path="/create-organization"
            component={CreateOrganization}
          />
          <ContentRoute
            path="/organizzazione/pannello-controllo/"
            component={PannelloControllo}
          />
          <ContentRoute path="/documenti" component={Documenti} />
          <ContentRoute path="/organizzazione/membri/" component={Membri} />
          <Route path="/google-material" component={GoogleMaterialPage} />
          <Route path="/react-bootstrap" component={ReactBootstrapPage} />
          <Route path="/e-commerce" component={ECommercePage} />
          <Route path="/user-profile" component={UserProfilepage} />
          <Redirect to="error/error-v1" />
        </Switch>
    </Suspense>
  );
}
