import React, { useState, useEffect } from "react";
import { Modal, Button } from "react-bootstrap";
import { Skeleton } from "@material-ui/lab";
import config, { roles } from "../../../../../config/config";
import { useHistory } from "react-router-dom";

export const ModalDetailMeet = (props) => {
  const history = useHistory();
  const [meet, setMeet] = useState({});
  const [loading, setLoading] = useState(false);

  const GetDetailMeet = () => {
    setLoading(true);
    const options = {
      headers: {
        Authorization: config.keyApi,
      },
      method: "POST",
      body: JSON.stringify({
        idMeet: props.idMeet,
        password: props.password,
        idRole: props.idRole,
      }),
    };
    fetch(config.apiUrl + "calendar/GetDetailMeet.php", options)
      .then((response) => response.json())
      .then((data) => setMeet(data))
      .then(() => setLoading(false));
  };

  useEffect(() => {
    if (props.idMeet > 0 && props.password !== "") {
      GetDetailMeet();
    }
    /*eslint-disable-next-line*/
  }, [props.show]);

  return (
    <Modal show={props.show} onHide={props.close}>
      <Modal.Header>
        <Modal.Title>Teleconsulto:</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {!loading && meet !== undefined && Object.keys(meet).length > 0 ? (
          <>
            Paziente:{" "}
            {parseInt(props.idRole) === roles.patient
              ? props.firstname + " " + props.lastname
              : meet.firstname + " " + meet.lastname}
            <br /> Medico:{" "}
            {parseInt(props.idRole) === roles.doctor
              ? props.firstname + " " + props.lastname
              : meet.firstname + " " + meet.lastname}
            <br /> Data: {props.dateAppointment}
            <br />
          </>
        ) : (
          <>
            <div className="row">
              <div className="col-12">
                <Skeleton />
              </div>
              <div className="col-12">
                <Skeleton />
              </div>
              <div className="col-12">
                <Skeleton />
              </div>
              <div className="col-12">
                <Skeleton />
              </div>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.close}>Chiudi</Button>
        <Button
          onClick={() =>
            history.push(
              `/meet/${props.password}/${
                props.firstname + "-" + props.lastname
              }/${props.password}/${
                parseInt(props.idRole) === roles.doctor
                  ? meet.idPaziente
                  : meet.idMedico
              }/${props.idMeet}/`
            )
          }
          disabled={
            !loading && meet !== undefined && Object.keys(meet).length > 0
              ? false
              : true
          }
        >
          Vai al meet
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
