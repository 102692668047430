import React, { useState } from "react";
import { Modal, Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import config, { roles } from "../../../../../config/config";
import moment from "moment-timezone";

export const ModalAppointment = (props) => {
  const [loading, setLoading] = useState(false);
  const CreaTeleconsulto = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Decidi se eseguire la visita tramite teleconsulto;
      <br /> automaticamente verrà creata una videochiamata alla data
      dell'appuntamento.
    </Tooltip>
  );

  const AnnullaTeleconsulto = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Elimina un teleconsulto precedentemente creato
    </Tooltip>
  );

  const NoVideoConference = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Questo servizio non prevede teleconsulto
    </Tooltip>
  );

  const NoMeet = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Questo servizio verrà eseguito in presenza
    </Tooltip>
  );

  const Meet = (props) => (
    <Tooltip id="button-tooltip" {...props}>
      Per questo servizio è stato creato un teleconsulto
    </Tooltip>
  );

  const createMeet = () => {
    setLoading(true);
    const options = {
      headers: {
        Authorization: config.keyApi,
      },
      method: "POST",
      body: JSON.stringify({
        idMedic: props.user.id,
        idAppointment: parseInt(props.appointment.idAppointment),
        idPaziente: props.appointment.idPaziente,
        emailMedic: props.user.email,
        medicName: props.user.firstname,
        medicLastname: props.user.lastname,
        patName: props.appointment.patient,
        start: moment
          .tz(props.appointment.dateAppointment, "Europe/Rome")
          .format("DD MMMM YYYY, HH:mm"),
      }),
    };
    fetch(config.apiUrl + "calendar/AddMeet.php", options)
      .then(() => setLoading(false))
      .then(() => props.setShow(false))
      .then(() => props.setCount(props.count + 1));
  };

  const DeleteMeet = () => {
    setLoading(true);
    const options = {
      headers: {
        Authorization: config.keyApi,
      },
      method: "POST",
      body: JSON.stringify({
        idMedic: props.user.id,
        idAppointment: parseInt(props.appointment.idAppointment),
        idPaziente: props.appointment.idPaziente,
        emailMedic: props.user.email,
        medicName: props.user.firstname,
        medicLastname: props.user.lastname,
        patName: props.appointment.patient,
        start: moment
          .tz(props.appointment.dateAppointment, "Europe/Rome")
          .format("DD MMMM YYYY, HH:mm"),
      }),
    };
    fetch(config.apiUrl + "calendar/DeleteMeet.php", options)
      .then(() => setLoading(false))
      .then(() => props.setShow(false))
      .then(() => props.setCount(props.count + 1));
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header>
        <Modal.Title>
          {props.appointment.title}: {props.appointment.start}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="row">
          <div className="col-8 float-left">
            Inizio visita: {props.appointment.start} <br />
            Fine visita: {props.appointment.end} <br />
            Paziente: {props.appointment.patient} <br />
            Sede: {props.appointment.sede}
            {props.appointment.note !== false
              ? <br /> + props.appointment.note
              : null}
          </div>
          {props.appointment.videoconference ? (
            props.appointment.meet ? (
              <div className="col-4 float-right">
                <OverlayTrigger
                  placement="right"
                  overlay={Meet}
                  trigger={["focus", "hover"]}
                >
                  <i
                    style={{ color: "green" }}
                    className="fa-solid fa-video float-right"
                  ></i>
                </OverlayTrigger>
              </div>
            ) : (
              <div className="col-4 float-right">
                <OverlayTrigger
                  placement="right"
                  overlay={NoMeet}
                  trigger={["focus", "hover"]}
                >
                  <i className="fa-solid fa-video float-right"></i>
                </OverlayTrigger>
              </div>
            )
          ) : (
            <div className="col-4 float-right">
              <OverlayTrigger
                placement="right"
                overlay={NoVideoConference}
                trigger={["focus", "hover"]}
              >
                <i className="fa-solid fa-video-slash float-right"></i>
              </OverlayTrigger>
            </div>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer>
        {props.appointment.videoconference &&
        !props.appointment.meet &&
        parseInt(props.user.id) === parseInt(props.appointment.idMedico) ? (
          <OverlayTrigger
            placement="top"
            overlay={CreaTeleconsulto}
            trigger={["focus", "hover"]}
          >
            <Button disabled={loading} onClick={createMeet} variant="info">
              Crea teleconsulto
            </Button>
          </OverlayTrigger>
        ) : null}
        {props.appointment.videoconference &&
        props.appointment.meet &&
        parseInt(props.user.id) === parseInt(props.appointment.idMedico) ? (
          <OverlayTrigger
            placement="top"
            overlay={AnnullaTeleconsulto}
            trigger={["focus", "hover"]}
          >
            <Button disabled={loading} onClick={DeleteMeet} variant="warning">
              Annulla teleconsulto
            </Button>
          </OverlayTrigger>
        ) : null}
        <Button onClick={() => props.setShow(false)}>Chiudi</Button>
      </Modal.Footer>
    </Modal>
  );
};
