import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import * as PropTypes from "prop-types";
import {
  SortingState,
  IntegratedSorting,
  PagingState,
  IntegratedPaging,
  FilteringState,
  IntegratedFiltering,
  DataTypeProvider,
} from "@devexpress/dx-react-grid";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  TableFilterRow,
} from "@devexpress/dx-react-grid-bootstrap4";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import Skeleton from "@material-ui/lab/Skeleton";
import config from "../../../config/config";
import { EditModal } from "../../../_metronic/_partials/components/modals/modalsDisponibilita/EditModal";
import { DeleteModal } from "../../../_metronic/_partials/components/modals/modalsDisponibilita/DeleteModal";

export const GestisciDisponibilita = () => {
  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );

  const user = useSelector((state) => state.auth.user, shallowEqual);

  const [idAvailability, setIdAvailability] = useState(0);
  const [nameAvailability, setNameAvailability] = useState("");

  const GetAvailability = () => {
    setLoading(true);
    const RequestOptions = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: organization.id,
        idUser: user.id,
      }),
    };
    fetch(config.apiUrl + "organization/GetAvailability.php", RequestOptions)
      .then((response) => response.json())
      .then((data) => setRows(data))
      .then(() => setLoading(false));
  };

  const TableComponent = ({ ...restProps }) => (
    <Table.Table
      {...restProps}
      className="table table-hover table-head-custom table-vertical-center overflow-hidden"
    />
  );

  const editColum = ({ value }) => (
    <>
      <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">Modifica</Tooltip>}
      >
        {/*eslint-disable-next-line*/}
        <a
          onClick={() => handleOpenEdit(value.idAvailability)}
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>

      <> </>
      <OverlayTrigger
        overlay={<Tooltip id="products-delete-tooltip">Elimina</Tooltip>}
      >
        {/*eslint-disable-next-line*/}
        <a
          onClick={() =>
            handleOpenDelete(value.idAvailability, value.prestazione)
          }
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </OverlayTrigger>
    </>
  );

  const ButtonColumn = (props) => (
    <DataTypeProvider formatterComponent={editColum} {...props} />
  );

  const [columns] = useState([
    { name: "prestazione", title: "Prestazione" },
    { name: "startTime", title: "Inizio" },
    { name: "endTime", title: "Fine" },
    { name: "date", title: "Data" },
    { name: "sede", title: "Sede" },
    { name: "actions", title: "Azioni" },
  ]);

  const [rows, setRows] = useState([]);
  const [loading, setLoading] = useState(false);

  const [actionsColumn] = useState(["actions"]);

  const [sorting, setSorting] = useState([
    { columnName: "id", direction: "desc" },
  ]);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 20, 30, 0]);

  const [showEdit, setShowEdit] = useState(false);
  const handleOpenEdit = (id) => {
    setIdAvailability(id);
    setShowEdit(true);
  };
  const handleCloseEdit = () => {
    setIdAvailability(0);
    setShowEdit(false);
  };

  const [showDelete, setShowDelete] = useState(false);
  const handleOpenDelete = (id, name) => {
    setIdAvailability(id);
    setNameAvailability(name);
    setShowDelete(true);
  };
  const handleCloseDelete = () => {
    setIdAvailability(0);
    setNameAvailability("");
    setShowDelete(false);
  };

  const ActionsFilterCell = ({ filter, onFilter }) => (
    <th style={{ fontWeight: "normal" }}>
      <input disabled style={{ visibility: "hidden" }} />
    </th>
  );
  ActionsFilterCell.propTypes = {
    filter: PropTypes.shape({
      value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
        .isRequired,
    }),
    onFilter: PropTypes.func.isRequired,
  };
  ActionsFilterCell.defaultProps = {
    filter: null,
  };

  const FilterCell = (props) => {
    const { column } = props;
    if (column.name === "actions") {
      return <ActionsFilterCell {...props} />;
    }
    return <TableFilterRow.Cell {...props} />;
  };
  FilterCell.propTypes = {
    column: PropTypes.shape({ name: PropTypes.string }).isRequired,
  };

  const tableMessages = {
    noData: "Nessuna prestazione futura",
  };
  const filterRowMessages = {
    filterPlaceholder: "Filtra...",
  };

  const pagingPanelMessages = {
    showAll: "Tutte",
    rowsPerPage: "Righe per pagina",
    info: "Da {from} a {to} ({count} richieste)",
  };

  const [count, setCount] = useState(0);

  useEffect(() => {
    GetAvailability();
    /*eslint-disable-next-line*/
  }, [count]);

  return (
    <>
      <EditModal
        idAvailability={parseInt(idAvailability)}
        show={showEdit}
        closeEdit={handleCloseEdit}
        count={count}
        setCount={setCount}
      />
      <DeleteModal
        idAvailability={parseInt(idAvailability)}
        show={showDelete}
        name={nameAvailability}
        closeDelete={handleCloseDelete}
        count={count}
        setCount={setCount}
      />
      {!loading ? (
        <Card className="tab-content table-responsive px-2 py-2">
          <Grid rows={rows} columns={columns}>
            <ButtonColumn for={actionsColumn} />
            <FilteringState />
            <IntegratedFiltering />
            <PagingState
              currentPage={currentPage}
              onCurrentPageChange={setCurrentPage}
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
            <IntegratedPaging />
            <SortingState sorting={sorting} onSortingChange={setSorting} />
            <IntegratedSorting />
            <Table messages={tableMessages} tableComponent={TableComponent} />
            <TableHeaderRow showSortingControls />
            <TableFilterRow
              messages={filterRowMessages}
              cellComponent={FilterCell}
            />
            <PagingPanel messages={pagingPanelMessages} pageSizes={pageSizes} />
          </Grid>
        </Card>
      ) : (
        <>
          <Skeleton variant="text" />
          <Skeleton variant="circle" width={80} height={80} />
          <Skeleton variant="rect" height={300} />
        </>
      )}
    </>
  );
};
