import React from "react";
import { useSubheader } from "../../_metronic/layout";
import { Prenota } from "../../_metronic/_partials/components/forms/Prenota";
import { NewPrenota } from "../../_metronic/_partials/components/forms/NewPrenota";

export const Prenotazioni = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Prenotazioni");

  return <NewPrenota />;
};
