import React, { useState, useEffect } from "react";
import { Card } from "react-bootstrap";
import ApexCharts from "apexcharts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faAngleDown,
  faAngleUp,
  faCircle,
} from "@fortawesome/free-solid-svg-icons";

export const Prestazioni = (props) => {
  const [render, setRender] = useState(false);
  const [isOpen, setIsOpen] = useState(false);

  /* const setBg = () => {
    const randomColor = Math.floor(Math.random() * 16777215).toString(16);

    return "#" + randomColor;
  }; */

  const DropdownCustom = () => (
    <div className="dropup float-right" onClick={() => setIsOpen(!isOpen)}>
      <button
        className="btn btn-primary"
        type="button"
        id="dropdownMenuButton"
        data-toggle="dropdown"
        aria-haspopup="true"
      >
        <div>
          Prestazioni{" "}
          <FontAwesomeIcon
            className="pl-2 pt-1"
            icon={isOpen ? faAngleDown : faAngleUp}
          />
        </div>
      </button>
      <div
        className={`dropdown-menu${isOpen ? " show" : ""}`}
        aria-labelledby="dropdownMenuButton"
      >
        <ul className="navi navi-hover p-4">
          <li className="navi-item">
            <span className="navi-icon">
              <FontAwesomeIcon size="sm" color="#ef476f" icon={faCircle} />
            </span>
            <span className="navi-text pl-1">Radiografia</span>
          </li>
          <li className="navi-item">
            <span className="navi-icon">
              <FontAwesomeIcon size="sm" color="#f78c6b" icon={faCircle} />
            </span>
            <span className="navi-text pl-1">TAC</span>
          </li>
          <li className="navi-item">
            <span className="navi-icon">
              <FontAwesomeIcon size="sm" color="#ffd166" icon={faCircle} />
            </span>
            <span className="navi-text pl-1">R Magnetica</span>
          </li>
          <li className="navi-item">
            <span className="navi-icon">
              <FontAwesomeIcon size="sm" color="#06d6a0" icon={faCircle} />
            </span>
            <span className="navi-text pl-1">Ecografia</span>
          </li>
          <li className="navi-item">
            <span className="navi-icon">
              <FontAwesomeIcon size="sm" color="#0cb0a9" icon={faCircle} />
            </span>
            <span className="navi-text pl-1">Fiseoterapia</span>
          </li>
          <li className="navi-item">
            <span className="navi-icon">
              <FontAwesomeIcon size="sm" color="#118ab2" icon={faCircle} />
            </span>
            <span className="navi-text pl-1">Laserterapia</span>
          </li>
        </ul>
      </div>
    </div>
  );

  const renderTimer = () => {
    setRender(true);
    setTimeout(function () {
      setRender(false);
    }, 300);
  };

  useEffect(() => {
    renderTimer();
    /*eslint-disable-next-line*/
  }, []);

  useEffect(() => {
    if (!render) {
      const element = document.getElementById("chart");

      if (!element) {
        return;
      }

      const options = getChartOption();
      const chart = new ApexCharts(element, options);
      chart.render();
      return function cleanUp() {
        chart.destroy();
      };
    }
  }, [render]);

  return (
    <Card className="card-custom gutter-b">
      <Card.Header className="border-0 pt-5">
        <h3 className="card-title font-weight-bolder text-black">
          Prestazioni
        </h3>
      </Card.Header>
      <Card.Body className="p-4" style={{ minHeight: "375px" }}>
        <div className="row">
          <div className="col-12">{!render ? <div id="chart" /> : null}</div>
        </div>
      </Card.Body>
      <Card.Footer className="border-0">
        <DropdownCustom />
      </Card.Footer>
    </Card>
  );
};

const getChartOption = () => {
  var options1 = {
    chart: {
      type: "donut",
      size: 200,
    },
    legend: {
      show: false,
    },
    theme: {
      palette: "palette1",
    },
    responsive: [
      {
        breakpoint: 10,
      },
    ],
    series: [67, 130, 97, 61, 61, 61],
    plotOptions: {
      pie: {
        expandOnClick: true,
      },
    },
    colors: [
      "#EF476F",
      "#F78C6B",
      "#FFD166",
      "#06D6A0",
      "#0CB0A9",
      "#118AB2",
      "#073B4C",
      "#FFF4DE",
    ],
    labels: [
      "RADIOGRAFIA",
      "TAC",
      "RISONANZA MAGNETICA",
      "ECOGRAFIA",
      "FISIOTERAPIA",
      "LASERTERAPIA",
      "POSTUROLOGIA",
    ],
  };
  return options1;
};
