/* eslint-disable no-restricted-imports */
/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useEffect, useState, useMemo, useCallback } from "react";
import { SearchToolbar } from "../../../_partials/components/utils/SearchToolbar";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import objectPath from "object-path";
import { useHistory } from "react-router-dom";
import config, { roles } from "../../../../config/config";
import { useSubheader } from "../../_core/MetronicSubheader";
import { useHtmlClassService } from "../../_core/MetronicLayout";
import { makeStyles } from "@material-ui/core/styles";
//import Avatar from "@material-ui/core/Avatar";
import { Menu } from "@material-ui/core";
import MenuItem from "@material-ui/core/MenuItem";
import { Button } from "@material-ui/core";
import * as authOrganization from "../../../../../src/app/modules/AuthOrganization/authRedux";
import useWebSocket, { ReadyState } from "react-use-websocket";

const useStyles = makeStyles((theme) => ({
  root: {
    display: "block",
    "& > *": {
      margin: theme.spacing(1),
    },
  },
}));

export function SubHeader() {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );

  const dispatch = useDispatch();
  const uiService = useHtmlClassService();
  const location = useLocation();
  const subheader = useSubheader();
  const history = useHistory();
  const classes = useStyles();

  const [info, setInfo] = useState([]);
  const [anchorEl, setAnchorEl] = useState(null);
  const [organizations, setOrganizations] = useState([]);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const GetOrganizationsSearch = () => {
    const options = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: subheader.id,
        nameOrganization: subheader.nameOrganization,
      }),
    };
    fetch(config.apiUrl + "organization/GetOrganizationsSearch.php", options)
      .then((response) => response.json())
      .then((data) => setOrganizations(data));
  };

  const GetPageInfo = () => {
    const options = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: organization.id,
        nameOrganization: organization.name,
        idUser: user.id,
      }),
    };
    fetch(config.apiUrl + "organization/GetPageInfo.php", options)
      .then((response) => response.json())
      .then((data) => {
        setInfo(data);
        user["status"] = data.status;
        user["isMember"] = data.isMember;
      });
  };

  const layoutProps = useMemo(() => {
    return {
      config: uiService.config,
      subheaderFixed: objectPath.get(uiService.config, "subheader.fixed"),
      subheaderMobileToggle: objectPath.get(
        uiService.config,
        "subheader.mobile-toggle"
      ),
      subheaderCssClasses: uiService.getClasses("subheader", true),
      subheaderContainerCssClasses: uiService.getClasses(
        "subheader_container",
        true
      ),
    };
  }, [uiService]);

  /* useLayoutEffect(() => {
    const aside = getBreadcrumbsAndTitle("kt_aside_menu", location.pathname);
    const header = getBreadcrumbsAndTitle("kt_header_menu", location.pathname);
    const breadcrumbs =
      aside && aside.breadcrumbs.length > 0
        ? aside.breadcrumbs
        : header.breadcrumbs;
    subheader.setBreadcrumbs(breadcrumbs);
    subheader.setTitle(
      aside && aside.title && aside.title.length > 0
        ? aside.title
        : header.title
    );
    // eslint-disable-next-line
  }, [location.pathname]); */

  useEffect(() => {
    if (location.pathname.includes("pannello-controllo")) {
      document.body.classList.remove("aside-minimize");
      subheader.setBreadcrumbs([]);
      subheader.setTitle("");
    } else {
      document.body.classList.add("aside-minimize");
    }
    /*eslint-disable-next-line*/
  }, [location]);

  // Do not remove this useEffect, need from update title/breadcrumbs outside (from the page)
  useEffect(() => {
    if (location.pathname.includes("organizzazione")) {
      GetPageInfo();
    } else {
      dispatch(authOrganization.actions.setOrganization(undefined));
      GetOrganizationsSearch();
    }
    /*eslint-disable-next-line*/
  }, [subheader]);

  return (
    <>
      {/* <div >
        
        <Navbar style={{ borderLeft: "white solid 1px" }}>
          {window.location.href.indexOf("pagina-organizzazione") > -1 ? (
            <Nav className="mr-auto pl-4">
              <Nav.Link className="text-light" href="#features">
                Impostazioni
              </Nav.Link>
              <Nav.Link className="text-light" href="#pricing">
                Personale
              </Nav.Link>
              <Nav.Link className="text-light" href="#pricing">
                Prenotazioni
              </Nav.Link>
            </Nav>
          ) : (
            <Nav className="mr-auto pl-4"></Nav>
          )}
          <Dropdown className="py-2 px-3" color="dark">
            <Dropdown.Toggle as={CustomToggle} id="dropdown-custom-components">
              Mie organizzazioni
            </Dropdown.Toggle>
            <Dropdown.Menu>
              {organizations.length > 0 ? (
                organizations.map((e, key) => (
                  <NavLink
                    to={`/pagina-organizzazione/${e.id}/${e.slug}`}
                    key={key}
                  >
                    <div className={classes.root}>
                      <Avatar
                        alt={e.firstLetter}
                        src={toAbsoluteUrl(e.profilePic)}
                      />{" "}
                      <p className="py-3">{e.name}</p>
                    </div>
                  </NavLink>
                ))
              ) : (
                <Dropdown.Item>Nessuna organizzazione salvata</Dropdown.Item>
              )}
            </Dropdown.Menu>
          </Dropdown>
          <Form inline>
            <Form.Control
              type="select"
              placeholder="Cerca organizzazione..."
              className="mr-sm-2"
            />
          </Form>
        </Navbar>
      </div> */}
      <div
        id="kt_subheader"
        className={`subheader py-3 py-lg-8 ${layoutProps.subheaderCssClasses}`}
      >
        <div
          className={`${layoutProps.subheaderContainerCssClasses} d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap`}
        >
          {/* Info */}
          <div className="d-flex align-items-center flex-wrap mr-1">
            {/* begin::Mobile Toggle */}
            {layoutProps.subheaderMobileToggle && (
              <button
                className="burger-icon burger-icon-left mr-4 d-inline-block d-lg-none"
                id="kt_subheader_mobile_toggle"
              >
                <span />
              </button>
            )}
            {/* end::Mobile Toggle */}

            {/* begin::Heading */}
            <div className="d-flex align-items-baseline mr-5">
              {/* begin::Title */}
              {
                <h6 className="subheader-title text-dark pt-2">
                  {subheader.title}
                </h6>
              }
              {/* end::Title */}

              {/* <BreadCrumbs items={subheader.breadcrumbs} /> */}
            </div>
            {/* end::Heading */}
          </div>
          {/* Info */}
          {window.location.href.indexOf("organizzazione") > -1 ? (
            <div>
              <div>
                <div>
                  <Button
                    className="p-0"
                    aria-controls="simple-menu"
                    aria-haspopup="true"
                    onClick={handleClick}
                  >
                    <div className={classes.root}>
                      <p className="py-3">{info.name}</p>
                    </div>
                  </Button>
                  <Menu
                    id="simple-menu"
                    anchorEl={anchorEl}
                    keepMounted
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                  >
                    {user.idRole === roles.doctor ? (
                      <MenuItem
                        onClick={() => {
                          history.push("/organizzazione/pannello-controllo");
                          setAnchorEl(null);
                        }}
                      >
                        Impostazioni
                      </MenuItem>
                    ) : null}
                    <MenuItem
                      onClick={() => {
                        history.push(`/dashboard`);
                        dispatch(
                          authOrganization.actions.setOrganization(undefined)
                        );
                        setAnchorEl(null);
                      }}
                    >
                      Logout azienda
                    </MenuItem>
                  </Menu>
                </div>
              </div>
            </div>
          ) : (
            <div className="col-3">
              <SearchToolbar organizations={organizations} />
            </div>
          )}
        </div>
      </div>
    </>
  );
}
