import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import { useSelector, shallowEqual } from "react-redux";
import * as Yup from "yup";
import { Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import Select from "react-select";
import config from "../../../config/config";
import { useHistory } from "react-router-dom";

const departmentsTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Devi creare almeno un reparto per poter utilizzare questo componente
  </Tooltip>
);

const sitesTooltip = (props) => (
  <Tooltip id="button-tooltip" {...props}>
    Devi creare almeno una sede per poter utilizzare questo componente
  </Tooltip>
);

export const CreaPrestazione = () => {
  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );

  const history = useHistory();

  const [sites, setSites] = useState([]);
  const [departments, setDepartments] = useState([]);

  const [selectedSites, setSelectedSites] = useState([]);
  const [selectedDepartment, setSelectedDepartment] = useState("");

  const requestOptions = {
    headers: {
      Authorization:
        "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
    },
    method: "POST",
    body: JSON.stringify({
      idOrganization: organization.id,
    }),
  };

  const GetSites = () => {
    fetch(config.apiUrl + "organization/GetSitesSelect.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setSites(data));
  };

  const GetDepartments = () => {
    fetch(
      config.apiUrl + "organization/GetDepartmentsSelect.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => setDepartments(data));
  };

  useEffect(() => {
    GetSites();
    GetDepartments();
    /*eslint-disable-next-line*/
  }, []);

  const ReturnToSiteSummary = () => {
    history.push(`/organizzazione/pannello-controllo/gestisci-prestazioni/`, {
      from: "pannello-controllo",
    });
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const schema = Yup.object({
    name: Yup.string("Inserire un nome valido")
      .required("Inserire uno nome valido")
      .min(3, "Minimo 3 caratteri")
      .max(100, "Massimo 100 caratteri"),
    duration: Yup.number("Inserire una durata valida")
      .required("Inserire una durata valida")
      .positive("La durata non può essere un numero negativo")
      .integer("Numeri decimali non ammessi"),
    expireDate: Yup.number("Inserire entro quanti giorni può essere annullato")
      .required("Inserire entro quanti giorni può essere annullato")
      .positive("La scadenza non può essere un numero negativo")
      .integer("Numeri decimali non ammessi"),
    description: Yup.string("Inserire una descrizione valida")
      .notRequired()
      .min(3, "Minimo 3 caratteri")
      .max(255, "Massimo 255 caratteri"),
  });

  const formik = useFormik({
    initialValues: {
      name: "",
      description: "",
      duration: "",
      videoconference: false,
      deletable: false,
      expireDate: 1,
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const requestOptions = {
        headers: {
          Authorization:
            "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
        },
        method: "POST",
        body: JSON.stringify({
          name: values.name,
          description: values.description,
          duration: values.duration,
          videoconference: values.videoconference,
          deletable: values.deletable,
          expireDate: values.expireDate,
          selectedDepartment: selectedDepartment,
          selectedSites: selectedSites,
          idOrganization: organization.id,
        }),
      };

      fetch(
        config.apiUrl + "organization/CreateMedicalService.php",
        requestOptions
      )
        .then(() => formik.resetForm())
        .then(() => ReturnToSiteSummary());
    },
  });

  return (
    <Card>
      <CardHeader title="Crea prestazione">
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={ReturnToSiteSummary}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Prestazioni
          </button>
          <button onClick={formik.resetForm} className="btn btn-light ml-2">
            <i className="fa fa-redo"></i>
            Reset
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={formik.handleSubmit}
          >
            Salva
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Form className="row">
          <Form.Group className="col-6">
            <Form.Label className="col-12">Nome prestazione</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome..."
              value={formik.values.name}
              onChange={formik.handleChange}
              className={getInputClasses("name")}
              {...formik.getFieldProps("name")}
              name="name"
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.name}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-6">
            {departments.length > 0 ? (
              <>
                <Form.Label className="col-12">Seleziona reparto</Form.Label>
                <Select
                  defaultValue={selectedDepartment}
                  onChange={(e) => setSelectedDepartment(e)}
                  options={departments}
                  name="department"
                  placeholder="Seleziona dipartimento..."
                />
              </>
            ) : (
              <>
                <Form.Label className="col-12">
                  Seleziona reparto
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={departmentsTooltip}
                  >
                    <i className="far fa-question-circle fa-sm pl-2"></i>
                  </OverlayTrigger>
                </Form.Label>
                <Select
                  defaultValue={selectedDepartment}
                  onChange={(e) => setSelectedDepartment(e)}
                  options={departments}
                  name="department"
                  placeholder="Seleziona dipartimento..."
                  isDisabled={true}
                />
              </>
            )}
          </Form.Group>
          <Form.Group className="col-6">
            {sites.length > 0 ? (
              <>
                <Form.Label className="col-12">Seleziona sedi</Form.Label>
                <Select
                  defaultValue={selectedSites}
                  isMulti
                  isClearable={true}
                  onChange={(e) => {
                    setSelectedSites(e);
                  }}
                  options={sites}
                  name="site"
                  placeholder="Seleziona sedi..."
                />
              </>
            ) : (
              <>
                <Form.Label className="col-12">
                  Seleziona sedi
                  <OverlayTrigger
                    placement="bottom"
                    delay={{ show: 250, hide: 400 }}
                    overlay={sitesTooltip}
                  >
                    <i className="far fa-question-circle fa-sm pl-2"></i>
                  </OverlayTrigger>
                </Form.Label>
                <Select
                  options={sites}
                  name="site"
                  placeholder="Seleziona sedi..."
                  isDisabled={true}
                />
              </>
            )}
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label className="col-12">
              Durata prestazione (minuti)
            </Form.Label>
            <Form.Control
              type="number"
              placeholder="Durata in minuti"
              value={formik.values.duration}
              name="duration"
              onChange={formik.handleChange}
              className={getInputClasses("duration")}
              {...formik.getFieldProps("duration")}
            />
            {formik.touched.duration && formik.errors.duration ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.duration}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label className="col-12">
              Può essere eseguita tramite teleconsulto
            </Form.Label>
            <label className="checkbox pl-4 pt-4">
              <input
                type="checkbox"
                color="primary"
                className="checkbox-inline"
                checked={formik.values.videoconference}
                onChange={formik.handleChange}
                name="videoconference"
              />
              <span style={{ marginRight: "5px" }}></span>
              {formik.values.videoconference ? "SI" : "NO"}
            </label>
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label className="col-12">
              La prestazione è annullabile
            </Form.Label>
            <label className="checkbox pl-4 pt-4">
              <input
                type="checkbox"
                color="primary"
                className="checkbox-inline"
                checked={formik.values.deletable}
                onChange={formik.handleChange}
                name="deletable"
              />
              <span style={{ marginRight: "5px" }}></span>
              {formik.values.deletable ? "SI" : "NO"}
            </label>
          </Form.Group>
          {formik.values.deletable ? (
            <Form.Group className="col-6">
              <Form.Label className="col-12">
                Entro quanti giorni può essere annullata
              </Form.Label>
              <Form.Control
                type="text"
                placeholder="Giorni..."
                value={formik.values.expireDate}
                disabled={!formik.values.deletable ? true : false}
                onChange={formik.handleChange}
                className={getInputClasses("expireDate")}
                {...formik.getFieldProps("expireDate")}
                name="expireDate"
              />
              {formik.touched.expireDate && formik.errors.expireDate ? (
                <div className="fv-plugins-message-container">
                  <div className="fv-help-block">
                    {formik.errors.expireDate}
                  </div>
                </div>
              ) : null}
            </Form.Group>
          ) : null}
          <Form.Group className="col-12">
            <Form.Label className="col-12">Descrizione</Form.Label>
            <Form.Control
              as="textarea"
              style={{
                resize: "none",
              }}
              rows={4}
              placeholder="Descrizione..."
              value={formik.values.description}
              onChange={formik.handleChange}
              className={getInputClasses("description")}
              {...formik.getFieldProps("description")}
              name="description"
            />
            {formik.touched.description && formik.errors.description ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.description}</div>
              </div>
            ) : null}
          </Form.Group>
        </Form>
      </CardBody>
    </Card>
  );
};
