/**
 * TODO:
 * 
 * Inserire step pagamento (CON CALMA)
 */
/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { Card, Button, Modal } from "react-bootstrap";
import moment from "moment";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { useFormik } from "formik";
import { FirstStep } from "../../../../config/api/prenotazioni";
import { makeStyles } from "@material-ui/core/styles";
import { Steps } from "./newFormPrenotazione/Steps";
import * as yup from "yup";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser, faUserGroup } from "@fortawesome/free-solid-svg-icons";

export const SelectPaziente = ({
  show,
  setShow,
  setPaziente,
  setIdPaziente,
}) => (
  <Modal
    size="lg"
    aria-labelledby="contained-modal-title-vcenter"
    centered
    backdrop="static"
    keyboard={false}
    show={show}
    onHide={() => setShow(false)}
  >
    <Modal.Body>
      <h2 className="text-justify text-center">
        Per chi si sta effettuando la prenotazione?
      </h2>
    </Modal.Body>
    <Modal.Footer className="border-0 py-4" style={{ display: "block" }}>
      <span className="d-flex justify-content-center">
        <Button
          size="lg"
          /* variant="outline-primary" */
          onClick={() => {
            setPaziente("personal");
            setShow(false);
          }}
          className="float-right mr-5 col-5"
          style={{ fontSize: "20px" }}
        >
          <FontAwesomeIcon icon={faUser} className="pr-5" />
          Per me
        </Button>
        <Button
          size="lg"
          /* variant="outline-primary" */
          onClick={() => {
            setPaziente("other");
            setShow(false);
            setIdPaziente(0);
          }}
          className="float-right ml-5 col-5"
          style={{ fontSize: "20px" }}
        >
          <FontAwesomeIcon icon={faUserGroup} className="pr-5" />
          Per un altro
        </Button>
      </span>
    </Modal.Footer>
  </Modal>
);

export const NewPrenota = () => {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const [step, setStep] = useState(0);
  const [paziente, setPaziente] = useState("");
  const [idPaziente, setIdPaziente] = useState(user.id);
  const [idPrenotazione, setIdPrenotazione] = useState(0);

  const [nameService, setNameService] = useState("");
  const [stepDueSelect, setStepDueSelect] = useState({
    idService: 0,
    idDoc: 0,
    idOrganization: 0,
    startDate: "",
    endDate: "",
  });

  const [selectPaziente, setSelectPaziente] = useState(true);

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    button: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));

  const classes = useStyles();

  const initialValues =
    paziente === "other"
      ? {
          firstname: "",
          lastname: "",
          countryBirth: "",
          regionBirth: "",
          provinceBirth: "",
          cityBirth: "",
          birthday: "",
          gender: "",
          ssn: "",
          tesseraSanitaria: "",
          countryAddress: "",
          regionAddress: "",
          provinceAddress: "",
          cityAddress: "",
          address: "",
        }
      : {
          firstname: user.firstname,
          lastname: user.lastname,
          countryBirth: user.countryBirth,
          regionBirth: user.regionBirth,
          provinceBirth: user.provinceBirth,
          cityBirth: user.cityBirth,
          birthday: moment(user.birthDay).format("YYYY-MM-DD"),
          gender: user.gender,
          ssn: user.ssn,
          tesseraSanitaria: user.tesseraSanitaria,
          countryAddress: user.countryAddress,
          regionAddress: user.regionAddress,
          provinceAddress: user.provinceAddress,
          cityAddress: user.cityAddress,
          address: user.address,
        };

  const validationSchema = yup.object().shape({
    firstname: yup
      .string()
      .required("Inserire un nome valido")
      .min(3, "Minimo 3 caratteri")
      .max(50, "Massimo 50 caratteri"),
    lastname: yup
      .string()
      .required("Inserire un cognome valido")
      .min(3, "Minimo 3 caratteri")
      .max(50, "Massimo 50 caratteri"),
    countryBirth: yup.string().required("Selezionare una nazione"),
    regionBirth: yup.string().required("Selezionare una regione"),
    provinceBirth: yup.string().required("Selezionare una provincia"),
    cityBirth: yup.string().required("Selezionare una città"),
    birthday: yup.date().required("Inserire data di nascita"),
    gender: yup.string().required("Selezionare genere"),
    ssn: yup
      .string("Inserire codice fiscale valido")
      .required("Inserire codice fiscale valido")
      .matches(
        /^[a-zA-Z]{6}[0-9]{2}[a-zA-Z][0-9]{2}[a-zA-Z][0-9]{3}[a-zA-Z]$/,
        "Il codice fiscale non è corretto"
      ),
    tesseraSanitaria: yup
      .string("Inserire un numero di Tessera sanitataria valida")
      .min(20, "Minimo 20 caratteri")
      .max(20, "Massimo 20 caratteri")
      .required("Inserisci un numero di tessera sanitaria valido"),
    countryAddress: yup.string().required("Selezionare nazione"),
    regionAddress: yup.string().required("Selezionare una regione"),
    provinceAddress: yup.string().required("Selezionare una provincia"),
    cityAddress: yup.string().required("Selezionare una città"),
    address: yup
      .string()
      .required("Inserire l'indirizzo di residenza")
      .min(5, "Minimo 5 caratteri")
      .max(100, "Massimo 100 caratteri"),
  });

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const payload = {
        ...values,
        accessToken: user.authToken,
        paziente: paziente,
      };
      FirstStep(payload)
        .then((data) => setIdPaziente(data.result))
        .then(() => setStep(step + 1));
    },
  });

  return (
    <>
      <SelectPaziente
        show={selectPaziente}
        setShow={setSelectPaziente}
        setPaziente={setPaziente}
        setIdPaziente={setIdPaziente}
      />
      <div
        style={selectPaziente ? { filter: "blur(4px)" } : { filter: "none" }}
      >
        <div className={classes.root}>
          <Stepper activeStep={step} step={step}>
            <Step>
              <StepLabel>Dati paziente</StepLabel>
            </Step>
            <Step>
              <StepLabel>Seleziona prestazione</StepLabel>
            </Step>
            <Step>
              <StepLabel>Seleziona appuntamento</StepLabel>
            </Step>
            <Step>
              <StepLabel>Riepilogo prenotazione</StepLabel>
            </Step>
          </Stepper>
        </div>
        <Steps
          setSelectPaziente={setSelectPaziente}
          step={step}
          setStep={setStep}
          formik={formik}
          paziente={paziente === "other" ? true : false}
          submit={formik.handleSubmit}
          nameService={nameService}
          setNameService={setNameService}
          stepDueSelect={stepDueSelect}
          setStepDueSelect={setStepDueSelect}
          accessToken={user.authToken}
          idPaziente={idPaziente}
          idPrenotazione={idPrenotazione}
          setIdPrenotazione={setIdPrenotazione}
        />
      </div>
    </>
  );
};
