import React from "react";
import { Modal, Button } from "react-bootstrap";
import config from "../../../../config/config";
import { useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import moment from "moment";

export const ModalPrenotazione = (props) => {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );

  var months = "Gennaio_Febbraio_Marzo_Aprile_Maggio_Giugno_Luglio_Agosto_Settembre_Ottobre_Novembre_Dicembre".split(
    "_"
  );
  moment.locale("it");
  moment.updateLocale("it", { months: months });

  const history = useHistory();

  const ConfermaPrenotazione = () => {
    const requestOptions = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idPaziente: user.id,
        idOrganization: props.idOrganization,
        idDoc: props.idDoc,
        idService: props.prestazione,
        startTime: props.startTime,
        endTime: props.endTime,
        date: moment(props.date).format("YYYY-MM-DD"),
      }),
    };
    fetch(
      config.apiUrl + "appointments/ConfermaPrenotazione.php",
      requestOptions
    )
      .then((response) => response.json())
      .then((data) => props.setIdPrenotazione(data.result))
      .then(() => props.setStep(props.step + 1));
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header>
        <Modal.Title>Conferma prenotazione</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span className="px-3 label-text-date">Stai prenotando per</span> <br />
        <div className="px-3">
          <span className="text-muted text-center">Tipo prestazione:</span>{" "}
          {props.nameService}
          <br />
          <span className="text-muted text-center">Data:</span>{" "}
          {moment(props.date).format("DD MMMM YYYY")} <br />
          <span className="text-muted text-center">Ora inizio:</span>{" "}
          {props.startTime} <br />
          <span className="text-muted text-center">Ora fine:</span>{" "}
          {props.endTime} <br />
        </div>
        <span className="label-text-date float-right">
          Sei sicuro di voler procedere?
        </span>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.handleClose} variant="warning">
          Cambia
        </Button>
        <Button onClick={ConfermaPrenotazione} variant="primary">
          Conferma
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
