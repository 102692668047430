import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  LogoutAzienda: "[Logout Azienda] Action",
  SetOrganization: "[Set Organization] Action",
};

const initialAuthState = {
  organization: undefined,
};

export const reducer = persistReducer(
  { storage, key: "v726-demo1-auth" },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.SetOrganization: {
        const { organization } = action.payload;

        return { ...state, organization };
      }

      case actionTypes.LogoutAzienda: {
        return initialAuthState;
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setOrganization: (organization) => ({ type: actionTypes.SetOrganization, payload: { organization } }),
  logoutAzienda: () => ({ type: actionTypes.Logout }),
};