import React from "react";
import { useSubheader } from "../../../_metronic/layout";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import "../../../_metronic/_assets/sass/pages/error/error-3.scss";
import { UnderCostruction } from "../UnderCostruction";

export const ImpostazioniOrganizzazione = ({
  history,
  match: {
    params: { id, nameOrganization },
  },
}) => {
  const suhbeader = useSubheader();
  suhbeader.setId(id);
  suhbeader.setNameOrganization(nameOrganization);
  return <UnderCostruction />;
};
