import React, { useState, useEffect } from "react";
import { Button, Form, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import config from "../../../../../config/config";
import { RicevutaPrenotazione } from "../../model/ricevutaPrenotazione";
import moment from "moment";
import { Skeleton } from "@material-ui/lab";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRotateLeft, faHouse } from "@fortawesome/free-solid-svg-icons";

export const StepTre = ({
  accessToken,
  idPaziente,
  idPrenotazione,
  setStep,
}) => {
  const [loading, setLoading] = useState(false);
  const [dati, setDati] = useState({
    prenotazioneNumero: "",
    prestazione: "",
    medico: "",
    paziente: "",
    ssn: "",
    tesseraSanitaria: "",
    data: "",
    orario: "",
  });

  const Overlay = (button) => (
    <OverlayTrigger
      key={button}
      placement="top"
      overlay={<Tooltip id={`tooltip-${button}`}>{button}.</Tooltip>}
    >
      <Button variant="secondary">{button}</Button>
    </OverlayTrigger>
  );

  const GetRiepilogoPrenotazione = () => {
    setLoading(true);
    const options = {
      headers: {
        Authorization: config.keyApi,
      },
      method: "POST",
      body: JSON.stringify({
        idPaziente: idPaziente,
        accessToken: accessToken,
        idPrenotazione: idPrenotazione,
      }),
    };
    fetch(config.apiUrl + "appointments/GetRiepilogoPrenotazione.php", options)
      .then((response) => response.json())
      .then((data) => setDati(data.result))
      .then(() => setLoading(false));
  };

  useEffect(() => {
    GetRiepilogoPrenotazione();
    /*eslint-disable-next-line*/
  }, []);

  if (!loading) {
    return (
      <Card className="col-12 p-3 border-0">
        <RicevutaPrenotazione
          prenotazioneNumero={dati.prenotazioneNumero}
          prestazione={dati.prestazione}
          medico={dati.medico}
          paziente={dati.paziente}
          tesseraSanitaria={dati.tesseraSanitaria}
          ssn={dati.ssn}
          data={moment(dati.data).format("DD MMMM YYYY")}
          orario={dati.orario}
        />
        <Form className="mt-4">
          <OverlayTrigger
            key={"dashboard"}
            placement="top"
            overlay={
              <Tooltip id={`tooltip-dashboard`}>Torna alla dashboard.</Tooltip>
            }
          >
            <Button
              onClick={() => (window.location.href = "/")}
              className="float-right"
            >
              <FontAwesomeIcon icon={faHouse} />
            </Button>
          </OverlayTrigger>
          <OverlayTrigger
            key={"new"}
            placement="top"
            overlay={<Tooltip id={`tooltip-new`}>Nuova prenotazione.</Tooltip>}
          >
            <Button onClick={() => setStep(0)} className="float-right mr-3">
              <FontAwesomeIcon icon={faArrowRotateLeft} />
            </Button>
          </OverlayTrigger>
        </Form>
      </Card>
    );
  } else {
    return (
      <div className="row">
        <div className="col-12">
          <Skeleton variant="text" />
        </div>
        <div className="col-12">
          <Skeleton variant="text" />
        </div>
        <div className="col-12">
          <Skeleton variant="rect" height={800} />
        </div>
      </div>
    );
  }
};
