import React from "react";
import { Modal } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../controls";
import config from "../../../../../config/config";

export const DeleteModal = (props) => {
  const RequestOptions = {
    headers: {
      Authorization:
        "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
    },
    method: "POST",
    body: JSON.stringify({
      idDisponibilita: props.idAvailability,
    }),
  };

  const DeleteAvailability = () => {
    fetch(config.apiUrl + "organization/DeleteAvailability.php", RequestOptions)
      .then(() => props.closeDelete())
      .then(() => props.setCount(props.count + 1));
  };

  return (
    <>
      <Modal show={props.show}>
        <Card>
          <CardHeader title="Elimina disponibilità">
            <CardHeaderToolbar>
              <button
                type="button"
                onClick={() => props.closeDelete()}
                className="btn btn-light"
              >
                <i className="fas fa-times"></i>
                Chiudi
              </button>
              <button
                type="submit"
                className="btn btn-danger ml-2"
                onClick={DeleteAvailability}
              >
                Elimina
              </button>
            </CardHeaderToolbar>
          </CardHeader>
          <CardBody>
            Stai per eliminare questa prestazione definitivamente, la scelta è
            irreversibile e non potrai tornare più indietro.
            <br />
            <b>{props.name}</b>
          </CardBody>
        </Card>
      </Modal>
    </>
  );
};
