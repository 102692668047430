import React, { useState, useEffect } from "react";
import { Button, Modal } from "react-bootstrap";
import { useSelector, shallowEqual } from "react-redux";
import { CardCartella } from "./CardCartella";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  DataTypeProvider,
  PagingState,
  IntegratedPaging,
} from "@devexpress/dx-react-grid";
import "@devexpress/dx-react-grid-bootstrap4/dist/dx-react-grid-bootstrap4.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCodePullRequest } from "@fortawesome/free-solid-svg-icons";
import { GetPazienti } from "../../../config/api/cartellaDigitale";
import { RichiestaCartellaDigitale } from "../../../config/api/cartellaDigitale";
import moment from "moment-timezone";
import Swal from "sweetalert2";

export const TabellaPazienti = () => {
  const [show, setShow] = useState(false);
  const [columns] = useState([
    { name: "name", title: "Paziente" },
    { name: "dateBirth", title: "Data di nascita" },
    { name: "expire", title: "Scadenza autorizzazione" },
    { name: "action", title: "Richiedi Accesso" },
  ]);

  const [page, setPage] = useState("pazienti");

  const user = useSelector((state) => state.auth.user, shallowEqual);

  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [pageSizes] = useState([10, 20, 50, 0]);
  const [idRichiesta, setIdRichiesta] = useState(0);
  const [name, setName] = useState("");

  useEffect(() => {
    GetPazienti(user.authToken).then((data) => setRows([...data.result]));
    /*eslint-disable-next-line*/
  }, []);

  const TableComponent = ({ ...restProps }) => (
    <Table.Table
      {...restProps}
      className="table table-hover table-head-custom table-vertical-center overflow-hidden"
    />
  );

  const BirthdayColumn = (props) => (
    <DataTypeProvider
      formatterComponent={({ value }) => moment(value).format("DD MMMM YYYY")}
      {...props}
    />
  );

  const tableMessages = {
    noData: "Nessun paziente affiliato",
  };

  const actionColumn = ({ value, row }) => {
    let patient = value.name;
    var now = moment(new Date());
    var expire = moment(row.expire);
    var diff = expire.diff(now, "milliseconds");
    if (value.stato === 3 || value.stato === 2 || diff < 0) {
      return (
        <div>
          <Button
            onClick={() =>
              Swal.fire({
                title: "Richiesta",
                html:
                  "<p>Si sta richiedendo " +
                  "l'autorizzazione per visionare la cartella clinica " +
                  "di " +
                  patient +
                  "</p>",
                confirmButtonColor: "#1bc5bd",
                cancelButtonColor: "#f64e60",
                showDenyButton: true,
                confirmButtonText: "Conferma",
                denyButtonText: `Annulla`,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  RichiestaCartellaDigitale({
                    accessToken: user.authToken,
                    idPatient: value.id,
                  }).then((data) => {
                    if (data.code === 1) {
                      Swal.fire({
                        icon: "success",
                        title: "Richiesta inviata con successo",
                        confirmButtonColor: "#1bc5bd",
                        confirmButtonText: "Chiudi",
                      });
                    } else {
                      Swal.fire({
                        icon: "danger",
                        title: "Errore nell'invio della richiesta",
                        confirmButtonColor: "#1bc5bd",
                        confirmButtonText: "Chiudi",
                      });
                    }
                  });
                } else if (result.isDenied) {
                  Swal.fire({
                    title: "Richiesta non inviata",
                    confirmButtonColor: "#1bc5bd",
                    confirmButtonText: "Chiudi",
                    icon: "danger",
                  });
                }
              })
            }
          >
            <FontAwesomeIcon className="mr-2" icon={faCodePullRequest} />
            Richiesta
          </Button>
        </div>
      );
    } else if (value.stato === 0) {
      return <Button disabled={true}>In attesa del paziente</Button>;
    } else if (value.stato === 1) {
      return (
        <Button
          onClick={() => {
            setPage("cardPaziente");
            setIdRichiesta(value.idRichiesta);
            setName(row.name);
          }}
        >
          Visualizza cartella
        </Button>
      );
    }
  };

  const ActionFormattedColumn = (props) => (
    <DataTypeProvider formatterComponent={actionColumn} {...props} />
  );

  const ScadenzaColumn = ({ value }) => {
    var now = moment(new Date());
    var expire = moment(value);
    var diff = expire.diff(now, "milliseconds");
    if (parseInt(value) === 0 || diff < 0) {
      return <p>Non autorizzato</p>;
    } else {
      return <p> {moment(value).format("DD MMMM YYYY, HH:mm")}</p>;
    }
  };

  const ScadenzaColumnFormatted = (props) => (
    <DataTypeProvider formatterComponent={ScadenzaColumn} {...props} />
  );
  /*eslint-disable-next-line*/
  const [rows, setRows] = useState([]);

  return (
    <>
      {page === "pazienti" ? (
        <Grid rows={rows} columns={columns}>
          <PagingState
            currentPage={currentPage}
            onCurrentPageChange={setCurrentPage}
            pageSize={pageSize}
            onPageSizeChange={setPageSize}
          />
          <IntegratedPaging />
          <ActionFormattedColumn for={["action"]} />
          <ScadenzaColumnFormatted for={["expire"]} />
          <BirthdayColumn for={["dateBirth"]} />
          <Table messages={tableMessages} tableComponent={TableComponent} />
          <TableHeaderRow />
          <PagingPanel pageSizes={pageSizes} />
        </Grid>
      ) : (
        <CardCartella
          name={name}
          accessToken={user.authToken}
          idRichiesta={idRichiesta}
          setPage={setPage}
        />
      )}
    </>
  );
};
