import React from "react";
import { StepUno } from "./StepUno";
import { StepDue } from "../formPrenotazione/stepDue";
import { StepTre } from "../formPrenotazione/stepTre";
import { StepDate } from "../formPrenotazione/stepDate";
import { Card } from "react-bootstrap";

export const Steps = ({
  step,
  setStep,
  formik,
  paziente,
  setSelectPaziente,
  submit,
  nameService,
  setNameService,
  stepDueSelect,
  setStepDueSelect,
  idPaziente,
  idPrenotazione,
  accessToken,
  setIdPrenotazione,
}) => {
  return (
    <Card className="border-0">
      {step === 0 ? (
        <StepUno
          formik={formik}
          paziente={paziente}
          setSelectPaziente={setSelectPaziente}
          submit={submit}
          className="p-4"
        />
      ) : null}
      {step === 1 ? (
        <StepDue
          step={step}
          setStep={setStep}
          nameService={nameService}
          setNameService={setNameService}
          stepDueSelect={stepDueSelect}
          setStepDueSelect={setStepDueSelect}
        />
      ) : null}
      {step === 2 ? (
        <StepDate
          step={step}
          setStep={setStep}
          nameService={nameService}
          stepDueSelect={stepDueSelect}
          setStepDueSelect={setStepDueSelect}
          setIdPrenotazione={setIdPrenotazione}
        />
      ) : null}
      {step === 3 ? (
        <StepTre
          setStep={setStep}
          step={step}
          idPaziente={idPaziente}
          idPrenotazione={idPrenotazione}
          accessToken={accessToken}
          setIdPrenotazione={setIdPrenotazione}
        />
      ) : null}
    </Card>
  );
};
