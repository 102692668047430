/**
 * TODO:
 * 
 * Inserire nelle impostazioni dell'organizzazione, la possibilità
 * di scegliere le modalità di pagamento disponibili:
 * PAYPAL, CARTA(STRIPE), BONIFICO, PAGAMENTO IN SEDE, APPLE PAY, GOOGLE PAY
 */
import React, { useEffect } from "react";
import { Switch, Redirect, Route } from "react-router-dom";
import { PannelloPages } from "./PannelloControllo/PanelloPages";

export const PannelloControllo = () => {
  useEffect(() => {}, []);

  return (
    <>
      <Switch>
        <Redirect
          from={`/organizzazione/pannello-controllo/`}
          exact={true}
          to={`/organizzazione/pannello-controllo/impostazioni/`}
        />
        <Route
          path={`/organizzazione/pannello-controllo/crea-prestazione/`}
          component={PannelloPages.CreaPrestazione}
        />
        <Route
          path={`/organizzazione/pannello-controllo/gestisci-prestazioni/`}
          component={PannelloPages.GestisciPrestazioni}
        />
        <Route
          path={`/organizzazione/pannello-controllo/gestisci-medici/`}
          component={PannelloPages.GestisciMedici}
        />
        <Route
          path={`/organizzazione/pannello-controllo/richieste-medici/`}
          component={PannelloPages.RichiesteMedici}
        />
        <Route
          path={`/organizzazione/pannello-controllo/gestisci-sedi/`}
          component={PannelloPages.GestisciSedi}
        />
        <Route
          path={`/organizzazione/pannello-controllo/gestisci-reparti/`}
          component={PannelloPages.GestisciReparti}
        />
        <Route
          path={`/organizzazione/pannello-controllo/crea-reparto/`}
          component={PannelloPages.CreaReparto}
        />
        <Route
          path={`/organizzazione/pannello-controllo/crea-sede/`}
          component={PannelloPages.CreaSede}
        />
        <Route
          path={`/organizzazione/pannello-controllo/impostazioni-organizzazione/`}
          component={PannelloPages.ImpostazioniOrganizzazione}
        />
        <Route
          path={`/organizzazione/pannello-controllo/impostazioni-personali`}
          component={PannelloPages.ImpostazioniPersonali}
        />
        <Route
          path={`/organizzazione/pannello-controllo/crea-disponibilita/`}
          component={PannelloPages.CreaDisponibilita}
        />
        <Route
          path={`/organizzazione/pannello-controllo/gestisci-disponibilita/`}
          component={PannelloPages.GestisciDisponibilita}
        />
      </Switch>
    </>
  );
};
