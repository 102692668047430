import React from "react";
import { useSubheader } from "../../layout";
import { MixedWidget1 } from "../widgets";
import { useSelector, shallowEqual } from "react-redux";
import { useHistory } from "react-router-dom";
import { PrestazioniChart } from "../components/charts/PrestazioniChart";
import { Prestazioni } from "../components/dashboardComponents/Prestazioni";
import { Meets } from "../components/dashboardComponents/Meets";
import { roles } from "../../../config/config";

export function DashboardAzienda() {
  const history = useHistory();
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );
  const suhbeader = useSubheader();
  suhbeader.setTitle("Dashboard - " + organization.name);

  if (
    organization.memberType === "Ospite" ||
    organization.memberType === "Paziente"
  ) {
    history.push("/organizzazione/info/", {
      from: "organizzazione/dashboard/",
    });
  }

  return (
    <>
      <div className="row">
        <div className="col-sm">
          <MixedWidget1 className="card-stretch gutter-b" />
        </div>
      </div>

      {/* begin::Row */}
      {parseInt(user.idRole) === roles.doctor ? (
        <div className="row">
          <div className="col-xl-4 col-lg-4 col-sm-12">
            <Prestazioni />
          </div>
          <div className="col-xl-8 col-lg-8 col-sm-12">
            <PrestazioniChart className="gutter-b card card-custom card-stretch" />
          </div>
        </div>
      ) : null}
      {/* end::Row */}

      <div className="row">
        <div className="col-xl-4 col-lg-4 col-sm-12">
          <Meets idOrganization={organization.id} />
        </div>
      </div>
    </>
  );
}
