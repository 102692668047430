import React from "react";
import { Form } from "react-bootstrap";

export const InputFile = ({ name, id, setFile, file }) => (
  <div className="custom-file" style={{ zIndex: 1 }}>
    <Form.File
      type="file"
      accept="application/pdf"
      className="custom-file-input"
      name={name} // assegnazione del nome
      id={id} // assegnazione all'id
      onChange={(e) => {
        setFile(e.target.files[0]); // setto il file con il nome dell'elemento
      }}
      style={{ visibility: "hidden" }}
    />

    {/*
        passo il nome attraverso le props nella label, se esiste altrimenti quello di default 
        */}
    <label
      className={`col-12 custom-file-label ${!file && file !== undefined ? "text-muted" : null}`}
      htmlFor={id}
    >
      {file["name"] !== undefined && file !== undefined ? file["name"] : "Seleziona un file (.pdf)"}
    </label>
  </div>
);
