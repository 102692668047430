import React, { useState } from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect, useDispatch } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import * as auth from "../_redux/authRedux";
import config from "../../../../config/config";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "@material-ui/core";
// import logo from 'http://localhost:3000/media/bg/logo_footer_region_drchain.jpeg';

/*
  INTL (i18n) docs:
  https://github.com/formatjs/react-intl/blob/master/docs/Components.md#formattedmessage
*/

/*
  Formik+YUP:
  https://jaredpalmer.com/formik/docs/tutorial#getfieldprops
*/

const initialValues = {
  email: "",
  password: "",
};

function Login(props) {
  const { intl } = props;
  const [loading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const eye = <FontAwesomeIcon icon={show ? faEyeSlash : faEye} />;
  const dispatch = useDispatch();
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Inserisci un'email valida")
      .min(3, "Minimo 3 caratteri")
      .max(50, "Massimo 50 caratteri")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
    password: Yup.string()
      .min(8, "Minimo 8 caratteri")
      .max(16, "Massimo 16 caratteri")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD",
        })
      ),
  });

  const enableLoading = () => {
    setLoading(true);
  };

  const disableLoading = () => {
    setLoading(false);
  };

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      enableLoading();
      setTimeout(() => {
        const requestOptions = {
          headers: {
            Authorization:
              "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
          },
          method: "POST",
          body: JSON.stringify({
            email: values.email,
            password: values.password,
          }),
        };

        fetch(config.apiUrl + "users/LoginUser.php", requestOptions)
          .then((response) => response.json())
          .then((result) => {
            if (result !== []) {
              dispatch(auth.actions.login(result.authToken));
              dispatch(auth.actions.setUser(result));
              window.location.href="/";
            } else {
              if (result < 0) {
                disableLoading();
                setSubmitting(false);
                setStatus(
                  intl.formatMessage({
                    id: "AUTH.VALIDATION.USER_NOT_ACTIVE",
                  })
                );
              } else {
                disableLoading();
                setSubmitting(false);
                setStatus(
                  intl.formatMessage({
                    id: "AUTH.VALIDATION.INVALID_LOGIN",
                  })
                );
              }
            }
          })
          .catch((error) => {
            disableLoading();
            setSubmitting(false);
            setStatus(
              intl.formatMessage({
                id: "AUTH.VALIDATION.INVALID_LOGIN",
              })
            );
          });
      }, 1000);
    },
  });

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-10 mb-lg-20">
        <h3 className="font-size-h1">
          <FormattedMessage id="AUTH.LOGIN.TITLE" />
        </h3>
      </div>

      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={formik.handleSubmit}
        className="form fv-plugins-bootstrap fv-plugins-framework"
      >
        {formik.status ? (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{formik.status}</div>
          </div>
        ) : (
          ""
        )}

        <div className="form-group fv-plugins-icon-container">
          <input
            placeholder="Email"
            type="email"
            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
              "email"
            )}`}
            name="email"
            {...formik.getFieldProps("email")}
          />
          {formik.touched.email && formik.errors.email ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.email}</div>
            </div>
          ) : null}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <div className="pass-wrapper">
            <input
              placeholder="Password"
              type={!show ? "password" : "text"}
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                "password"
              )}`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            <Tooltip
              placement="top"
              title={show ? "Nascondi password" : "Mostra password"}
            >
              <i className="eye-password" onClick={() => setShow(!show)}>
                {eye}
              </i>
            </Tooltip>
          </div>
          {formik.touched.password && formik.errors.password ? (
            <div className="fv-plugins-message-container">
              <div className="fv-help-block">{formik.errors.password}</div>
            </div>
          ) : null}
        </div>
        {/* begin: ForgotPassword */}
        <div className="form-group">
          <Link to="/auth/forgot-password"> Password dimenticata </Link>
        </div>
        {/* end: ForgotPassword */}

        {/* end: Registration */}
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <button
            id="kt_login_signin_submit"
            type="submit"
            disabled={formik.isSubmitting}
            className="btn btn-primary font-weight-bold px-9 py-4 my-3"
          >
            <span>Accedi</span>
            {loading && <span className="ml-3 spinner spinner-white"></span>}
          </button>
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center mt-15">
          <h8 className="font-weight-lighter text-black">
            Il progetto "Dr. Chain" è stato finanziato con il contributo del POR CAMPANIA FESR 2014-2020
            e cofinanziato dall’Unione Europea,
            dallo Stato Italiano e dalla Regione Campania,
            nell’ambito del bando CAMPANIA STARTUP 2020 – POR Campania FESR 2014-2020
            per un importo pari a € 75.650,00 . CUP B21B19001240007 Decreto 139 del 23/04/2021
          </h8>
        </div>

        <div className="d-none flex-column-auto d-lg-flex justify-content-center">
          <div className="d-flex justify-content-center ml-10">
            <img
                alt="Logo"
                style={{width: '300px'}}
                className=""
                src={toAbsoluteUrl("/media/bg/logoFooter.jpeg")}
            />
          </div>
        </div>
      </form>
      {/*end::Form*/}
    </div>
  );
}

export default injectIntl(connect(null, auth.actions)(Login));
