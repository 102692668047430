/* eslint-disable no-restricted-imports */
import React, { useState, useEffect } from "react";
import { Card, Button, Form } from "react-bootstrap";
import { Jutsu } from "react-jutsu";
import Select from "react-select";
import {
  GetLabels,
  SaveResoconto,
  SaveFile,
} from "../../config/api/cartellaDigitale";
import { useFormik } from "formik";

import * as yup from "yup";
/*eslint-disable-next-line*/
import { useSelector, shallowEqual } from "react-redux";
import { roles } from "../../config/config";
import makeAnimated from "react-select/animated";
import { makeStyles } from "@material-ui/core/styles";
import Stepper from "@material-ui/core/Stepper";
import Step from "@material-ui/core/Step";
import StepLabel from "@material-ui/core/StepLabel";
import { Chatbox } from "../../_metronic/_partials/components/utils/Chatbox";
import Typography from "@material-ui/core/Typography";

export const FormTermine = ({ user, idDestinatario, idMeet }) => {
  const [labels, setLabels] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [file, setFile] = useState([]);
  const [nameFile, setNameFile] = useState("");
  const [message, setMessage] = useState(false);
  const [urlArr, setUrlArr] = useState([]);

  //const client = create("https://ipfs.infura.io:5001/api/v0");

  const retrieveFile = (e) => {
    const data = e.target.files[0];
    const reader = new window.FileReader();
    reader.readAsArrayBuffer(data);
    setNameFile(e.target.files[0].name);
    reader.onloadend = () => {
      setFile(Buffer(reader.result));
      console.log("Buffer data: ", Buffer(reader.result));
    };

    e.preventDefault();
  };

  // const handleSubmit = async (e) => {
  //   e.preventDefault();
  //   try {
  //     //const created = await client.add(file);
  //     //const url = `https://ipfs.infura.io/ipfs/${created.path}`;
  //     setUrlArr((prev) => [...prev, url]);
  //     SaveFile({
  //       accessToken: user.authToken,
  //       idPaziente: idDestinatario,
  //       url: url,
  //     }).then(() => {
  //       setMessage(true);
  //       setNameFile("");
  //       setFile([]);
  //       setUrlArr([]);
  //     });
  //   } catch (error) {
  //     console.log(error.message);
  //   }
  // };

  const useStyles = makeStyles((theme) => ({
    root: {
      width: "100%",
    },
    backButton: {
      marginRight: theme.spacing(1),
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
  }));

  function getSteps() {
    return ["Carica file del paziente", "Resoconto"];
  }

  const classes = useStyles();
  const [activeStep, setActiveStep] = useState(0);
  const steps = getSteps();

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  const animatedComponents = makeAnimated();

  const [initialValues, setInitialValues] = useState({
    resoconto: "",
  });

  const [validate, setValidate] = useState({
    resoconto: yup
      .string()
      .required("Inserire un resoconto del paziente")
      .min(4, "Minimo 4 caratteri")
      .max(500, "Massimo 500 caratteri"),
  });

  const validationSchema = yup.object().shape(validate);

  const handleSelectLabels = (value, no_key) => {
    if (no_key.action === "select-option") {
      let newValues = { ...initialValues };
      newValues[no_key["option"]["value"]] = 1;
      let newValidate = { ...validate };
      newValidate[no_key["option"]["value"]] = yup
        .number()
        .required("Inserire un valore valido")
        .positive("Inserire un valore valido")
        .max(2000, "Massimo 6 cifre");
      setInitialValues(newValues);
      setValidate(newValidate);
    } else if (no_key.action === "remove-value") {
      delete initialValues[no_key["removedValue"]["value"]];
      delete validate[no_key["removedValue"]["value"]];
    }
    setSelectedLabels(value);
  };

  useEffect(() => {
    GetLabels(user.authToken).then((data) => setLabels(data.result));
    /*eslint-disable-next-line*/
  }, []);

  const formik = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values) => {
      let payload = {
        accessToken: user.authToken,
        idPaziente: idDestinatario,
        resoconto: values.resoconto,
        idMeet: idMeet,
        values: {},
      };

      for (const [key, value] of Object.entries(values)) {
        if (key !== "resoconto") {
          payload.values[key] = value;
        }
      }
      SaveResoconto(payload);
    },
  });

  return (
    <Card className="card-custom card-stretch gutter-b">
      <Card.Header className="border-0 py-4">
        <Card.Title className="float-left">
          <h3 className="font-weight-bolder text-black">
            Resoconto teleconsulto
          </h3>
        </Card.Title>
        <div className="float-right">
          <Button
            onClick={() => (window.location.href = "/")}
            variant="danger"
            className="mr-3"
          >
            Chiudi
          </Button>
          {activeStep === 1 ? (
            <Button className="ml" //onClick={formik.handleSubmit}
            >
              Salva
            </Button>
          ) : null}
        </div>
      </Card.Header>
      <Card.Body>
        <div className={classes.root}>
          <Stepper activeStep={activeStep} alternativeLabel>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{label}</StepLabel>
              </Step>
            ))}
          </Stepper>
        </div>
        {activeStep === 0 ? (
          <div className="row">
            <div className="custom-file" style={{ zIndex: 1 }}>
              <Form.File
                type="file"
                accept="application/pdf"
                className="custom-file-input"
                name="file"
                id="file"
                onChange={retrieveFile}
                style={{ visibility: "hidden" }}
              />
              <label
                className={`col-12 custom-file-label ${
                  !file && file !== undefined ? "text-muted" : null
                }`}
                htmlFor="file"
              >
                {nameFile !== "" ? nameFile : "Seleziona un file"}
              </label>
              <div className="row">
                <Button
                  disabled={nameFile === ""}
                  //onClick={handleSubmit}
                  className="mt-4 ml-3 float-left"
                >
                  Carica file
                </Button>
                {message ? (
                  <span
                    className="col-12 float-left mt-2"
                    style={{ color: "green" }}
                  >
                    Seleziona un nuovo file da caricare o procedi al prossimo
                    step oppure clicka sul tasto "Esci"
                  </span>
                ) : null}
              </div>
            </div>
          </div>
        ) : null}
        {activeStep === 1 ? (
          <Form>
            <Form.Row>
              <div className={`col-12 mb-3`}>
                <Select
                  options={labels}
                  name="labels"
                  components={animatedComponents}
                  placeholder="Seleziona valori da inserire"
                  onChange={handleSelectLabels}
                  isMulti
                />
              </div>
              {selectedLabels !== null ? (
                <>
                  {selectedLabels.length > 0
                    ? selectedLabels.map((e, key) => (
                        <Form.Group
                          className="col-xl-6 col-lg-6 col-sm-12"
                          key={`group-${key}`}
                        >
                          <Form.Label className="col-12" key={`label-${key}`}>
                            {e.label}
                          </Form.Label>
                          <Form.Control
                            type="number"
                            name={e.value}
                            placeholder={e.label}
                            {...formik.getFieldProps(e.value)}
                            required
                          />
                          {formik.touched[e.value] && formik.errors[e.value] ? (
                            <div className="fv-plugins-message-container">
                              <div className="fv-help-block">
                                {formik.errors[e.value]}
                              </div>
                            </div>
                          ) : null}
                        </Form.Group>
                      ))
                    : null}
                </>
              ) : null}
              <Form.Group className="col-12 mt-3">
                <Form.Label className="col-12">Osservazioni:</Form.Label>
                <Form.Control
                  as="textarea"
                  placeholder="Osservazioni sul paziente..."
                  style={{ resize: "none" }}
                  rows={5}
                  {...formik.getFieldProps("resoconto")}
                />
                {formik.touched.resoconto && formik.errors.resoconto ? (
                  <div className="fv-plugins-message-container">
                    <div className="fv-help-block">
                      {formik.errors.resoconto}
                    </div>
                  </div>
                ) : null}
              </Form.Group>
            </Form.Row>
          </Form>
        ) : null}

        <div>
          {activeStep === steps.length ? (
            <div className="float-right mt-4">
              <Typography className={classes.instructions}>
                Resoconto completato
              </Typography>
              <Button onClick={handleReset}>Nuovo caricamento</Button>
            </div>
          ) : (
            <div className="float-right mt-4">
              <div className="mt-4">
                <Button
                  variant=""
                  disabled={activeStep === 0}
                  onClick={handleBack}
                  className={classes.backButton}
                >
                  Indietro
                </Button>
                <Button color="primary" onClick={handleNext}>
                  {activeStep === steps.length - 1 ? "Termina" : "Successivo"}
                </Button>
              </div>
            </div>
          )}
        </div>
      </Card.Body>
    </Card>
  );
};

export const VideoConference = ({
  history,
  match: {
    params: { room, username, password, idDestinatario, idMeet },
  },
}) => {
  const [page, setPage] = useState("videoconference");
  const user = useSelector((state) => state.auth.user, shallowEqual);

  if (!room || !username || !password) {
    return (window.location.href = "/");
  } else {
    if (page === "videoconference") {
      return (
        <div className="row">
          <Card className="col-12 card-custom card-stretch gutter-b">
            <Card.Header className="border-0 pt-5">
              <Card.Title>
                <h3 className="font-weight-bolder text-black">
                  Teleconsulto in corso
                </h3>
              </Card.Title>
            </Card.Header>
            <Card.Body className="row">
              <div className="col-12">
                <Jutsu
                  containerStyles={{
                    width: "1200 px fit-content",
                    height: "600px",
                  }}
                  roomName={room}
                  displayName={username.replace("-", " ")}
                  password={password}
                  onMeetingEnd={() => {
                    if (parseInt(user.idRole) === roles.doctor) {
                      setPage("resoconto");
                    } else window.location.href = "/";
                  }}
                  loadingComponent={<p>Caricamento ...</p>}
                  errorComponent={<p>Oops, qualcosa è andato storto</p>}
                />
              </div>
              {/* <div className="col-4">
                <Chatbox idMittente={user.id} idDestinatario={idDestinatario} />
              </div> */}
            </Card.Body>
            <Card.Footer className="border-0 pt-5">
              <Button
                onClick={() => {
                  if (parseInt(user.idRole) === roles.doctor) {
                    setPage("resoconto");
                  } else window.location.href = "/";
                }}
                className="float-right"
                variant="danger"
              >
                <i
                  style={{ color: "white" }}
                  className="fa-solid fa-phone-slash px-1"
                ></i>
                Chiudi chiamata
              </Button>
            </Card.Footer>
          </Card>
        </div>
      );
    } else
      return (
        <FormTermine
          user={user}
          idDestinatario={idDestinatario}
          idMeet={idMeet}
        />
      );
  }
};
