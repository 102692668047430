import { persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage";

export const actionTypes = {
  SetRichieste: "[Set Richieste] Action",
};

const initialAuthState = {
  richieste: [],
};

export const reducer = persistReducer(
  { storage, key: "v726-demo1-auth" },
  (state = initialAuthState, action) => {
    switch (action.type) {
      case actionTypes.SetRichieste: {
        const { richieste } = action.payload;

        return { ...state, richieste };
      }

      default:
        return state;
    }
  }
);

export const actions = {
  setRichieste: (richieste) => ({
    type: actionTypes.SetRichieste,
    payload: { richieste },
  }),
};
