import React from "react";
import {toAbsoluteUrl} from "../../_metronic/_helpers";
import "../../_metronic/_assets/sass/pages/error/error-3.scss";

export function UnderCostruction() {
  return (
    <div className="d-flex flex-column flex-root">
      <div
        className="error error-3 d-flex flex-row-fluid bgi-size-cover bgi-position-center"
        style={{
          backgroundImage: `url(${toAbsoluteUrl("/media/error/bg3.jpg")})`
        }}
      >
        <div className="px-10 px-md-30 py-10 py-md-0 d-flex flex-column justify-content-md-center">
          <h1 className="error-title text-stroke text-transparent">ASPETTA!</h1>
          <p className="font-size-h1 font-weight-boldest text-dark-75">
            Questa pagina non è ancora pronta
          </p>
          <p className="font-size-h4 line-height-md">
            Stiamo lavorando per voi,
            <br />
            a breve anche questa sezione sarà a vostra disposizione
          </p>
        </div>
      </div>
    </div>
  );
}
