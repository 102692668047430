import React from "react";
import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { Button } from "react-bootstrap";

export const CardValore = ({ label, value, summary, setSummary, setPage }) => {
  return (
    <div className="col-xl-4 col-lg-6 col-md-6 col-sm-6">
      {/* <!--begin::Card--> */}
      <div className="card card-custom gutter-b card-stretch box-valori p-4">
        {/* <!--begin::Body--> */}
        <div className="card-body text-center pt-4">
          {/* <!--begin::User--> */}
          <div className="mt-7">
            <div className="symbol symbol-lg-50 symbol-sm-40">
              <img
                src={toAbsoluteUrl("/media/misc/etichetta-test.png")}
                alt="logo"
              />
            </div>
          </div>
          {/* <!--end::User--> */}
          {/* <!--begin::Name--> */}
          <div className="my-4">
            <p className="text-dark font-weight-bold text-hover-primary text-center nowrap">
              {label}
            </p>
          </div>
          {/* <!--end::Name--> */}
          {/* <!--begin::Label--> */}
          <p className="text-success font-weight-bold text-hover-primary font-size-h4">
            {value}
          </p>
          {/* <!--end::Label--> */}
          {/* <!--begin::Buttons--> */}
          <div className="mt-9">
            <Button
              href="#"
              className="btn btn-primary font-weight-bolder py-3 px-6 text-uppercase"
              onClick={() => {
                setPage("riepilogo");
                setSummary(summary);
              }}
            >
              Riepilogo
            </Button>
          </div>
          {/* <!--end::Buttons--> */}
        </div>
        {/* <!--end::Body--> */}
      </div>
      {/* <!--end::Card--> */}
    </div>
  );
};
