import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Form } from "react-bootstrap";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_partials/controls";
import Select from "react-select";
import config from "../../../../config/config";

export const EditSite = (props) => {
  const [regions, setRegions] = useState([]);
  const [provinces, setProvinces] = useState([]);
  const [cities, setCities] = useState([]);

  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedProvince, setSelectedProvince] = useState("");
  const [selectedCity, setSelectedCity] = useState("");

  const [controlRegion, setControlRegion] = useState(false);
  const [controlProvince, setControlProvince] = useState(false);
  const [controlCity, setControlCity] = useState(false);

  const requestOptions = {
    headers: {
      Authorization:
        "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
    },
    method: "POST",
    body: JSON.stringify({
      provincia: selectedProvince,
      regione: selectedRegion,
    }),
  };

  const ReturnToSiteSummary = () => {
    props.setPage("summary");
  };

  const GetRegions = () => {
    fetch(config.apiUrl + "geolocal/GetRegions.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setRegions(data))
      .then(() =>
        setSelectedRegion(regions.findIndex((p) => p.value === props.region))
      );
  };

  const GetProvinces = () => {
    fetch(config.apiUrl + "geolocal/GetProvinces.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setProvinces(data))
      .then(() => setSelectedProvince(props.province));
  };

  const GetCities = () => {
    fetch(config.apiUrl + "geolocal/GetCities.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setCities(data))
      .then(() => setSelectedCity(props.city));
  };

  useEffect(() => {
    GetRegions();
    /*eslint-disable-next-line*/
  }, []);
  
  useEffect(() => {
    if (selectedProvince !== "") {
      GetCities();
    }
    /*eslint-disable-next-line*/
  }, [selectedProvince]);
  
  useEffect(() => {
    if (selectedRegion !== "") {
      GetProvinces();
    }
    /*eslint-disable-next-line*/
  }, [selectedRegion]);

  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const schema = Yup.object({
    name: Yup.string("Inserire un nome valido")
      .required("Inserire uno nome valido")
      .min(3, "Minimo 3 caratteri")
      .max(100, "Massimo 100 caratteri"),
    address: Yup.string("Inserire un indirizzo valido")
      .required("Inserire un indirizzo valido")
      .min(3, "Minimo 3 caratteri")
      .max(255, "Massimo 255 caratteri"),
  });

  const formik = useFormik({
    initialValues: {
      name: props.name,
      address: props.address,
    },
    validationSchema: schema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const requestOptions = {
        headers: {
          Authorization:
            "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
        },
        method: "POST",
        body: JSON.stringify({
          id: props.id,
          name: values.name,
          address: values.address,
          region: selectedRegion,
          province: selectedProvince,
          city: selectedCity,
          idOrganization: props.idOrganization,
        }),
      };
      if (selectedRegion === "") {
        setControlRegion(true);
      } else {
        setControlRegion(false);
      }
      if (selectedCity === "") {
        setControlCity(true);
      } else {
        setControlCity(false);
      }
      if (selectedProvince === "") {
        setControlProvince(true);
      } else {
        setControlProvince(false);
      }
      if (
        controlRegion === false &&
        controlCity === false &&
        controlProvince === false
      ) {
        fetch(config.apiUrl + "organization/EditSite.php", requestOptions)
          .then(() => formik.resetForm())
          .then(() => props.setCount(props.count + 1))
          .then(() => ReturnToSiteSummary());
      }
    },
  });

  return (
    <Card>
      <CardHeader title="Modifica sede">
        <CardHeaderToolbar>
          <button
            type="button"
            onClick={ReturnToSiteSummary}
            className="btn btn-light"
          >
            <i className="fa fa-arrow-left"></i>
            Sedi
          </button>
          {`  `}
          <button onClick={formik.resetForm} className="btn btn-light ml-2">
            <i className="fa fa-redo"></i>
            Reset
          </button>
          {`  `}
          <button
            type="submit"
            className="btn btn-primary ml-2"
            onClick={formik.handleSubmit}
          >
            Salva modifiche
          </button>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <Form className="row">
          <Form.Group className="col-6">
            <Form.Label className="col-12">Nome sede</Form.Label>
            <Form.Control
              type="text"
              placeholder="Nome..."
              value={formik.values.name}
              onChange={formik.handleChange}
              className={getInputClasses("name")}
              {...formik.getFieldProps("name")}
              name="name"
            />
            {formik.touched.name && formik.errors.name ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.name}</div>
              </div>
            ) : null}
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label className="col-12">Regione</Form.Label>
            <Select
              defaultValue={selectedRegion}
              onChange={(e) => {
                setSelectedRegion(e.value);
              }}
              options={regions}
              name="region"
              placeholder="Seleziona regione..."
            />
            {controlRegion ? (
              <p style={{ color: "red" }}>Selezionare una regione</p>
            ) : null}
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label className="col-12">Provincia</Form.Label>
            <Select
              defaultValue={selectedProvince}
              onChange={(e) => setSelectedProvince(e.value)}
              options={provinces}
              name="province"
              isDisabled={selectedRegion === "" ? true : false}
              placeholder="Seleziona provincia..."
            />
            {controlProvince ? (
              <p style={{ color: "red" }}>Selezionare una provincia</p>
            ) : null}
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label className="col-12">Città</Form.Label>
            <Select
              defaultValue={selectedCity}
              onChange={(e) => setSelectedCity(e.value)}
              options={cities}
              name="city"
              isDisabled={selectedProvince === "" ? true : false}
              placeholder="Seleziona città..."
            />
            {controlCity ? (
              <p style={{ color: "red" }}>Selezionare una città</p>
            ) : null}
          </Form.Group>
          <Form.Group className="col-6">
            <Form.Label className="col-12">Indirizzo sede</Form.Label>
            <Form.Control
              type="text"
              placeholder="Indirizzo..."
              value={formik.values.address}
              onChange={formik.handleChange}
              className={getInputClasses("address")}
              {...formik.getFieldProps("address")}
              name="address"
            />
            {formik.touched.address && formik.errors.address ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.address}</div>
              </div>
            ) : null}
          </Form.Group>
        </Form>
      </CardBody>
    </Card>
  );
};
