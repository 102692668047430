import React, { useEffect, useState } from "react";
import { useSelector, shallowEqual } from "react-redux";
import { ModalSiteDelete } from "../../../_metronic/_partials/components/modals/ModalSiteDelete";
import {
  Grid,
  Table,
  TableHeaderRow,
  PagingPanel,
  Toolbar,
  SearchPanel,
} from "@devexpress/dx-react-grid-bootstrap4";
import {
  PagingState,
  IntegratedPaging,
  DataTypeProvider,
  SearchState,
  IntegratedFiltering,
} from "@devexpress/dx-react-grid";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";
import SVG from "react-inlinesvg";
import { Card, Tooltip, OverlayTrigger } from "react-bootstrap";
import config from "../../../config/config";
import Skeleton from "@material-ui/lab/Skeleton";
import { EditSite } from "../../../_metronic/_partials/components/forms/EditSite";

export const GestisciSedi = () => {
  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );

  const [columns] = useState([
    { name: "name", title: "Nome" },
    { name: "region", title: "Regione" },
    { name: "province", title: "Provincia" },
    { name: "city", title: "Città" },
    { name: "address", title: "Indirizzo" },
    { name: "actions", title: "Azioni" },
  ]);
  const [site, setSite] = useState({
    id: 0,
    name: "",
    region: "",
    province: "",
    city: "",
    address: "",
  });
  const [rows, setRows] = useState([]);
  const [pageSizes] = useState([0, 10, 20, 50, 100]);
  const [currentPage, setCurrentPage] = useState(0);
  const [pageSize, setPageSize] = useState(10);
  const [searchValue, setSearchState] = useState("");
  const [actionsColumn] = useState(["actions"]);
  const [loading, setLoading] = useState(false);
  const [page, setPage] = useState("summary");
  const [show, setShow] = useState(false);
  const [count, setCount] = useState(0);
  const handleOpen = (e) => {
    setSite(e);
    setShow(true);
  };
  const handleClose = () => {
    setSite({
      id: 0,
      name: "",
      region: "",
      province: "",
      city: "",
      address: "",
    });
    setShow(false);
  };

  const requestOptions = {
    headers: {
      Authorization:
        "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
    },
    method: "POST",
    body: JSON.stringify({
      idOrganization: organization.id,
    }),
  };

  const GetSites = () => {
    setLoading(true);
    fetch(config.apiUrl + "organization/GetSites.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setRows(data))
      .then(() => setLoading(false));
  };

  useEffect(() => {
    GetSites();
    /*eslint-disable-next-line*/
  }, [count]);

  const TableComponent = ({ ...restProps }) => (
    <Table.Table
      {...restProps}
      className="table table-hover table-head-custom table-vertical-center overflow-hidden"
    />
  );

  const editColum = ({ value }) => (
    <>
      <OverlayTrigger
        overlay={<Tooltip id="products-edit-tooltip">Modifica</Tooltip>}
      >
        {/*eslint-disable-next-line*/}
        <a
          onClick={() => {
            setSite(value);
            setPage("edit");
          }}
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
        >
          <span className="svg-icon svg-icon-md svg-icon-primary">
            <SVG
              src={toAbsoluteUrl("/media/svg/icons/Communication/Write.svg")}
            />
          </span>
        </a>
      </OverlayTrigger>

      <> </>
      <OverlayTrigger
        overlay={<Tooltip id="products-delete-tooltip">Elimina</Tooltip>}
      >
        {/*eslint-disable-next-line*/}
        <a
          onClick={() => handleOpen(value)}
          className="btn btn-icon btn-light btn-hover-danger btn-sm"
        >
          <span className="svg-icon svg-icon-md svg-icon-danger">
            <SVG src={toAbsoluteUrl("/media/svg/icons/General/Trash.svg")} />
          </span>
        </a>
      </OverlayTrigger>
    </>
  );

  const ButtonColumn = (props) => (
    <DataTypeProvider formatterComponent={editColum} {...props} />
  );
  if (page === "summary") {
    return (
      <>
        <ModalSiteDelete
          show={show}
          handleOpen={handleOpen}
          handleClose={handleClose}
          idSite={site.id}
          address={site.address}
          count={count}
          setCount={setCount}
        />
        <Card className="tab-content table-responsive px-2 py-2">
            {!loading ? (
              <Grid rows={rows} columns={columns}>
                <ButtonColumn for={actionsColumn} />
                <SearchState
                  value={searchValue}
                  onValueChange={setSearchState}
                />
                <IntegratedFiltering />
                <PagingState
                  currentPage={currentPage}
                  onCurrentPageChange={setCurrentPage}
                  pageSize={pageSize}
                  onPageSizeChange={setPageSize}
                />
                <IntegratedPaging />
                <Table tableComponent={TableComponent} />
                <TableHeaderRow />
                <PagingPanel pageSizes={pageSizes} />
                <Toolbar />
                <SearchPanel />
              </Grid>
            ) : (
              <>
                <Skeleton variant="text" />
                <Skeleton variant="circle" width={80} height={80} />
                <Skeleton variant="rect" height={300} />
              </>
            )}
        </Card>
      </>
    );
  } else if (page === "edit") {
    return (
      <>
        <EditSite
          address={site.address}
          region={site.region}
          province={site.province}
          city={site.city}
          id={site.id}
          name={site.name}
          idOrganization={organization.id}
          page={page}
          setPage={setPage}
          count = {count}
          setCount = {setCount}
        />
      </>
    );
  }
};
