import React, { useState, useEffect } from "react";
import { useSelector, shallowEqual, connect } from "react-redux";
import { Card, Row } from "react-bootstrap";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import config from "../../../config/config";
import * as auth from "../../modules/Auth";

const OrganizationInformation = () => {
  const organization = useSelector(
    (state) => state.authOrganization.organization,
    shallowEqual
  );
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const [info, setInfo] = useState([]);
  const [loading, setLoading] = useState(false);
  const GetPageInfo = () => {
    setLoading(true);
    const options = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idOrganization: organization.id,
        nameOrganization: organization.name,
        idUser: user.id
      }),
    };
    fetch(config.apiUrl + "organization/GetPageInfo.php", options)
      .then((response) => response.json())
      .then((data) => setInfo(data))
      .then(() => setLoading(false));
  };
  useEffect(() => {
    GetPageInfo();
    /*eslint-disable-next-line*/
  }, [window.location.href]);

  return (
    <Card>
      {loading && <ModalProgressBar />}

      {/* begin::Body */}
      <Card.Body>
        <div className="row">
          <label className="col-xl-0"></label>
          <div className="col-lg-9 col-xl-6">
            <h5 className="font-weight-bold mb-6">
              Informazioni organizzazione
            </h5>
          </div>
        </div>
        <Row className="my-4">
          <div className="col-6 my-2">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <span className="text-muted font-weight-bold mr-2">Regione:</span>
              <span className=" text-hover-primary">{info.regione}</span>
            </div>
          </div>
          <div className="col-6 my-2">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <span className="text-muted font-weight-bold mr-2">
                Provincia:
              </span>
              <span className="text-hover-primary">{info.provincia}</span>
            </div>
          </div>
          <div className="col-6 my-2">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <span className="text-muted font-weight-bold mr-2">Città:</span>
              <span className=" text-hover-primary">{info.city}</span>
            </div>
          </div>
          <div className="col-6 my-2">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <span className="text-muted font-weight-bold mr-2">
                Indirizzo:
              </span>
              <span className="text-hover-primary">{info.address}</span>
            </div>
          </div>
        </Row>
        {/* separatore*/}
        <div className="separator separator-dashed my-5"></div>
        {/* fine separatore*/}
        <div className="row">
          <label className="col-xl-0"></label>
          <div className="col-lg-9 col-xl-6">
            <h5 className="font-weight-bold mb-6">
              Descrizione
            </h5>
          </div>

          <div className="col-12 my-2">
            <div className="d-flex align-items-center justify-content-between mb-2">
              <span className="text-muted font-weight-bold mr-2">
                Bio:
              </span>
              <div className="col-6">
              <span className=" text-hover-primary">Formisano è un’azienda bio farmaceutica internazionale impegnata nel miglioramento della qualità di vita dei pazienti e nella ricerca di nuove soluzioni terapeutiche.</span>
            </div>
            </div>
          </div>
        </div>
        {/* separatore*/}
        <div className="separator separator-dashed my-5"></div>
        {/* fine separatore*/}
        <div className="col-6 my-2">
          <div className="d-flex align-items-center justify-content-between mb-2">
            <span className="text-muted font-weight-bold mr-2">
              Su Dr. Chain dal:
            </span>
            <span className=" text-hover-primary">{info.dateCreated}</span>
          </div>
        </div>
      </Card.Body>
      {/* end::Body */}
    </Card>
  );
};

export default connect(null, auth.actions)(OrganizationInformation);
