import React from "react";
import { Modal, Button } from "react-bootstrap";
import config from "../../../../config/config";

export const ModalDepartmentDelete = (props) => {
  const requestOptions = {
    headers: {
      Authorization:
        "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
    },
    method: "POST",
    body: JSON.stringify({
      idDepartment: props.id,
    }),
  };

  const DeleteDepartment = () => {
    fetch(config.apiUrl + "organization/DeleteDepartment.php", requestOptions)
      .then(() => props.handleClose())
      .then(() => props.setCount(props.count + 1));
  };

  return (
    <Modal show={props.show} onHide={props.handleClose}>
      <Modal.Header>
        <Modal.Title>Elimina reparto</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        Stai eliminando il reparto: {props.name}. Una volta
        confermato, la scelta sarà irreversibile e non potrai tornare indietro
      </Modal.Body>
      <Modal.Footer style={{ display: "block !important" }}>
        <Button
          onClick={props.handleClose}
          className="float-right"
          size="sm"
          variant="secondary"
        >
          Annulla
        </Button>
        <Button
          onClick={() => DeleteDepartment()}
          className="float-right"
          size="sm"
          variant="danger"
        >
          Elimina
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
