/* eslint-disable no-restricted-imports */
import React, { useState, useRef, useEffect } from "react";
import { ListGroup } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import * as authOrganization from "../../../../../src/app/modules/AuthOrganization/authRedux";
import config from "../../../../config/config";
import { useSelector, useDispatch, shallowEqual } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Select, { components } from "react-select";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { library } from "@fortawesome/fontawesome-svg-core";

library.add(faMagnifyingGlass);

export const SearchToolbar = (props) => {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const [query, setQuery] = useState("");
  const history = useHistory();
  let ref = useRef(null);

  const LoginAzienda = (idOrganization) => {
    const options = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: user.id,
        idRole: user.idRole,
        idOrganization: idOrganization,
      }),
    };
    fetch(config.apiUrl + "organization/LoginAzienda.php", options)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          dispatch(authOrganization.actions.setOrganization(data));
          window.location.href="/organizzazione/dashboard"
          /* history.push(`/organizzazione/dashboard/`, {
            from: "Dashboard",
          }); */
        }
      });
  };

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      props.onClickOutside && props.onClickOutside();
      setShow(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside, true);
    return () => {
      document.removeEventListener("click", handleClickOutside, true);
    };
  });

  const CaretDownIcon = () => {
    return <FontAwesomeIcon icon="fa-solid fa-magnifying-glass" />;
  };

  const DropdownIndicator = (props) => {
    return (
      <components.DropdownIndicator {...props}>
        <CaretDownIcon />
      </components.DropdownIndicator>
    );
  };

  return (
    <>
      <div className="pr-8" style={{ position: "relative" }}>
        <Select
          options={props.organizations}
          components={{ DropdownIndicator }}
          placeholder="Cerca azienda..."
          onChange={(e) =>  LoginAzienda(e.value)}
        />
        {/* <input
          ref={ref}
          onChange={(e) => setQuery(e.target.value)}
          onClick={() => setShow(true)}
          value={query}
          className="form-control"
          placeholder="Cerca organizzazione..."
        /> */}
        {show ? (
          <ListGroup
            ref={ref}
            onClickOutside={handleClickOutside}
            style={{
              width: "92%",
              position: "absolute",
              zIndex: "9",
              backgroundColor: "white",
            }}
          >
            {props.organizations
              /*eslint-disable-next-line*/
              .filter((e) => {
                if (query === "") {
                  return "";
                } else if (e.name.toLowerCase().includes(query.toLowerCase())) {
                  return e;
                }
              })
              .map((e, key) => (
                <ListGroup.Item
                  key={key}
                  action
                  onClick={() => {
                    LoginAzienda(e.value);
                  }}
                >
                  {e.name}
                </ListGroup.Item>
              ))}
          </ListGroup>
        ) : null}
      </div>
    </>
  );
};
