import arrConfig from "../config";

export async function GetLabels(accessToken) {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify({ accessToken: accessToken }),
  };
  const response = await fetch(
    arrConfig.apiUrl + "cartella_digitale/GetLabels.php",
    options
  );
  return await response.json();
}

export async function CheckPin(payload) {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify({
      pin: payload.pin,
      accessToken: payload.accessToken,
    }),
  };
  const response = await fetch(
    arrConfig.apiUrl + "cartella_digitale/CheckPin.php",
    options
  );
  return await response.json();
}

export async function SaveValues(payload) {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify(payload),
  };
  const response = await fetch(
    arrConfig.apiUrl + "cartella_digitale/SaveValues.php",
    options
  );
  return await response.json();
}

export async function SaveToMintedValues(payload) {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify(payload),
  };
  const response = await fetch(
    arrConfig.apiUrl + "cartella_digitale/SaveToMintedValues.php",
    options
  );
  return await response.json();
}

export async function SaveResoconto(payload) {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify(payload),
  };
  const response = await fetch(
    arrConfig.apiUrl + "cartella_digitale/SaveResoconto.php",
    options
  );
  return await response.json();
}

export async function GetDati(payload, pin) {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify(payload),
  };
  const response = await fetch(
    arrConfig.apiUrl + "cartella_digitale/GetDati.php",
    options
  );
  return await response.json();
}

export async function SaveFile(payload) {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify(payload),
  };
  const response = await fetch(
    arrConfig.apiUrl + "cartella_digitale/SaveFile.php",
    options
  );
  return await response.json();
}

export async function SyncFileCrypted(payload) {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify(payload),
  };
  const response = await fetch(
    arrConfig.apiUrl + "cartella_digitale/SyncFileCrypted.php",
    options
  );
  return await response.json();
}

export async function SyncDataCrypted(payload) {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify(payload),
  };
  const response = await fetch(
    arrConfig.apiUrl + "cartella_digitale/SyncDataCrypted.php",
    options
  );
  return await response.json();
}

export async function SyncDataToMint(payload) {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify(payload),
  };
  const response = await fetch(
    arrConfig.apiUrl + "cartella_digitale/SyncDataToMint.php",
    options
  );
  return await response.json();
}

export async function GetPazienti(accessToken) {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify({ accessToken: accessToken }),
  };
  const response = await fetch(
    arrConfig.apiUrl + "cartella_digitale/GetPazienti.php",
    options
  );
  return await response.json();
}

export async function RichiestaCartellaDigitale(payload) {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify(payload),
  };
  const response = await fetch(
    arrConfig.apiUrl + "cartella_digitale/RichiestaCartellaDigitale.php",
    options
  );
  return await response.json();
}

export async function GetRichieste(accessToken) {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify({ accessToken: accessToken }),
  };
  const response = await fetch(
    arrConfig.apiUrl + "cartella_digitale/GetRichieste.php",
    options
  );
  return await response.json();
}

export async function HandleRichiesta(payload) {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify(payload),
  };
  const response = await fetch(
    arrConfig.apiUrl + "cartella_digitale/HandleRichiesta.php",
    options
  );
  return await response.json();
}

export async function GetPazienteCartella(payload) {
  const options = {
    headers: {
      Authorization: arrConfig.keyApi,
    },
    method: "POST",
    body: JSON.stringify(payload),
  };
  const response = await fetch(
    arrConfig.apiUrl + "cartella_digitale/GetPazienteCartella.php",
    options
  );
  return await response.json();
}
