import React, { useState, useEffect } from "react";
import { Card, Form, Button, Modal, Tab, Tabs } from "react-bootstrap";
import { useSubheader } from "../../_metronic/layout";
import { useSelector, shallowEqual } from "react-redux";
import { Test } from "./test";
import { sha256 } from "js-sha256";
import {
  CheckPin,
  SaveToMintedValues,
} from "../../config/api/cartellaDigitale";
import { encrypt } from "../../config/utils/crypt";
import {
  SaveValues,
  SyncDataCrypted,
  SyncDataToMint,
  SyncFileCrypted,
} from "../../config/api/cartellaDigitale";
import { useFormik } from "formik";
import { GetLabels } from "../../config/api/cartellaDigitale";
import makeAnimated from "react-select/animated";
import Select from "react-select";
import { Resoconto } from "../modules/cartellaDigitale/Resoconto";
import { Richieste } from "../modules/cartellaDigitale/Richieste";
import PinInput from "react-pin-input";
import * as yup from "yup";
import Swal from "sweetalert2";

export const ModalPin = ({
  show,
  setShow,
  checkPin,
  pin,
  setPin,
  accessToken,
}) => {
  const [check, setCheck] = useState(true);
  const [auth, setAuth] = useState(false);
  const [toBeCrypted, setToBeCrypted] = useState([]);

  useEffect(() => {
    if (Object.keys(toBeCrypted).length > 0) {
      let toBeMinted = [];
      let fileToBeMinted = [];
      for (var k in toBeCrypted) {
        if (k === "datiCartella") {
          toBeCrypted[k].map((e) =>
            toBeMinted.push({
              id: e.id,
              idUser: e.idUser,
              idLabel: e.idLabel,
              value: encrypt(e.value, pin),
            })
          );
        } else {
          toBeCrypted[k].map((e) =>
            fileToBeMinted.push({
              id: e.id,
              idUser: e.idUser,
              value: encrypt(e.value, pin),
            })
          );
        }
        SyncDataCrypted({ accessToken: accessToken, values: toBeMinted });
        SyncFileCrypted({ accessToken: accessToken, values: fileToBeMinted });
        SyncDataToMint({
          accessToken: accessToken,
          data: encrypt(toBeMinted, pin),
        });
      }
    }

    /*eslint-disable-next-line*/
  }, [toBeCrypted]);
  return (
    <Modal
      centered
      show={show}
      size={!check && auth ? "sm" : ""}
      onHide={() => setShow(false)}
      backdrop="static"
      keyboard={false}
    >
      <Modal.Body>
        {!auth ? (
          <Form.Group className="col-12 pt-4 pb-0">
            <h5 className="col-12 text-center">
              Inserisci il pin personale per proseguire
            </h5>

            <div className="col-12 text-center mt-8">
              <PinInput
                length={4}
                initialValue={pin}
                onChange={(value, index) => {
                  setPin(value);
                }}
                type="numeric"
                inputMode="number"
                inputStyle={{ borderColor: "black", borderRadius: "10px" }}
                inputFocusStyle={{
                  borderColor: "var(--primary)",
                  borderRadius: "5px",
                }}
                onComplete={(value, index) => {
                  checkPin({
                    accessToken: accessToken,
                    pin: sha256(value),
                  })
                    .then((data) => {
                      if (data.code === 1) {
                        setCheck(false);
                        setAuth(true);
                        setToBeCrypted(data.result);
                        setShow(false);
                      } else {
                        setCheck(false);
                        setAuth(false);
                      }
                    })
                    .then(() =>
                      Swal.fire({
                        icon: "success",
                        title: "PIN corretto",
                        buttonsStyling: false,
                        customClass: {
                          confirmButton: "btn btn-primary",
                        },
                      })
                    );
                }}
                autoSelect={true}
                regexCriteria={/^[ A-Za-z0-9_@./#&+-]*$/}
              />
              {!check && !auth ? (
                <label className="pt-3" style={{ color: "red" }}>
                  Pin errato! Riprovare.
                </label>
              ) : null}
            </div>
          </Form.Group>
        ) : (
          <div className="row">
            <h5 className="col-12 pt-4 text-center">Pin corretto!</h5>
          </div>
        )}
      </Modal.Body>
      {!check && auth ? (
        <Modal.Footer className="border-0 py-4">
          <Button onClick={() => setShow(false)}>Prosegui</Button>
        </Modal.Footer>
      ) : null}
    </Modal>
  );
};

export const ModalComplete = ({ show, setShow, message, setMessage }) => (
  <Modal
    centered
    show={show}
    onHide={() => {
      setShow(false);
      setMessage("");
    }}
  >
    <Modal.Body>{message}</Modal.Body>
    <Modal.Footer className="border-0 py-4">
      <Button
        size="sm"
        onClick={() => {
          setShow(false);
          setMessage("");
        }}
      >
        Chiudi
      </Button>
    </Modal.Footer>
  </Modal>
);

export const CartellaDigitale = () => {
  const suhbeader = useSubheader();
  suhbeader.setTitle("Cartella digitale");
  const animatedComponents = makeAnimated();

  const [labels, setLabels] = useState([]);
  const [selectedLabels, setSelectedLabels] = useState([]);
  const [initialValues, setInitialValues] = useState({});
  const [message, setMessage] = useState(true);
  const [pin, setPin] = useState("");
  const [show, setShow] = useState(true);
  const [showComplete, setShowComplete] = useState(false);
  const [esito, setEsito] = useState("");
  const [validate, setValidate] = useState({});

  const schema = yup.object().shape(validate);

  const handleSelectLabels = (value, no_key) => {
    setMessage(false);
    if (no_key.action === "select-option") {
      let newValues = { ...initialValues };
      newValues[no_key["option"]["value"]] = 1;
      let newValidate = { ...validate };
      newValidate[no_key["option"]["value"]] = yup
        .number()
        .required("Inserire un valore valido")
        .positive("Inserire un valore valido")
        .max(2000, "Massimo 6 cifre");
      setInitialValues(newValues);
      setValidate(newValidate);
    } else if (no_key.action === "remove-value") {
      delete initialValues[no_key["removedValue"]["value"]];
      delete validate[no_key["removedValue"]["value"]];
    }
    setSelectedLabels(value);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: schema,
    onSubmit: (values) => {
      SaveToMintedValues({
        accessToken: user.authToken,
        values: encrypt(values, pin),
      });
      let payload = {
        accessToken: user.authToken,
        values: {},
      };
      for (const [key, value] of Object.entries(values)) {
        payload["values"][key] = encrypt(value, pin);
      }
      SaveValues(payload).then((data) => {
        if (data.code === 1) {
          setEsito("Operazione completata con successo");
          setShowComplete(true);
          setSelectedLabels([]);
          setInitialValues({});
        } else {
          setEsito("C'è stato un errore nell'elaborazione dei dati, riprova");
          setShow(true);
        }
      });
    },
  });

  useEffect(() => {
    GetLabels(user.authToken).then((data) => setLabels(data.result));
    /*eslint-disable-next-line*/
  }, []);

  const user = useSelector((state) => state.auth.user, shallowEqual);

  return (
    <>
      <ModalPin
        show={show}
        setShow={setShow}
        pin={pin}
        setPin={setPin}
        checkPin={CheckPin}
        accessToken={user.authToken}
      />
      <></>
      <ModalComplete
        show={showComplete}
        setShow={setShowComplete}
        message={esito}
        setMessage={setEsito}
      />
      <Tabs defaultActiveKey="resoconto" id="uncontrolled-tab-example">
        <Tab eventKey="resoconto" title="Resoconto">
          <Resoconto blur={show} user={user} pin={pin} />
        </Tab>
        <Tab eventKey="add" title="Nuovi dati">
          <Card
            style={
              show
                ? { filter: "blur(4px)" }
                : { borderTop: "0px", borderRadius: "0px" }
            }
            className="p-4"
          >
            <Card.Body>
              {message || selectedLabels === null ? (
                <div className="row">
                  <div className="col-12 text-center my-5">
                    <h5>
                      Bentornato nella sezione per salvare i tuoi dati clinici!
                    </h5>
                  </div>
                  <div className="col-12 mb-4">
                    <ul>
                      <li>Seleziona uno o più valori per iniziare</li>
                      <li>Inserire il nuovo valore e salvare i dati</li>
                      <li>
                        Ogni valore inserito verrà criptato e salvato on chain,
                        solo il proprietario può accedervi con l'apposito pin
                        scelto alla registrazione
                      </li>
                    </ul>
                  </div>
                </div>
              ) : null}
              <div className={`col-12 ${message ? "mb-5" : null}`}>
                <Select
                  options={labels}
                  name="labels"
                  components={animatedComponents}
                  placeholder="Seleziona valori da inserire"
                  onChange={handleSelectLabels}
                  isMulti
                />
              </div>
              {selectedLabels !== null ? (
                <Form>
                  <Form.Row className="p-5">
                    {selectedLabels.length > 0
                      ? selectedLabels.map((e, key) => (
                          <Form.Group
                            className="col-xl-6 col-lg-6 col-sm-12"
                            key={`group-${key}`}
                          >
                            <Form.Label className="col-12" key={`label-${key}`}>
                              {e.label}
                            </Form.Label>
                            <Form.Control
                              type="number"
                              name={e.value}
                              placeholder={e.label}
                              {...formik.getFieldProps(e.value)}
                              required
                            />
                            {formik.touched[e.value] &&
                            formik.errors[e.value] ? (
                              <div className="fv-plugins-message-container">
                                <div className="fv-help-block">
                                  {formik.errors[e.value]}
                                </div>
                              </div>
                            ) : null}
                          </Form.Group>
                        ))
                      : null}
                  </Form.Row>
                  {selectedLabels.length > 0 ? (
                    <Button
                      onClick={formik.handleSubmit}
                      className="float-right"
                    >
                      Salva
                    </Button>
                  ) : null}
                </Form>
              ) : null}
            </Card.Body>
          </Card>
        </Tab>
        <Tab eventKey={"richieste"} title="Richieste">
          <Richieste pin={pin} />
        </Tab>
      </Tabs>
    </>
  );
};
