import React from "react";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";

/**
 * Funzione per creare un form con l'ausilio di formik, gestisce i campi text,
 * textarea, number, date, time, select e checkbox
 *
 * @param Array fields Ogni campo del form dovrà essere un oggetto:
 * @param String type Identificare il tipo di campo: text, textarea, date, time, select, checkbox
 * @param String label label del campo
 * @param String name nome del campo, quello che viene utilizzato in formik per identificare il valore
 * @param String cols le colonne che occuperà il campo
 * @param Bool disabled se il campo è disabilitato
 * @param Bool subField se il campo deve essere visualizzato solo se un altro è true (quando è true bisogna identificare il campo per il quale è dipendente)
 * @param Property passare il valore di formik che deve essere true per far visualizzare quel subField
 * @param Array options Se il field è un select, passare in un array le opzioni, l'array deve contenere oggetti di questo tipo [{label: "nome da visualizzare nel select", value: "valore da passare al backend"}]
 * @param object baseOption inserire value e label del valore di default
 * @param {function} formik  funzione di formik per gestire il form
 * @returns form da renderizzare all'interno di App.js
 */
export const MakeForm = ({
  fields = [
    {
      type: "",
      label: "",
      name: "",
      cols: "",
      disabled: false,
      subField: false,
      field: false,
      options: [],
      baseOption: { value: "", label: "" },
    },
  ],
  formik = useFormik,
}) => {
  const getInputClasses = (fieldname) => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const handleField = (e, key, formik) => {
    if (e.type === "text") {
      if (!e.subField) {
        return (
          <Form.Group key={"group-" + key} className={e.cols}>
            <Form.Label key={"label-" + key} className="col-12">
              {e.label}
            </Form.Label>
            <Form.Control
              key={"control-" + key}
              value={e.value}
              disabled={e.disabled}
              onChange={formik.handleChange}
              placeholder={e.placeholder}
              className={`col-12 ${getInputClasses(e.name)}`}
              type="text"
              name={e.name}
              {...formik.getFieldProps(e.name)}
            />
            {formik.touched[e.name] && formik.errors[e.name] ? (
              <div key={"div1-" + key} className="fv-plugins-message-container">
                <div key={"div2-" + key} className="fv-help-block">
                  {formik.errors[e.name]}
                </div>
              </div>
            ) : null}
          </Form.Group>
        );
      } else {
        return e.field ? (
          <Form.Group key={"group-" + key} className={e.cols}>
            <Form.Label key={"label-" + key} className="col-12">
              {e.label}
            </Form.Label>
            <Form.Control
              key={"control-" + key}
              value={e.value}
              disabled={e.disabled}
              onChange={formik.handleChange}
              placeholder={e.placeholder}
              className={`col-12 ${getInputClasses(e.name)}`}
              type="text"
              name={e.name}
              {...formik.getFieldProps(e.name)}
            />
            {formik.touched[e.name] && formik.errors[e.name] ? (
              <div key={"div1-" + key} className="fv-plugins-message-container">
                <div key={"div2-" + key} className="fv-help-block">
                  {formik.errors[e.name]}
                </div>
              </div>
            ) : null}
          </Form.Group>
        ) : null;
      }
    } else if (e.type === "number") {
      if (!e.subField) {
        return (
          <Form.Group key={"group-" + key} className={e.cols}>
            <Form.Label key={"label-" + key} className="col-12">
              {e.label}
            </Form.Label>
            <Form.Control
              key={"control-" + key}
              disabled={e.disabled}
              placeholder={e.placeholder}
              className={`col-12 ${getInputClasses(e.name)}`}
              type="number"
              name={e.name}
              {...formik.getFieldProps(e.name)}
            />
            {formik.touched[e.name] && formik.errors[e.name] ? (
              <div key={"div1-" + key} className="fv-plugins-message-container">
                <div key={"div2-" + key} className="fv-help-block">
                  {formik.errors[e.name]}
                </div>
              </div>
            ) : null}
          </Form.Group>
        );
      } else {
        return e.field ? (
          <Form.Group key={"group-" + key} className={e.cols}>
            <Form.Label key={"label-" + key} className="col-12">
              {e.label}
            </Form.Label>
            <Form.Control
              key={"control-" + key}
              disabled={e.disabled}
              placeholder={e.placeholder}
              className={`col-12 ${getInputClasses(e.name)}`}
              type="number"
              name={e.name}
              {...formik.getFieldProps(e.name)}
            />
            {formik.touched[e.name] && formik.errors[e.name] ? (
              <div key={"div1-" + key} className="fv-plugins-message-container">
                <div key={"div2-" + key} className="fv-help-block">
                  {formik.errors[e.name]}
                </div>
              </div>
            ) : null}
          </Form.Group>
        ) : null;
      }
    } else if (e.type === "date") {
      if (!e.subField) {
        return (
          <Form.Group key={"group-" + key} className={e.cols}>
            <Form.Label key={"label-" + key} className="col-12">
              {e.label}
            </Form.Label>
            <Form.Control
              key={"control-" + key}
              disabled={e.disabled}
              name={e.name}
              placeholder={e.placeholder}
              type="date"
              className={`col-12 ${getInputClasses(e.name)}`}
              {...formik.getFieldProps(e.name)}
            />
            {formik.touched[e.name] && formik.errors[e.name] ? (
              <div key={"div1-" + key} className="fv-plugins-message-container">
                <div key={"div2-" + key} className="fv-help-block">
                  {formik.errors[e.name]}
                </div>
              </div>
            ) : null}
          </Form.Group>
        );
      } else {
        return e.field ? (
          <Form.Group key={"group-" + key} className={e.cols}>
            <Form.Label key={"label-" + key} className="col-12">
              {e.label}
            </Form.Label>
            <Form.Control
              key={"control-" + key}
              disabled={e.disabled}
              name={e.name}
              placeholder={e.placeholder}
              type="date"
              className={`col-12 ${getInputClasses(e.name)}`}
              {...formik.getFieldProps(e.name)}
            />
            {formik.touched[e.name] && formik.errors[e.name] ? (
              <div key={"div1-" + key} className="fv-plugins-message-container">
                <div key={"div2-" + key} className="fv-help-block">
                  {formik.errors[e.name]}
                </div>
              </div>
            ) : null}
          </Form.Group>
        ) : null;
      }
    } else if (e.type === "time") {
      if (!e.subField) {
        return (
          <Form.Group key={"group-" + key} className={e.cols}>
            <Form.Label key={"label-" + key} className="col-12">
              {e.label}
            </Form.Label>
            <Form.Control
              key={"control-" + key}
              name={e.name}
              disabled={e.disabled}
              placeholder={e.placeholder}
              type="time"
              className={`col-12 ${getInputClasses(e.name)}`}
              {...formik.getFieldProps(e.name)}
            />
            {formik.touched[e.name] && formik.errors[e.name] ? (
              <div key={"div1-" + key} className="fv-plugins-message-container">
                <div key={"div2-" + key} className="fv-help-block">
                  {formik.errors[e.name]}
                </div>
              </div>
            ) : null}
          </Form.Group>
        );
      } else {
        return e.field ? (
          <Form.Group key={"group-" + key} className={e.cols}>
            <Form.Label key={"label-" + key} className="col-12">
              {e.label}
            </Form.Label>
            <Form.Control
              key={"control-" + key}
              name={e.name}
              disabled={e.disabled}
              placeholder={e.placeholder}
              type="time"
              className={`col-12 ${getInputClasses(e.name)}`}
              {...formik.getFieldProps(e.name)}
            />
            {formik.touched[e.name] && formik.errors[e.name] ? (
              <div key={"div1-" + key} className="fv-plugins-message-container">
                <div key={"div2-" + key} className="fv-help-block">
                  {formik.errors[e.name]}
                </div>
              </div>
            ) : null}
          </Form.Group>
        ) : null;
      }
    } else if (e.type === "textarea") {
      if (!e.subField) {
        return (
          <Form.Group key={"group-" + key} className={e.cols}>
            <Form.Label key={"label-" + key} className="col-12">
              {e.label}
            </Form.Label>
            <Form.Control
              key={"control-" + key}
              name={e.name}
              disabled={e.disabled}
              placeholder={e.placeholder}
              className={`col-12 ${getInputClasses(e.name)}`}
              {...formik.getFieldProps(e.name)}
              as="textarea"
              row={2}
              style={{ resize: "none" }}
            />
            {formik.touched[e.name] && formik.errors[e.name] ? (
              <div key={"div1-" + key} className="fv-plugins-message-container">
                <div key={"div2-" + key} className="fv-help-block">
                  {formik.errors[e.name]}
                </div>
              </div>
            ) : null}
          </Form.Group>
        );
      } else {
        return e.field ? (
          <Form.Group key={"group-" + key} className={e.cols}>
            <Form.Label key={"label-" + key} className="col-12">
              {e.label}
            </Form.Label>
            <Form.Control
              key={"control-" + key}
              name={e.name}
              disabled={e.disabled}
              placeholder={e.placeholder}
              className={`col-12 ${getInputClasses(e.name)}`}
              {...formik.getFieldProps(e.name)}
              as="textarea"
              row={2}
              style={{ resize: "none" }}
            />
            {formik.touched[e.name] && formik.errors[e.name] ? (
              <div key={"div1-" + key} className="fv-plugins-message-container">
                <div key={"div2-" + key} className="fv-help-block">
                  {formik.errors[e.name]}
                </div>
              </div>
            ) : null}
          </Form.Group>
        ) : null;
      }
    } else if (e.type === "checkbox") {
      if (!e.subField) {
        return (
          <>
            <Form.Group className={e.cols}>
              <Form.Label className="col-12">{e.label}</Form.Label>
              <label className="checkbox pl-4 pt-4">
                <input
                  type="checkbox"
                  color="primary"
                  onChange={formik.setValues[e.name]}
                  disabled={e.disabled}
                  className="checkbox-inline"
                  name={e.name}
                  {...formik.getFieldProps(e.name)}
                />
                <span style={{ marginRight: "5px" }}></span>
                {formik.values[e.name] ? "SI" : "NO"}
              </label>
              {formik.touched[e.name] && formik.errors[e.name] ? (
                <div
                  key={"div1-" + key}
                  className="fv-plugins-message-container"
                >
                  <div key={"div2-" + key} className="fv-help-block">
                    {formik.errors[e.name]}
                  </div>
                </div>
              ) : null}
            </Form.Group>
          </>
        );
      } else {
        return e.field ? (
          <>
            <Form.Group key={"group-" + key} className={e.cols}>
              <Form.Label className="col-12">{e.label}</Form.Label>
              <label key={"label-" + key} className="checkbox pl-4 pt-4">
                <input
                  type="checkbox"
                  color="primary"
                  key={"input-" + key}
                  disabled={e.disabled}
                  className="checkbox-inline"
                  name={e.name}
                  {...formik.getFieldProps(e.name)}
                />
                <span key={"span-" + key} style={{ marginRight: "5px" }}></span>
                {formik.values[e.name] ? "SI" : "NO"}
              </label>
              {formik.touched[e.name] && formik.errors[e.name] ? (
                <div
                  key={"div1-" + key}
                  className="fv-plugins-message-container"
                >
                  <div key={"div2-" + key} className="fv-help-block">
                    {formik.errors[e.name]}
                  </div>
                </div>
              ) : null}
            </Form.Group>
          </>
        ) : null;
      }
    } else if (e.type === "select") {
      if (!e.subField) {
        return (
          <Form.Group key={"group-" + key} className={e.cols}>
            <Form.Label className="col-12">{e.label}</Form.Label>
            <select
              key={"select-" + key}
              className={`form-control ${getInputClasses(e.name)}`}
              name={e.name}
              disabled={e.disabled}
              {...formik.getFieldProps(e.name)}
            >
              {e.baseOption !== undefined && e.baseOption ? (
                <option value={e.baseOption["value"]}>
                  {e.baseOption["label"]}
                </option>
              ) : null}
              {e["options"].map((e, key) => (
                <option key={key} value={e.value}>
                  {e.label}
                </option>
              ))}
            </select>
            {formik.touched[e.name] && formik.errors[e.name] ? (
              <div key={"div1-" + key} className="fv-plugins-message-container">
                <div key={"div2-" + key} className="fv-help-block">
                  {formik.errors[e.name]}
                </div>
              </div>
            ) : null}
          </Form.Group>
        );
      } else {
        return e.field ? (
          <Form.Group key={"group-" + key} className={e.cols}>
            <Form.Label className="col-12">{e.label}</Form.Label>
            <select
              key={"select-" + key}
              className={`form-control ${getInputClasses(e.name)}`}
              name={e.name}
              disabled={e.disabled}
              {...formik.getFieldProps(e.name)}
            >
              {e.baseOption && e.baseOption !== undefined ? (
                <option value={e.baseOption["value"]}>
                  {e.baseOption["label"]}
                </option>
              ) : null}
              {e["options"].map((e, key) => (
                <option key={key} value={e.value}>
                  {e.label}
                </option>
              ))}
            </select>
            {formik.touched[e.name] && formik.errors[e.name] ? (
              <div key={"div1-" + key} className="fv-plugins-message-container">
                <div key={"div2-" + key} className="fv-help-block">
                  {formik.errors[e.name]}
                </div>
              </div>
            ) : null}
          </Form.Group>
        ) : null;
      }
    }
  };

  return fields.map((e, key) => <>{handleField(e, key, formik)}</>);
};
