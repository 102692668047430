import React, { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useSelector, shallowEqual } from "react-redux";
import config from "../../../../config/config";
import { toAbsoluteUrl } from "../../../_helpers";
import Skeleton from "@material-ui/lab/Skeleton";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import * as auth from "../../../../../src/app/modules/AuthOrganization/authRedux";

export const MieOrganizzazioni = ({ className }) => {
  const user = useSelector((state) => state.auth.user, shallowEqual);
  const dispatch = useDispatch();
  const history = useHistory();

  const [organizations, setOrganizations] = useState([]);
  const [loading, setLoading] = useState(false);

  const requestOptions = {
    headers: {
      Authorization:
        "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
    },
    method: "POST",
    body: JSON.stringify({
      idUser: user.id,
    }),
  };

  const GetOrganizations = () => {
    setLoading(true);
    fetch(config.apiUrl + "organization/GetOrganizations.php", requestOptions)
      .then((response) => response.json())
      .then((data) => setOrganizations(data))
      .then(() => setLoading(false));
  };

  const LoginAzienda = (idOrganization) => {
    const options = {
      headers: {
        Authorization:
          "66148357b2fda762a650ba6465b9db6b6f4d0facab276b0550240a792e717180",
      },
      method: "POST",
      body: JSON.stringify({
        idUser: user.id,
        idRole: user.idRole,
        idOrganization: idOrganization,
      }),
    };
    fetch(config.apiUrl + "organization/LoginAzienda.php", options)
      .then((response) => response.json())
      .then((data) => {
        if (data) {
          dispatch(auth.actions.setOrganization(data));
          window.location.href = "/organizzazione/dashboard/";
          /* history.push(`/organizzazione/dashboard/`, {
            from: "Dashboard",
          }); */
        }
      });
  };

  useEffect(() => {
    GetOrganizations();
    //eslint-disable-next-line
  }, []);

  return (
    <div className={`card card-custom ${className}`}>
      {/* Head */}
      <div className="card-header border-0 py-5">
        <h3 className="card-title align-items-start flex-column">
          <span className="card-label font-weight-bolder text-dark">
            Organizzazioni
          </span>
        </h3>
      </div>
      {/* Body */}
      <div
        className="card-body pt-0 pb-8"
        style={{ maxHeight: "430px", minHeight: "400px", overflow: "auto" }}
      >
        <div className="tab-content">
          <div className="table-responsive">
            {organizations.length > 0 ? (
              <table className="table table-head-custom table-head-bg table-borderless table-vertical-center">
                <thead>
                  <tr className="text-left text-uppercase">
                    <th style={{ minWidth: "100px" }}>Nome</th>
                    <th style={{ minWidth: "100px" }}>Ruolo</th>
                    <th style={{ minWidth: "100px" }}>Sede</th>
                    <th style={{ minWidth: "100px" }}>Email</th>

                    <th style={{ minWidth: "80px" }} />
                  </tr>
                </thead>
                <tbody>
                  {!loading ? (
                    organizations.map((e, key) => (
                      <tr key={key}>
                        <td className="pl-0 py-8">
                          <div className="d-flex align-items-center">
                            <div>
                              <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                                {e.name}
                              </span>
                            </div>
                          </div>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {e.memberType}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {e.address}
                          </span>
                        </td>
                        <td>
                          <span className="text-dark-75 font-weight-bolder d-block font-size-lg">
                            {e.email}
                          </span>
                        </td>

                        <td className="pr-4">
                          <Button
                            className="nav-link btn btn-lg"
                            onClick={() => LoginAzienda(e.id)}
                          >
                            Accedi
                          </Button>
                        </td>
                      </tr>
                    ))
                  ) : (
                    <Skeleton />
                  )}
                </tbody>
              </table>
            ) : (
              <h5 className="text-muted text-inline text-center text-justify float-center my-4">
                NON FAI PARTE ANCORA DI NESSUNA ORGANIZZAZIONE
              </h5>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
